import { Link } from "react-router-dom";
import {
	Wrapper,
	FormWrapper,
	HeadBar,
	Form,
	UploadWrapper,
	UploadItem,
	ListTiles,
	Tiles,
	UploadItemWrapper,
	UploadContent,
	CourseThumbnailWrapper,
	CourseUploadWrapper,
} from "./styles";
// import {MdDateRange} from 'react-icons/md'
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useState, useEffect, useRef } from "react";
import Loader from "../../../components/Shared/Loader/Loader";
import { useHistory } from "react-router-dom";
import AxiosCall from "../../../../Utils/axios";
import AxiosUpload from "../../../../Utils/axios/upload";
import Message from "../../../components/Shared/Message/Index";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import Loading from "../../../components/Shared/Loader/Loading";
import axios from "axios";
import { hostUrl } from "../../../../Utils/axios";
const ExpertLedCourseUpload = () => {
	const user = useSelector((state) => state.user);
	let history = useHistory();
	const { courseId } = useParams();
	// const [counter, setCounter] = useState(0);
	const [contntentForm, setContentForm] = useState(false);
	const [resourcesForm, setResourcesForm] = useState(false);
	const [instructorsForm, setInstructorsForm] = useState(false);
	const [courseThumbnailForm, setCourseThumbnailForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	// const [error, setError] = useState(null);
	const [loadingVideo, setLoadingVideo] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);
	const [loadingThumbnail, setLoadingThumbnail] = useState(false);
	const [loadingSyllabus, setLoadingSyllabus] = useState(false);
	const [videoLink, setVideoLink] = useState("");
	const [imageLink, setImageLink] = useState("");
	// const [thumbnailLink, setThumbNailLink] = useState("");
	const [syllabusLink, setSyllabusLink] = useState("");
	const [instructors, setInstructors] = useState([]);
	const [selectedInstructors, setSelectedInstructors] = useState([]);
	const [timeline, setTimeline] = useState([]);

	const [categories, setCategories] = useState([]);
	const [formInputs, setFormInputs] = useState({
		title: "",
		snippet: "",
		description: "",
		duration: "",
		syllabus: syllabusLink,
		featuredVideo: videoLink,
		featuredImage: imageLink,
		thumbnail: "",
		language: "English",
		category: "",
		prerequisite: "",
		effort: "",
		level: "",
		timeline: [
			{
				title: "",
				description: "",
				project: {
					title: "",
					description: "",
				},
			},
		],
		instructors: [],
	});

	// const formInput = useRef({
	// 	title: "",
	// 	snippet: "",
	// 	description: "",
	// 	duration: "",
	// 	syllabus: syllabusLink,
	// 	featuredVideo: videoLink,
	// 	featuredImage: imageLink,
	// 	thumbnail: "",
	// 	language: "English",
	// 	category: "",
	// 	prerequisite: "",
	// 	effort: "",
	// 	level: "",
	// 	timeline: [
	// 		{
	// 			title: "",
	// 			description: "",
	// 			project: {
	// 				title: "",
	// 				description: "",
	// 			},
	// 		},
	// 	],
	// 	instructors: [],
	// });

	useEffect(() => {
		// formInput.current.timeline = [...timeline];
		setFormInputs({
			...formInputs,
			timeline: [...timeline]
		})
	}, [timeline]);

	const uploadCourse = async () => {
		setIsLoading(true);

		// formInput.current = {
		//     ...formInput.current,
		//     syllabus: syllabusLink,
		//     featuredVideo: videoLink,
		//     featuredImage: imageLink,
		//     thumbnail: thumbnailLink,
		// }

		// console.log(
		//     {syllabus: syllabusLink},
		//     {featuredVideo: videoLink},
		//     {featuredImage: imageLink},
		//     {thumbnail: thumbnailLink}
		// )


		// formInput.current = {
		// 	...formInput.current,
		// 	instructors: [...formInput.current.instructors, user.userId],
		// };
		setFormInputs({
			...formInputs,
			instructors: [...formInputs.instructors, user.userId],

		})

		setTimeout(async () => {
			try {
				const payLoad = formInputs;

				if (courseId === "new") {
					const res = await AxiosCall({
						method: "POST",
						path: "course/expert",
						data: payLoad,
					});

					console.log("response: ", res);

					if (res.status) {
						Message.success("Course created successfully");
						history.push("/upload/syllabus/course/expert/" + res.data._id);
					}
				} else {
					const res = await AxiosCall({
						method: "PATCH",
						path: "course/expert/details",
						data: payLoad,
					});

					console.log("response: ", res);

					if (res.status) {
						Message.success("Course updated successfully");
						history.push("/upload/syllabus/course/expert/" + courseId);
					}
				}
			} catch (err) {
				setIsLoading(false);
				// setError("Invalid credentials");
				Message.error(err?.response?.data?.error);
			}
		}, 1000);
	};

	const uploadVideo = async (file) => {
		setLoadingVideo(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});
		// formInput.current = {
		// 	...formInput.current,
		// 	featuredVideo: res.data[0].url,
		// };
		setFormInputs({
			...formInputs,
			featuredVideo: res.data[0].url,
		})
		setVideoLink(res.data[0].url);

		Message.success("Media uploaded successfully");
		setLoadingVideo(false);
	};
	const uploadImage = async (file) => {
		setLoadingImage(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		// formInput.current = {
		// 	...formInput.current,
		// 	featuredImage: res.data[0].url,
		// };
		setFormInputs({
			...formInputs,
			featuredImage: res.data[0].url,
		})
		setImageLink(res.data[0].url);
		Message.success("Media uploaded successfully");
		setLoadingImage(false);
	};
	const uploadThumbnail = async (file) => {
		setLoadingThumbnail(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		// formInput.current = {
		// 	...formInput.current,
		// 	thumbnail: res.data[0].url,
		// };
		setFormInputs({
			...formInputs,
			thumbnail: res.data[0].url,
		})
		Message.success("Media uploaded successfully");
		setLoadingThumbnail(false);
	};
	const uploadSyllabus = async (file) => {
		setLoadingSyllabus(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		// formInput.current = {
		// 	...formInput.current,
		// 	syllabus: res.data[0].url,
		// };
		setFormInputs({
			...formInputs,
			syllabus: res.data[0].url,
		})

		setSyllabusLink(res.data[0].url);
		console.log("response", res);
		Message.success("Media uploaded successfully");
		setLoadingSyllabus(false);
	};

	const getInstructor = useCallback(async () => {
		try {
			const res = await AxiosCall({
				method: "GET",
				custormBaseURL: "https://api.cholatrek.io/",
				path: "instructors",
			});
			setInstructors(res.data);

			console.log("response: ", res);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, []);

	const getCourse = useCallback(async () => {
		if (courseId !== "new") {
			try {
				const res = await AxiosCall({
					method: "GET",
					path: "classroom/course/" + courseId,
				});

				setFormInputs({
					...formInputs,
					courseId: res.data._id,
					title: res.data.title,
					snippet: res.data.snippet,
					level: res.data.level,
					category: res.data.category,
					effort: res.data.effort,
					duration: res.data.duration,
					prerequisite: res.data.prerequisite,
					description: res.data.description,
					syllabus: res.data.syllabus,
					featuredVideo: res.data.featuredVideo,
					featuredImage: res.data.featuredImage,
					thumbnail: res.data.thumbnail,
					timeline: [...res.data.timeline],
				});

				// timeline.current = res.data.timeline
				setTimeline(res.data.timeline);

				setPageLoading(false);
			} catch (err) {
				setPageLoading(false);
				// setError("Invalid credentials");
				Message.error(err?.response?.data?.error);
			}
		} else {
			setPageLoading(false);
		}
	}, [courseId]);

	const getCategory = useCallback(async () => {
		const token = localStorage.getItem("authToken");
		// console.log("fewf token: ", token);

		const headers = {
			cholatrek_access_token: token,
			"Content-Type": "application/json",
		};
		try {
			const res = await axios(`${hostUrl}/admin/category/all`, headers);

			/* 	const res = await axios(
		
				"https://api.cholatrek.io/admin/category/all",
				headers
			); */
			if (res) {
				setCategories(res.data.data);
			}

			console.log("categories: ", res.data);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, []);

	useEffect(() => {
		getCourse();
	}, [getCourse]);

	useEffect(() => {
		getInstructor();
	}, [getInstructor]);

	useEffect(() => {
		getCategory();
	}, [getCategory]);

	return (
		<Wrapper>
			<HeadBar>
				<h3>upload courses</h3>
				<Link to="/course/upload">Exit</Link>
			</HeadBar>
			{pageLoading ? (
				<Loading />
			) : (
				<>
					<div className="top-action">
						<button onClick={uploadCourse}>
							{isLoading ? <Loader /> : "Continue"}
						</button>
					</div>

					<ListTiles>
						<Tiles showForm={contntentForm}>
							<div
								className="tile-head"
								onClick={() => setContentForm(!contntentForm)}>
								<span>Course Content</span>
								<div className="toggle">
									<BsChevronDown />
								</div>
							</div>

							{contntentForm && (
								<div className="tile-body">
									<FormWrapper>
										<Form>
											<label htmlFor="">Course Title</label>
											<input
												type="text"
												defaultValue={formInputs.title}
												placeholder="Insert Course Title"
												onChange={(e) => {
													// formInput.current = {
													// 	...formInput.current,
													// 	title: e.target.value,
													// };
													setFormInputs({
														...formInputs,
														title: e.target.value,
													})
												}}
											/>

											<label htmlFor="">Short Description</label>
											<input
												type="text"
												defaultValue={formInputs.snippet}
												placeholder="Insert Course Description"
												onChange={(e) => {
													// formInput.current = {
													// 	...formInput.current,
													// 	snippet: e.target.value,
													// };
													setFormInputs({
														...formInputs,
														snippet: e.target.value,
													})
												}}
											/>

											<div className="input-col">
												<div className="input-wrapper">
													<input
														type="text"
														defaultValue={formInputs.effort}
														placeholder="Effort"
														onChange={(e) => {
															// formInput.current = {
															// 	...formInput.current,
															// 	effort: e.target.value,
															// };
															setFormInputs({
																...formInputs,
																effort: e.target.value,
															})
														}}
													/>
												</div>
											</div>

											<div className="input-col">
												<div className="input-wrapper">
													<select
														defaultValue={formInputs.category}
														name="category"
														onChange={(e) => {

															// formInput.current = {
															// 	...formInput.current,
															// 	category: e.target.value,
															// };
															setFormInputs({
																...formInputs,
																category: e.target.value,
															})
														}}>
														<option selected value="design">
															-- select category --
														</option>
														{categories?.map((category) => (
															<option
																selected={
																	formInputs.category === category.name
																}
																value={category?.name}>
																{category?.name}
															</option>
														))}
													</select>
												</div>

												<div className="input-wrapper">
													<select
														defaultValue={formInputs.level}
														name="level"
														onChange={(e) => {
															// formInput.current = {
															// 	...formInput.current,
															// 	level: e.target.value,
															// };
															setFormInputs({
																...formInputs,
																level: e.target.value,
															})
														}}>
														<option value="design">-- select level --</option>
														<option
															selected={
																formInputs.category === "Beginner"
																	? "true"
																	: "false"
															}
															value="beginner">
															Beginner
														</option>
														<option
															selected={
																formInputs.category === "Intermediate"
																	? "true"
																	: "false"
															}
															value="intermediate">
															Intermediate
														</option>
														<option
															selected={
																formInputs.category === "Advanced"
																	? "true"
																	: "false"
															}
															value="advanced">
															Advanced
														</option>
													</select>
												</div>

												<div className="input-wrapper">
													<input
														type="number"
														defaultValue={formInputs.duration}
														placeholder="Duration in weeks"
														onChange={(e) => {
															// formInput.current = {
															// 	...formInput.current,
															// 	duration: e.target.value,
															// };
															setFormInputs({
																...formInputs,
																duration: e.target.value,
															})
														}}
													/>
												</div>
											</div>

											<label htmlFor="">Description</label>
											<textarea
												className="input-description"
												defaultValue={formInputs.description}
												type="text"
												onChange={(e) => {
													// formInput.current = {
													// 	...formInput.current,
													// 	description: e.target.value,
													// };
													setFormInputs({
														...formInputs,
														description: e.target.value,
													})
												}}></textarea>
											<label htmlFor="">Prerequsite Knowledge</label>
											<textarea
												className="input-Knowledge"
												defaultValue={formInputs.prerequisite}
												type="text"
												onChange={(e) => {
													// formInput.current = {
													// 	...formInput.current,
													// 	prerequisite: e.target.value,
													// };
													setFormInputs({
														...formInputs,
														prerequisite: e.target.value,
													})
												}}></textarea>

											{timeline.map((item, index) => (
												<div key={index} className="form-sec timeline-sec">
													<div className="timeline-head">
														<label htmlFor="">Course Timeline</label>
														<span
															onClick={() => {
																const newTimeline =
																	formInputs.timeline.splice(index, 1);
																setTimeline(newTimeline);
																/* 														formInput.current = {
															...formInput.current,
															timeline: newTimeline,
														}; */
															}}>
															remove
														</span>
													</div>
													<input
														type="text"
														placeholder="Headline"
														defaultValue={item.title}
														onChange={(e) => {
															const newTimeline = formInputs.timeline;
															newTimeline[index].title = e.target.value;
															// formInput.current = {
															// 	...formInput.current,
															// 	timeline: newTimeline,
															// };
															setFormInputs({
																...formInputs,
																timeline: newTimeline,
															})
															console.log("timeline index: ", index);
														}}
													/>
													<textarea
														className="input-Knowledge course-description"
														type="text"
														placeholder="Description"
														defaultValue={item.description}
														onChange={(e) => {
															const newTimeline = formInputs.timeline;
															newTimeline[index].description = e.target.value;
															// formInput.current = {
															// 	...formInput.current,
															// 	timeline: newTimeline,
															// };
															setFormInputs({
																...formInputs,
																timeline: newTimeline,
															})
														}}></textarea>

													<label htmlFor="">Project</label>
													<input
														type="text"
														placeholder="headline"
														defaultValue={item.project.title}
														onChange={(e) => {
															const newTimeline = formInputs.timeline;
															newTimeline[index].project.title = e.target.value;
															// formInput.current = {
															// 	...formInput.current,
															// 	timeline: newTimeline,
															// };
															setFormInputs({
																...formInputs,
																timeline: newTimeline,
															})
														}}
													/>

													<textarea
														className="input-Knowledge project-description"
														type="text"
														placeholder="Description"
														defaultValue={item.project.description}
														onChange={(e) => {
															const newTimeline = formInputs.timeline;
															newTimeline[index].project.description =
																e.target.value;
															// formInput.current = {
															// 	...formInput.current,
															// 	timeline: newTimeline,
															// };
															setFormInputs({
																...formInputs,
																timeline: newTimeline,
															})
														}}></textarea>
												</div>
											))}

											<div className="form-footer-wrapper">
												<span
													onClick={() => {
														setTimeline([
															...timeline,
															{
																title: "",
																description: "",
																project: {
																	title: "",
																	description: "",
																},
															},
														]);
													}}>
													Add Course Timeline & Project
												</span>
												<span style={{ opacity: 0 }}>Add</span>
											</div>
										</Form>
									</FormWrapper>
								</div>
							)}
						</Tiles>
						<Tiles showForm={resourcesForm}>
							<div
								className="tile-head"
								onClick={() => setResourcesForm(!resourcesForm)}>
								<span>Resources</span>
								<div className="toggle">
									<BsChevronDown />
								</div>
							</div>

							{resourcesForm && (
								<div className="tile-body">
									<UploadWrapper>
										<UploadContent>
											<UploadItemWrapper>
												{loadingSyllabus ? (
													<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
												) : (
													<UploadItem>
														<div className="upload-icon-wrapper">
															<AiOutlineCloudUpload />
														</div>
														{formInputs.syllabus ? (
															<span>{formInputs.syllabus}</span>
														) : (
															<span>
																Drop your PDF here <br /> or
															</span>
														)}
														<form action="" encType="multipart/form-data">
															<label htmlFor="upload-syllabus">Browse</label>
															<input
																onChange={(e) => {
																	uploadSyllabus(e.target.files[0]);
																}}
																style={{ display: "none" }}
																type="file"
																accept="application/pdf"
																id="upload-syllabus"
															/>
														</form>
													</UploadItem>
												)}
												<b>Syllabus Upload</b>
											</UploadItemWrapper>
											<UploadItemWrapper>
												{loadingVideo ? (
													<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
												) : (
													<UploadItem>
														<div className="upload-icon-wrapper">
															<AiOutlineCloudUpload />
														</div>
														{formInputs.featuredVideo ? (
															<span>{formInputs.featuredVideo}</span>
														) : (
															<span>
																Drop your video here <br /> or
															</span>
														)}
														<form action="" encType="multipart/form-data">
															<label htmlFor="upload-video">Browse</label>
															<input
																onChange={(e) => {
																	uploadVideo(e.target.files[0]);
																}}
																style={{ display: "none" }}
																type="file"
																id="upload-video"
																accept="video/*"
															/>
														</form>
													</UploadItem>
												)}
												<b>Video Upload</b>
											</UploadItemWrapper>
											<UploadItemWrapper>
												{loadingImage ? (
													<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
												) : (
													<UploadItem>
														<div className="upload-icon-wrapper">
															<AiOutlineCloudUpload />
														</div>
														{formInputs.featuredImage ? (
															<span>{formInputs.featuredImage}</span>
														) : (
															<span>
																Drop your image here <br /> or
															</span>
														)}
														<form action="" encType="multipart/form-data">
															<label htmlFor="upload-image">Browse</label>
															<input
																onChange={(e) => {
																	uploadImage(e.target.files[0]);
																}}
																style={{ display: "none" }}
																type="file"
																id="upload-image"
																accept="image/png,jpeg,jpg"
															/>
														</form>
													</UploadItem>
												)}
												<b>Featured Image</b>
											</UploadItemWrapper>
										</UploadContent>
										{/* <button className="upload-button">Add</button> */}
									</UploadWrapper>
								</div>
							)}
						</Tiles>
						<Tiles showForm={instructorsForm}>
							<div
								className="tile-head"
								onClick={() => setInstructorsForm(!instructorsForm)}>
								<span>Instructors</span>
								<div className="toggle">
									<BsChevronDown />
								</div>
							</div>



							{instructorsForm && (
								<div className="instructor-tile-body">
									<div>
										<ul className="list-pills">
											{
												selectedInstructors.map((i) => (
													<li className="list-pill" key={i?._id}>{i.firstName + " " + i.lastName} <button onClick={() => {
														const updated1 = formInputs.instructors.filter((ins) => ins._id !== i?._id)
														const updated2 = selectedInstructors.filter((ins) => ins._id !== i?._id)
														setFormInputs({
															...formInputs,
															instructors: updated1
														})
														setSelectedInstructors(updated2)
													}}
													>x</button></li>
												))
											}
										</ul>
									</div>
									<div className="input-wrapper">
										<select
											name="category"
											onChange={(e) => {
												// formInput.current = {
												// 	...formInput.current,
												// 	instructors: [e.target.value],
												// };
												setFormInputs({
													...formInputs,
													instructors: [...formInputs.instructors, e.target.value],
												})
												const selected = instructors.filter((i) => i._id === e.target.value)
												setSelectedInstructors([...selectedInstructors, selected[0]])
											}}>
											<option value="select-instructor" key={0}>
												-- select instructor --
											</option>
											{instructors.map((item, instructorIndex) => {
												if (item._id !== user.userId) {
													return (
														<option key={instructorIndex + 1} value={item._id}>
															{item.firstName + " " + item.lastName}
														</option>
													);
												} else {
													return <></>;
												}
											})}
										</select>
									</div>
								</div>
							)}
						</Tiles>
						<Tiles showForm={courseThumbnailForm}>
							<div
								className="tile-head"
								onClick={() => setCourseThumbnailForm(!courseThumbnailForm)}>
								<span>Course Thumbnail</span>
								<div className="toggle">
									<BsChevronDown />
								</div>
							</div>

							{courseThumbnailForm && (
								<div className="tile-body">
									<CourseThumbnailWrapper>
										<div className="upload-sec">
											<CourseUploadWrapper>
												{loadingThumbnail ? (
													<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
												) : (
													<UploadItem>
														<div className="upload-icon-wrapper">
															<AiOutlineCloudUpload />
														</div>
														<span>
															Drop your image ( 393 x 246 ) here <br /> or
														</span>
														<form action="" encType="multipart/form-data">
															<label htmlFor="upload-thumbnail">Browse</label>
															<input
																onChange={(e) => {
																	uploadThumbnail(e.target.files[0]);
																}}
																style={{ display: "none" }}
																type="file"
																id="upload-thumbnail"
															/>
														</form>
													</UploadItem>
												)}
											</CourseUploadWrapper>
										</div>
										{/* <button className="upload-button">Add</button> */}
									</CourseThumbnailWrapper>
								</div>
							)}
						</Tiles>
					</ListTiles>
				</>
			)}
		</Wrapper>
	);
};

export default ExpertLedCourseUpload;
