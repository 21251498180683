import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

export const PageTitle = styled.h3`
    color: #000000;
    font-size: 20px;
    font-weight: 500;
`;

export const Content = styled.div`
    display: block;
    padding: 20px 20px;

    .table-col {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        h4 {
            font-weight: 500;
            font-size: 15px;
        }
    }
    .table-col .options {
        display: flex;
        align-items: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
        }
        li:first-child {
            margin-right: 8px;
        }
        li:last-child {
            font-size: 19px;
        }
        li.list-view-active{
            background: var(--primary-color);
            color: #fff;
            border-radius: 5px;
            padding: 3px;
        }
        li.grid-view-active{
            background: var(--primary-color);
            color: #fff;
            border-radius: 5px;
            padding: 4px;
        }
    }
`;

export const StatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 960px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }

`;

export const StatsContent = styled.div`
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    max-width: 480px;
    height: 120px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 17px rgba(0, 0, 0, 0.07));

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    ul li:first-child {
        color: #979797;
        font-size: 14px;
        font-weight: 400;
    }

    ul li:last-child {
        font-size: 30px;
        font-weight: 500;
    }
    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        background: rgba(88, 10, 216, 0.2);
        width: 65px;
        height: 65px;
        border-radius: 50%;
        color: #580AD8;
        font-size: 30px;
        position: relative;
    }
    :nth-child(2) .img-wrapper {
        background: rgba(125, 164, 214, 0.2);
        color: #0A6DD8;
    }
    :nth-child(3) .img-wrapper {
        background: rgba(125, 214, 171, 0.2);
        color: #2A960F;
    }
    .img-wrapper::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.12);
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    @media screen and (min-width: 960px) {
        width: 30%;
        height: 150px;
        max-width: unset;
    }
`;

export const TableWrapper = styled.div`
    
`;

export const TableHead = styled.ul`
    display: none;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #C4C4C4;
    ::after {
        content: "";
        display: block;
        width: 25%;
    }
    li {
        width: 25%;
        text-align: left;
        font-size: 12px;
        color: #979797;
        text-transform: uppercase;
    }
    @media screen and (min-width: 960px) {
        display: ${props => props.gridview ? 'none' : 'flex'};;
    }
`;

export const TableBody = styled.div`
	display: ${(props) => (props.gridview ? "grid" : "block")};
	width: 100%;
	max-width: 100%;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	@media screen and (min-width: 880px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (min-width: 1280px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (min-width: 1618px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const TableItem = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding: 8px;
	:nth-child(odd) {
		background: #fff;
	}

	.title-wrapper {
		display: flex;
		align-items: center;
		img {
			margin-right: 10px;
			width: 52px;
			height: 52px;
			border-radius: 5px;
		}
		span {
			color: #000;
			font-size: 13px;
		}
	}

	li {
		color: #000;
		font-size: 13px;
	}

	li a {
		display: none;
		justify-content: center;
		align-items: center;
		color: #fff;
		width: 100px;
		height: 37px;
		font-size: 14px;
		font-weight: 400;
		border-radius: 18.5px;
		background: rgba(10, 109, 216, 0.75);
	}

	@media screen and (min-width: 960px) {
		.title-wrapper {
			span {
				color: #000;
				font-size: 14px;
			}
		}
		li {
			font-size: 14px;
			width: 25%;
		}
		li a {
			display: flex;
		}
	}
`;
export const TableGridItem = styled.div`
	width: 100%;
	background: #fff;
	padding: 12px;
	border-radius: 5px;
	filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.05));

	a {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: transparent;
		width: 100%;
		height: 100%;
		z-index: 2;
		cursor: pointer;
	}

	ul {
		display: flex;
		justify-content: space-between;
	}

	ul:first-child {
		border-bottom: 1px solid #ccc;
		margin-bottom: 15px;
		padding-bottom: 40px;

		.title-wrapper {
			img {
				margin-bottom: 10px;
				width: 52px;
				height: 52px;
				border-radius: 5px;
			}
			span {
				font-weight: 500;
				font-size: 14px;
			}
		}
		li:last-child {
			position: relative;
			z-index: 9;
		}
	}
	ul:nth-child(2) {
		padding: 11px 0px;
		li {
			display: flex;
			align-items: center;
			margin-right: 20px;
			white-space: nowrap;
			font-size: 14px;
			span:first-child {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background: rgba(8, 96, 208, 0.24);
				font-size: 24px;
				color: #1590d8;
				margin-right: 8px;
			}

			@media screen and (min-width: 960px) {
				font-size: 12px;
			}
			@media screen and (min-width: 1280px) {
				font-size: 14px;
			}
		}
		li:last-child {
			display: flex;
			justify-content: flex-end;
			flex: 1;
			margin-right: 0px;
			color: #1590d8;
			font-size: 23px;
			cursor: pointer;
			z-index: 9;
		}
	}
`;
export const ModulesWrapper = styled.div`
    .page-head ul {
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid #C4C4C4;
        margin: 10px 0px 25px 0px;

        li{
            font-size: 14px;
        }

        li:first-child {
            margin-right: 5px;
        }
    }
`;

export const ModulesList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background: #FFFFFF;
    border-radius: 7px;
    margin-bottom: 15px;


    ul:first-child {
        display: flex;
        li:first-child {
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
        }
        li:last-child {
            font-size: 14px;
            font-weight: 400;
            margin-right: 20px;
        }
    }
    ul:last-child {
        display: flex;
        margin-top: 15px;
        align-self: flex-end;
        li:first-child {
            svg {
                font-size: 19px;
            }
        }
        li:nth-child(2) {
            margin: 0px 20px;
            svg {
                font-size: 19px;
            }
        }
        li:last-child {
            display: flex;
            justify-content: flex-end;

            * {
                transform: scale(0.8);
            }

            @media screen and (min-width: 960px) {
                * {
                    transform: scale(1);
                }
            }
        }
        
    }
    @media screen and (min-width: 960px) {
        flex-direction: row;
    
        ul:first-child {
            display: flex;
            li:first-child {
                font-size: 16px;
                margin-right: 25px;
            }
            li:last-child {
                font-size: 16px;
                margin-right: 25px;
            }
        }

        ul:last-child {
            margin-top: 0px;
            flex: 1;

            li:first-child {
                svg {
                    font-size: 23px;
                }
            }
            li:nth-child(2) {
                margin: 0px 25px;
                svg {
                    font-size: 23px;
                }
            }
            li:last-child {
                flex: 1;
            }
        }
    }
`;
