import { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import Header from "../../components/Shared/Header/Header";
import StatsCard from "./StatsCard";
import {
	Content,
	Wrapper,
	TableWrapper,
	TableHead,
	TableBody,
	TableItem,
	TableGridItem,
} from "./styles";
import Switch from "../../components/Shared/Switch/Switch";
import { BsGrid, BsListUl } from "react-icons/bs";
import { BiUser, BiTimeFive, BiBook } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import AxiosCall from "../../../Utils/axios";
import { enableCourse, disableCourse } from "../AllCourses/AllCourses";
import { useCallback } from "react";
// import Loader from '../../components/Shared/Loader/Loader';
import Loading from "../../components/Shared/Loader/Loading";
import { AnimatePresence, motion } from "framer-motion";

export const containerVariants = {
	start: {
		opacity: 0.5,
	},
	end: {
		opacity: 1,

		transition: {
			duration: 0.5,
			ease: "easeInOut",
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: "easeInOut",
		},
	},
};

const Home = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [dashStats, setDashStats] = useState({
		totalStudents: 0,
		activeCourses: 0,
		completedCourses: 0,
	});
	const [courses, setCourses] = useState([]);
	// const [error, setError] = useState(null);
	const [listView, setListView] = useState(true);

	const history = useHistory();

	const getCourses = useCallback(async () => {
		// setIsLoading(true);
		try {
			const res = await AxiosCall({
				method: "GET",
				path: "dashboard",
			});

			if (res.status) {
				setIsLoading(false);
				setDashStats({
					totalStudents: res.totalStudents,
					activeCourses: res.activeCourses,
					completedCourses: 0,
				});
				setCourses(res.courses);
			} else {
				setIsLoading(false);
				// setError("Invalid credentials");
			}
		} catch (err) {
			setIsLoading(false);
			// setError("Invalid credentials");
			console.log(err);
		}
	}, [setIsLoading]);
	useEffect(() => {
		getCourses();
	}, [getCourses]);

	const editCourse = (item) => {
		if (item?.weeks) {
			history.push(`/upload/course/expert/${item["_id"]}`);
		} else if (item?.modules) {
			history.push(`/upload/course/crash/${item["_id"]}`);
		} else {
			history.push(`/upload/course/free/${item["_id"]}`);
		}
	};
	const toggleActive = (course) => {
		if (course?.isActive) {
			disableCourse(course).then(() => {
				getCourses();
			});
		} else {
			enableCourse(course).then(() => {
				getCourses();
			});
		}
	};

	return (
		<Wrapper>
			<Header />
			<AnimatePresence>
				{isLoading ? (
					<Loading />
				) : (
					<motion.div
						variants={containerVariants}
						initial="start"
						animate="end"
						exit={"exit"}
						className="fullheight">
						<Content>
							<StatsCard data={dashStats} />
							<div className="table-col">
								<h4>All Courses</h4>
								<ul className="options">
									<li
										className={
											!listView ? "grid-view-active grid-view" : "grid-view"
										}
										onClick={() => setListView(false)}>
										<BsGrid />
									</li>
									<li
										className={
											listView ? "list-view-active list-view" : "list-view"
										}
										onClick={() => setListView(true)}>
										<BsListUl />
									</li>
								</ul>
							</div>

							<TableWrapper>
								<TableHead gridview={!listView}>
									<li>Course Title</li>
									<li>Duration</li>
									<li>Students Enrolled</li>
									<li>Course Status</li>
									<li>Course Type</li>
								</TableHead>
								<TableBody gridview={!listView}>
									{courses.map((item, index) => {
										return listView ? (
											<TableItem key={index}>
												<li className="title-wrapper">
													<img
														src={item.featuredImage}
														alt={item.title}
													/>
													<span>{item.title}</span>
												</li>
												<li>
													{item?.weeks
														? parseInt(item.duration) + " weeks"
														: parseInt(item.duration) + " minutes"}
												</li>
												<li>{item.students.length}</li>
												<li>
													<Switch
														active={item?.isActive}
														onClick={() => toggleActive(item)}
													/>
												</li>

												<li>
													{item?.weeks
														? "Expert-led course"
														: item?.modules
														? "Crash course"
														: "Free lesson"}
												</li>

												<li>
													{item?.weeks ? (
														<Link to={`/upload/course/expert/${item["_id"]}`}>
															View
														</Link>
													) : item?.modules ? (
														<Link to={`/upload/course/crash/${item["_id"]}`}>
															View
														</Link>
													) : (
														<Link to={`/upload/course/free/${item["_id"]}`}>
															View
														</Link>
													)}
												</li>
											</TableItem>
										) : (
											<TableGridItem key={index}>
												<ul>
													<li className="title-wrapper">
														<img
															src={item.featuredImage}
															alt={item.title}
														/>
														<span>{item.title}</span>
													</li>
													<li>
														<Switch
															active={item?.isActive}
															onClick={() => toggleActive(item)}
														/>
													</li>
												</ul>
												<ul>
													<li>
														<span>
															<BiUser />
														</span>
														<span>{item.students.length} Students</span>
													</li>
													<li>
														<span>
															<BiTimeFive />
														</span>
														<span>
															{item?.weeks
																? parseInt(item.duration) + " weeks"
																: parseInt(item.duration) + " minutes"}
														</span>
													</li>
													<li>
														<span>
															<BiBook />
														</span>
														<span>
															{" "}
															{item?.weeks
																? "Expert-led course"
																: item?.modules
																? "Crash course"
																: "Free lesson"}
														</span>
													</li>

													<li className="btn" onClick={() => editCourse(item)}>
														<IoIosArrowForward />
													</li>
												</ul>
											</TableGridItem>
										);
									})}
								</TableBody>
							</TableWrapper>
						</Content>
					</motion.div>
				)}
			</AnimatePresence>
		</Wrapper>
	);
};

export default Home;
