import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 0px 0px;
`;

export const PageTitle = styled.h3`
    color: #000000;
    font-size: 20px;
    font-weight: 500;
`;

export const Content = styled.div`
    margin-top: 40px;
    padding: 0px 20px;

    .page-title {
        font-size: 18px;
        font-weight: 400;
        color: #5C5C5C;
        margin-bottom: 15px;
    }
`;

export const Form = styled.form`
    display: block;
    input, textarea {
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        padding: 0px 15px;
        color: #222;
        border: 1px solid #1590D8;
        ::placeholder {
            color: #8c8c8c;
        }
    }

    textarea {
        resize: none;
        height: 200px;
        padding: 15px;
    }

    .col-1 {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #D8D8D8;
        padding: 32px 0px 32px 0px;

        label {
            display: block;
            margin-bottom: 22px;
            font-size: 14px;
            font-weight: 400;
            color: #5C5C5C;
        }
        .input-sec {
            width: 100%;
            flex: 1;
            max-width: 480px;
            margin-right: 20px;
            :first-child {
                margin-bottom: 20px;
            }
            @media screen and (min-width: 960px) {
                margin-bottom: 0px;
            }
        }

        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
    }

    .col-2 {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #D8D8D8;
        padding: 32px 0px 32px 0px;

        .user-avatar-label {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            overflow: hidden;
            width: 100%;
            max-width: 480px;
            height: 300px;
            margin-bottom: 20px;

            label {
                position: absolute;
                top: 0px;
                left: 0px; 
                width: 100%;
                max-width: 480px;
                height: 300px;
                cursor: pointer;
            }

            img {
                min-width: 100%;
                min-height: 100%;
                filter: grayscale(100%);
            }

            .edit-box {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 20px;
                right: 20px;
                width: 30px;
                height: 30px;
                background-color: var(--primary-color);
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
            }
        }

        .bio-label {
            display: block;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #5C5C5C;
        }
        .user-avater-input {
            display: none;
        }
        .input-sec {
            width: 100%;
            @media screen and (min-width: 960px) {
                margin-left: 20px;
            }
        }

        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
    }

    .col-3 {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #D8D8D8;
        padding: 32px 0px 50px 0px;

        .input-sec {
            width: 100%;
            flex: 1;
            max-width: 480px;
            margin-right: 20px;
            :first-child {
                margin-bottom: 20px;
            }
            @media screen and (min-width: 960px) {
                margin-bottom: 0px;
            }
        }
        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
        label {
            display: block;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #5C5C5C;
        }
        .input-sec {
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }

    button {
        display: block;
        width: 135px;
        height: 45px;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        border: none;
        margin: 0px auto 73px auto;
        background-color: var(--primary-color);
    }
`;