import React from "react";
import { LoadingWrapper } from "./styles";
import loading from "../../../../assets/svg/loading.svg";
import { motion } from "framer-motion/dist/framer-motion";
const loadingVariants = {
	start: {
		opacity: 1,
	},
	end: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: "ease-in-out",
		},
	},
};
const Loading = () => {
	return (
		<motion.div
			className="fullheight"
			variants={loadingVariants}
			initial="start"
			animate="end"
			exit="exit">
			<LoadingWrapper>
				<img src={loading} alt="cholatrek instructor loading" />
			</LoadingWrapper>
		</motion.div>
	);
};

export default Loading;
