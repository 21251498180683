import { Link } from "react-router-dom";
import { Wrapper, HeadBar, ContentWrapper, Content, ModuleListWrapper, ModuleCard, ModuleFormWrapper, ModuleForm, FormWrapper, Form, FormModal, UploadItemWrapper, UploadItem, LessonCard } from "./syllabusStyles";
import {VscMenu, VscSymbolColor} from 'react-icons/vsc'
import { BsFillPencilFill} from 'react-icons/bs'
import {AiOutlinePlus, AiOutlineCloudUpload} from 'react-icons/ai'
import {BiVideo} from 'react-icons/bi'
import {TiPencil} from 'react-icons/ti'
import {IoTrashOutline} from 'react-icons/io5'
import {MdOutlineQuiz} from 'react-icons/md'
import {RiFileList3Line} from 'react-icons/ri'
import { CgNotes } from "react-icons/cg";
import { useEffect, useRef, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import AxiosCall from "../../../../Utils/axios";
import AxiosUpload from "../../../../Utils/axios/upload";
import Loader from "../../../components/Shared/Loader/Loader";
import Message from "../../../components/Shared/Message/Index";
import { useCallback } from "react";

const CrashCourseSyllabusUpload = () => {
	// let history = useHistory();
	const { courseId } = useParams();
	const [counter, setCounter] = useState(0);
	// const [error, setError] = useState(null)
	const [contentForm, setContentForm] = useState(false);
	const [addModuleForm, setAddModuleForm] = useState(false);
	const [addLesson, setAddLesson] = useState(false);
	const [addVideoModal, setAddVideoModal] = useState(false);
	const [addNoteModal, setAddNoteModal] = useState(false);
	const [addQuizModal, setAddQuizModal] = useState(false);
	const [addInfoGraphicsModal, setAddInfoGraphicsModal] = useState(false);
	const [addActivitiesModal, setAddActivitiesModal] = useState(false);
	const [moduleTitleModal, setModuleTitleModal] = useState(null);
	const [isDeletingModule, setIsDeletingModule] = useState(null);
	const [isDeletingLesson, setIsDeletingLesson] = useState(null);

	const [activeLesson, setActiveLesson] = useState({});
	const [savingLesson, setSavingLesson] = useState(null);

	const [selectedAttachment, setSelectedAttachment] = useState(null);

	const [loadingVideo, setLoadingVideo] = useState(false);
	const [loadingNote, setLoadingNote] = useState(false);
	const [loadingInfoGraphics, setLoadingInfoGraphics] = useState(false);
	const [loadingActivities, setLoadingActivities] = useState(false);

	const videoTitleRef = useRef(null);
	const videoLinkRef = useRef(null);

	const lessonTitleRef = useRef(null);

	const noteTitleRef = useRef(null);
	const noteLinkRef = useRef(null);

	const quizTitleRef = useRef(null);
	const quizLinkRef = useRef(null);

	const infoGraphicsTitleRef = useRef(null);
	const infoGraphicsLinkRef = useRef(null);

	const activitiesTitleRef = useRef(null);
	const activitiesLinkRef = useRef(null);

	const addVideoModalRef = useRef(null);
	const addNoteModalRef = useRef(null);
	const addQuizModalRef = useRef(null);
	const addInfoGraphicsModalRef = useRef(null);
	const addActivitiesModalRef = useRef(null);
	const modalTitleModalRef = useRef(null);

	const formInput = useRef({
		courseId: courseId,
	});

	const clear = useCallback(() => {
		videoTitleRef.current = null;
		videoLinkRef.current = null;

		noteTitleRef.current = null;
		noteLinkRef.current = null;

		quizTitleRef.current = null;
		quizLinkRef.current = null;

		activitiesTitleRef.current = null;
		activitiesLinkRef.current = null;

		formInput.current = {
			courseId: courseId,
			moduleId: selectedModule.current,
			lessonId: selectedLesson.current,
		};
	}, [courseId]);

	const closeModal = (e, modalRef) => {
		if (e.target === modalRef.current) {
			setAddVideoModal(false);
			setAddNoteModal(false);
			setAddQuizModal(false);
			setAddInfoGraphicsModal(false);
			setAddActivitiesModal(false);
			setModuleTitleModal(null);
			setLessonTitle(null);
		}
	};
	const currentModule = useRef(null);
	const selectedModule = useRef(null);
	const selectedLesson = useRef(null);
	const [patch, setPatch] = useState(false);
	const [lessonTitle, setLessonTitle] = useState(null);

	const modules = useRef([]);
	// const [lessons, setLessons] = useState([]);

	console.log("course id: ", courseId);

	const updateLesson = async () => {
		setSavingLesson(selectedLesson.current);

		formInput.current = {
			...formInput.current,
			moduleId: selectedModule.current,
			lessonId: selectedLesson.current,
		};
		console.log("update", formInput.current);
		try {
			const res = await AxiosCall({
				method: "PATCH",
				path: "course/crash/modules",
				data: formInput.current,
			});

			console.log("response: ", res);

			if (res.status) {
				Message.success("Course updated successfully");
				await getLessons();
			}
			setSavingLesson(null);
		} catch (err) {
			setSavingLesson(null);
			// setError("Invalid credentials");
			// console.log(err?.response?.data?.error)
			Message.error(err?.response?.data?.error);
		}
	};

	const saveLesson = async () => {
		setSavingLesson(selectedLesson.current);

		formInput.current = {
			...formInput.current,
			moduleId: selectedModule.current,
			lessonId: selectedLesson.current,
		};
		console.log("saving", formInput.current);
		try {
			const res = await AxiosCall({
				method: "PUT",
				path: "course/crash",
				data: formInput.current,
			});

			console.log("response: ", res);

			if (res.status) {
				Message.success("Course updated successfully");
				await getLessons();
			}
			setSavingLesson(null);
		} catch (err) {
			setSavingLesson(null);
			// setError("Invalid credentials");
			// console.log(err?.response?.data?.error)
			Message.error(err?.response?.data?.error);
		}
	};

	const uploadVideo = async (file) => {
		setLoadingVideo(true);
		let reader = new FileReader();
		reader.onload = (e) => {
			//set values of selected attachment
			let newSelectedAttachment = {};
			newSelectedAttachment.file = file;
			newSelectedAttachment.blobData = e.target.result;

			//if file type is image then show the attachment or download the same
			if (file.type.includes("video")) {
				setSelectedAttachment(newSelectedAttachment);
			}
		};

		//read the file
		reader.readAsDataURL(file);

		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			videoLinkRef.current = res.data[0].url;
			formInput.current = {
				...formInput.current,
				video: res.data[0].url,
			};
		}
		console.log("response", res);
		setLoadingVideo(false);
	};

	const uploadNote = async (file) => {
		setLoadingNote(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			noteLinkRef.current = res.data[0].url;
			noteTitleRef.current = noteTitleRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				notes: { link: noteLinkRef.current, title: noteTitleRef.current },
			};
		}
		console.log("response", res);
		setLoadingNote(false);
	};

	const uploadInfoGraphics = async (file) => {
		setLoadingInfoGraphics(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			infoGraphicsLinkRef.current = res.data[0].url;
			infoGraphicsTitleRef.current =
				infoGraphicsTitleRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				infographics: {
					link: infoGraphicsLinkRef.current,
					title: infoGraphicsTitleRef.current,
				},
			};
		}
		console.log("response", res);
		setLoadingInfoGraphics(false);
	};

	const uploadActivities = async (file) => {
		setLoadingActivities(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			activitiesLinkRef.current = res.data[0].url;
			activitiesTitleRef.current =
				activitiesTitleRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				activities: {
					link: activitiesLinkRef.current,
					title: activitiesTitleRef.current,
				},
			};
		}
		console.log("response", res);
		setLoadingActivities(false);
	};

	const deleteModule = async ({ moduleId, moduleIndex }) => {
		setIsDeletingModule(moduleId);
		try {
			const res = await AxiosCall({
				method: "DELETE",
				path: "course/crash",
				data: {
					courseId: courseId,
					moduleId: moduleId,
				},
			});

			if (res.status) {
				modules.current.splice(moduleIndex, 1);
				Message.success(res.message);
				await getLessons();
			}
			setIsDeletingModule(null);
		} catch (err) {
			setIsDeletingModule(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const deleteLesson = async ({
		moduleId,
		moduleIndex,
		lessonId,
		lessonIndex,
	}) => {
		setIsDeletingLesson(lessonId);
		try {
			const res = await AxiosCall({
				method: "DELETE",
				path: "course/crash",
				data: {
					courseId: courseId,
					moduleId: moduleId,
					lessonId: lessonId,
				},
			});

			if (res.status) {
				modules.current[moduleIndex].lessons.splice(lessonIndex, 1);
				Message.success(res.message);
				await getLessons();
			}
			setIsDeletingLesson(null);
		} catch (err) {
			setIsDeletingLesson(null);
			Message.error(err?.response?.data?.error);
		}
	};

	// useEffect(async () => {
	//     const res = await AxiosCall({
	//         method: "PUT",
	//         path: "course/crash",
	//         data: formInput.current
	//     });

	//     console.log("response: ", res);

	//     if (res.status) {
	//         history.push("/");
	//     }
	// }, [])
	const getLessons = useCallback(async () => {
		try {
			const res = await AxiosCall({
				method: "GET",
				path: "classroom/course/" + courseId,
			});

			modules.current = res.data.modules;
			setCounter(1);

			if (res) {
				clear();
			}
			console.log("response lofty: ", res);
		} catch (err) {
			setSavingLesson(null);
			// setError("Invalid credentials");
			// console.log(err?.response?.data?.error)
			Message.error(err?.response?.data?.message);
		}
	}, [setCounter, courseId, clear]);

	useEffect(() => {
		getLessons();
	}, [getLessons]);

	return (
		<Wrapper>
			<HeadBar>
				<h3>upload courses</h3>
				<Link to="/course/upload">Exit</Link>
			</HeadBar>

			<ContentWrapper>
				<Content showForm={contentForm}>
					<div
						className="tile-head"
						onClick={() => setContentForm(!contentForm)}>
						<span>Syllabus</span>
					</div>

					<p className="upload-info">
						Start putting together your course by creating sections, lectures
						and practice (quizzes, coding exercises and assignments).
						<br />
						<br />
						<strong>Note:</strong>
						&nbsp;Modules can only be saved when they have lessons
					</p>

					<ModuleListWrapper>
						{modules.current.map((data, moduleIndex) => (
							<ModuleCard
								key={moduleIndex}
								onClick={() => {
									selectedModule.current = data.moduleId;
								}}>
								<div className="col-1">
									<h4>Module {moduleIndex + 1}:</h4>
									<span>{data.title}</span>
									<div className="sec-1">
										<BsFillPencilFill
											onClick={() => {
												selectedModule.current = data.moduleId;
												setModuleTitleModal(data.moduleId);

												console.log("Module id: ", selectedModule.current);

												console.log(
													modules.current[selectedModule.current - 1].title
												);
											}}
										/>

										{isDeletingModule === data.moduleId ? (
											<Loader topColor="#0A6DD8" sideColor="#0A6DD866" />
										) : (
											<IoTrashOutline
												onClick={() => {
													selectedModule.current = data.moduleId;
													deleteModule({
														moduleId: data.moduleId,
														moduleIndex: moduleIndex,
													});
												}}
											/>
										)}
									</div>

									<div className="toggle-wrapper">
										<VscMenu />
									</div>
								</div>
								{addLesson && selectedModule.current === data.moduleId && (
									<div
										className="lession-action-column"
										onClick={() => {
											setPatch(false);
											setActiveLesson({});
										}}>
										<button onClick={() => setAddVideoModal(true)}>
											<BiVideo />
											<span>Video</span>
										</button>
										<button onClick={() => setAddNoteModal(true)}>
											<CgNotes />
											<span>Notes</span>
										</button>
										<button onClick={() => setAddQuizModal(true)}>
											<MdOutlineQuiz />
											<span>Quiz</span>
										</button>
										<button onClick={() => setAddInfoGraphicsModal(true)}>
											<VscSymbolColor />
											<span>Infographics</span>
										</button>
										<button onClick={() => setAddActivitiesModal(true)}>
											<RiFileList3Line />
											<span>Activities</span>
										</button>
										<div
											onClick={() => {
												setAddLesson(false);
												selectedModule.current = null;
												setActiveLesson({});
											}}>
											&times;
										</div>
									</div>
								)}
								{data.lessons.map((lessonItem, lessonIndex) => {
									return (
										<LessonCard
											key={lessonIndex}
											onClick={() => {
												setPatch(true);
												selectedLesson.current = lessonItem.lessonId;
												selectedModule.current = data.moduleId;
											}}>
											<div className="lesson-content">
												<div
													className="lesson-header"
													onClick={() => setActiveLesson(lessonItem)}>
													<div className="sec-1">
														<h4>Lesson {lessonIndex + 1}:</h4>
														&nbsp;&nbsp;&nbsp;
														<span>
															{lessonItem.title ?? `Lesson ${lessonIndex + 1}`}:
														</span>
														&nbsp;&nbsp;&nbsp;
														<div
															className="lesson-title"
															onClick={() =>
																setLessonTitle(lessonItem.lessonId)
															}>
															<BsFillPencilFill />
														</div>
														&nbsp; &nbsp;&nbsp;
														{selectedModule.current === data.moduleId &&
														isDeletingLesson === lessonItem.lessonId ? (
															<Loader
																topColor="#0A6DD8"
																sideColor="#0A6DD866"
															/>
														) : (
															<IoTrashOutline
																onClick={() => {
																	selectedModule.current = data.moduleId;
																	deleteLesson({
																		moduleId: data.moduleId,
																		moduleIndex: moduleIndex,
																		lessonId: lessonItem.lessonId,
																		lessonIndex: lessonIndex,
																	});
																}}
															/>
														)}
													</div>
													<div className={"sec-2"}>
														{/* 
                            <button
                                onClick={() => {
                                    selectedWeek.current = item.weekId;
                                    selectedModule.current = data.moduleId;

                                    console.log("Lesson item", lessonItem);

                                    // formInput.current = {
                                    //     ...formInput.current,
                                    //     ...lessonItem
                                    // }
                                    saveLesson();
                                }}>
                                {savingLesson===lessonItem.lessonId &&
                                selectedModule.current===data.moduleId &&
                                selectedWeek.current===item.weekId ? (
                                    <Loader />
                                ) : (
                                    "save"
                                )}
                            </button> */}
													</div>
												</div>

												{selectedModule.current === data.moduleId &&
													activeLesson.lessonId === lessonItem.lessonId && (
														<div className="lesson-action-container">
															<div
																className="lession-action-column"
																onClick={() => {
																	setPatch(true);
																	setSelectedAttachment(null);
																}}>
																<button onClick={() => setAddVideoModal(true)}>
																	<BiVideo />
																	<span>Video</span>
																</button>
																<button onClick={() => setAddNoteModal(true)}>
																	<CgNotes />
																	<span>Notes</span>
																</button>
																<button onClick={() => setAddQuizModal(true)}>
																	<MdOutlineQuiz />
																	<span>Quiz</span>
																</button>
																<button
																	onClick={() => setAddInfoGraphicsModal(true)}>
																	<VscSymbolColor />
																	<span>Infographics</span>
																</button>
																<button
																	onClick={() => setAddActivitiesModal(true)}>
																	<RiFileList3Line />
																	<span>Activities</span>
																</button>
																<div
																	onClick={() => {
																		setAddLesson(false);

																		setActiveLesson({});
																	}}>
																	&times;
																</div>
															</div>

															<div className="action-details-container">
																{lessonItem?.video && (
																	<div className="action-details ">
																		<video
																			className="action-details-video"
																			controls>
																			<source src={lessonItem.video} />
																		</video>
																	</div>
																)}
																{lessonItem?.notes && (
																	<div className="action-details--2">
																		<div className="action-details-icon">
																			<CgNotes />
																		</div>
																		<h4 className="action-details-text">
																			{lessonItem?.notes?.title}
																		</h4>
																	</div>
																)}
																{lessonItem?.infographics && (
																	<div className="action-details--2">
																		<div className="action-details-icon">
																			<VscSymbolColor />
																		</div>
																		<h4 className="action-details-text">
																			{lessonItem?.infographics?.link}
																		</h4>
																	</div>
																)}
																{lessonItem?.assessment && (
																	<div className="action-details--2">
																		<div className="action-details-icon">
																			<MdOutlineQuiz />
																		</div>4
																		<h4 className="action-details-text">
																			{lessonItem?.test?.link}
																		</h4>
																	</div>
																)}

																{lessonItem?.activities && (
																	<div className="action-details--2">
																		<div className="action-details-icon">
																			<RiFileList3Line />
																		</div>
																		<h4 className="action-details-text">
																			{lessonItem?.activities?.link}
																		</h4>
																	</div>
																)}
															</div>
														</div>
													)}
											</div>
										</LessonCard>
									);
								})}

								<button
									className="add-lesson-btn"
									onClick={() => {
										setPatch(false);
										setAddLesson(false);

										setTimeout(() => {
											selectedModule.current = data.moduleId;
											selectedLesson.current =
												modules.current[selectedModule.current - 1]?.lessons
													.length + 1;
											setAddLesson(true);
										}, 200);
									}}>
									<AiOutlinePlus />
									<span>Add Lessons</span>
								</button>
							</ModuleCard>
						))}
					</ModuleListWrapper>

					{!addModuleForm ? (
						<button
							className="add-module-btn"
							onClick={() => {
								setAddModuleForm(true);
							}}>
							<AiOutlinePlus />
							<span>Add Module</span>
						</button>
					) : (
						<ModuleFormWrapper>
							<ModuleForm>
								<div className="col-1">
									<span>New Module:</span>
									<input
										ref={currentModule}
										type="text"
										placeholder="Enter module name"
									/>
								</div>
								<div className="col-2">
									<button onClick={() => setAddModuleForm(false)}>
										Discard
									</button>
									<button
										onClick={() => {
											setAddModuleForm(false);

											console.log("Selected module", selectedModule.current);

											let newModules = modules.current;
											let newModuleId;

											if (selectedModule.current <= modules.current.length) {
												newModuleId = modules.current.length + 1;

												newModules = [
													...newModules,
													{
														moduleId: newModuleId,
														title: currentModule.current?.value,
														isNewLesson: true,
														lessons: [],
													},
												];
											} else {
												newModuleId = 1;

												newModules = [
													...newModules,
													{
														moduleId: newModuleId,
														title: currentModule.current?.value,
														isNewLesson: true,
														lessons: [],
													},
												];
											}

											modules.current = newModules;

											formInput.current = {
												...formInput.current,
												moduleId: newModuleId,
												moduleTitle: currentModule.current?.value,
											};
											selectedModule.current = null;

											setCounter(2);
										}}>
										Save
									</button>
								</div>
							</ModuleForm>
						</ModuleFormWrapper>
					)}
					{addVideoModal && (
						<FormModal
							ref={addVideoModalRef}
							onClick={(e) => closeModal(e, addVideoModalRef)}>
							<FormWrapper>
								<Form>
									{/* <input ref={videoTitleRef} placeholder="Subject"  defaultValue={""} /> */}
									<label style={{ marginBottom: "2.5rem" }}></label>
									<UploadItemWrapper>
										<UploadItem>
											{loadingVideo ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : selectedAttachment ? (
												<video
													style={{ background: "#222", borderRadius: 10 }}
													className="action-details-video"
													controls>
													<source
														src={
															selectedAttachment?.blobData ??
															activeLesson?.video
														}
														type={selectedAttachment?.file?.type}
													/>
													Your browser does not support HTML5 video.
												</video>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your Video file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-video">Browse</label>
														<input
															onChange={(e) => {
																uploadVideo(e.target.files[0]);
															}}
															style={{ display: "none" }}
															type="file"
															accept="video/*"
															id="upload-video"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddVideoModal(false);

											const availableLessons = modules.current[
												selectedModule.current - 1
											].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);

											if (!patch) {
												if (availableLessons.length === 0) {
													const newModules = modules.current;
													newModules[selectedModule.current - 1].lessons = [
														...newModules[selectedModule.current - 1].lessons,
														{
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: videoTitleRef.current?.value,
															url: videoLinkRef.current,
														},
													];
													modules.current = newModules;
												}
											}

											formInput.current = {
												...formInput.current,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current,
												video: videoLinkRef.current,
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addNoteModal && (
						<FormModal
							ref={addNoteModalRef}
							onClick={(e) => closeModal(e, addNoteModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={noteTitleRef}
										placeholder="Subject"
										defaultValue={activeLesson.notes?.title}
									/>
									{console.log(activeLesson)}
									<UploadItemWrapper>
										<UploadItem>
											{loadingNote ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : noteLinkRef.current ? (
												<div className="image-preview">
													<img
														src={noteLinkRef.current}
														alt={noteLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<label htmlFor="upload-note">Browse</label>
												</>
											)}
											<input
												onChange={(e) => {
													uploadNote(e.target.files[0]);
												}}
												accept="application/pdf"
												style={{ display: "none" }}
												type="file"
												id="upload-note"
											/>
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddNoteModal(false);

											const availableLessons = modules.current[
												selectedModule.current - 1
											].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newModules = modules.current;
													newModules[selectedModule.current - 1].lessons = [
														...newModules[selectedModule.current - 1].lessons,
														{
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: noteTitleRef.current?.value,
															url: noteLinkRef.current,
														},
													];

													modules.current = newModules;
												}
											}
											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												notes: {
													title:
														noteTitleRef.current?.value ?? noteTitleRef.current,
													link: noteLinkRef.current,
												},
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addQuizModal && (
						<FormModal
							ref={addQuizModalRef}
							onClick={(e) => closeModal(e, addQuizModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={quizTitleRef}
										placeholder="Subject"
										defaultValue={activeLesson?.test?.title ?? ""}
									/>
									<label htmlFor="">
										Use External Hosted Content ( URL to your Quiz )
									</label>
									<input
										ref={quizLinkRef}
										placeholder="Link to Quiz"
										defaultValue={activeLesson?.test?.link ?? ""}
									/>

									<button
										onClick={(e) => {
											e.preventDefault();
											setAddQuizModal(false);

											const availableLessons = modules.current[
												selectedModule.current - 1
											].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newModules = modules.current;
													newModules[selectedModule.current - 1].lessons = [
														...newModules[selectedModule.current - 1].lessons,
														{
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: quizTitleRef.current?.value,
															link: quizLinkRef.current?.value,
														},
													];

													modules.current = newModules;
												}
											}
											formInput.current = {
												...formInput.current,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												test: {
													title: quizTitleRef.current?.value,
													link: quizLinkRef.current?.value,
												},
											};

											if (!patch) {
												saveLesson();
											} else {
												updateLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addInfoGraphicsModal && (
						<FormModal
							ref={addInfoGraphicsModalRef}
							onClick={(e) => closeModal(e, addInfoGraphicsModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={infoGraphicsTitleRef}
										placeholder="Subject"
										defaultValue={activeLesson.infographics?.title ?? ""}
									/>
									<UploadItemWrapper>
										<UploadItem>
											{loadingInfoGraphics ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : infoGraphicsLinkRef.current ? (
												<div className="image-preview">
													<img
														src={infoGraphicsLinkRef.current}
														alt={infoGraphicsLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													{" "}
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-info-graphics">Browse</label>
														<input
															onChange={(e) => {
																uploadInfoGraphics(e.target.files[0]);
															}}
															accept="application/pdf"
															style={{ display: "none" }}
															type="file"
															id="upload-info-graphics"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddInfoGraphicsModal(false);

											const availableLessons = modules.current[
												selectedModule.current - 1
											].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newModules = modules.current;
													newModules[selectedModule.current - 1].lessons = [
														...newModules[selectedModule.current - 1].lessons,
														{
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: quizTitleRef.current?.value,
															link: quizLinkRef.current?.value,
														},
													];

													modules.current = newModules;
												}
											}

											formInput.current = {
												...formInput.current,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												infographics: {
													title:
														infoGraphicsTitleRef.current?.value ??
														infoGraphicsTitleRef.current,
													link: infoGraphicsLinkRef.current,
												},
											};
											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addActivitiesModal && (
						<FormModal
							ref={addActivitiesModalRef}
							onClick={(e) => closeModal(e, addActivitiesModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={activitiesTitleRef}
										placeholder="Subject"
										defaultValue={activeLesson.activities?.title}
									/>
									<UploadItemWrapper>
										<UploadItem>
											{loadingActivities ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : activitiesLinkRef.current ? (
												<div className="image-preview">
													<img
														src={activitiesLinkRef.current}
														alt={activitiesLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-activities">Browse</label>
														<input
															onChange={(e) => {
																uploadActivities(e.target.files[0]);
															}}
															accept="application/pdf"
															style={{ display: "none" }}
															type="file"
															id="upload-activities"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddActivitiesModal(false);

											const availableLessons = modules.current[
												selectedModule.current - 1
											].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newModules = modules.current;
													newModules[selectedModule.current - 1].lessons = [
														...newModules[selectedModule.current - 1].lessons,
														{
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: activitiesTitleRef.current?.value,
															link: activitiesLinkRef.current?.value,
														},
													];

													modules.current = newModules;
												}
											}

											formInput.current = {
												...formInput.current,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												infographics: {
													title:
														activitiesTitleRef.current?.value ??
														activitiesTitleRef.current,
													link: activitiesLinkRef.current,
												},
											};
											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{moduleTitleModal !== null && (
						<FormModal
							ref={modalTitleModalRef}
							onClick={(e) => closeModal(e, modalTitleModalRef)}>
							<FormWrapper minHeight="unset">
								<Form>
									<input
										ref={activitiesTitleRef}
										placeholder="Enter Module Title"
										defaultValue={
											modules.current[selectedModule.current - 1].title
										}
										onChange={(e) => {
											modules.current[selectedModule.current - 1].title =
												e.target.value;

											formInput.current = {
												...formInput.current,
												moduleTitle: e.target.value,
												moduleId: selectedModule.current,
											};
										}}
									/>
									<button
										onClick={(e) => {
											e.preventDefault();
											setModuleTitleModal(null);
											updateLesson();
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{lessonTitle !== null && (
						<FormModal
							ref={lessonTitleRef}
							onClick={(e) => closeModal(e, lessonTitleRef)}>
							<FormWrapper minHeight="unset">
								<Form>
									<input
										ref={activitiesTitleRef}
										placeholder="Enter Module Title"
										defaultValue={
											modules.current[selectedModule.current - 1]?.lessons[
												selectedLesson.current - 1
											]?.title
										}
										onChange={(e) => {
											formInput.current = {
												...formInput.current,
												lessonTitle: e.target.value,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current,
											};
										}}
									/>
									<button
										onClick={(e) => {
											e.preventDefault();
											updateLesson();
											setLessonTitle(null);
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
};
 
export default CrashCourseSyllabusUpload;
