import styled from 'styled-components';

export const Wrapper = styled.div`
    display: block;
    .top-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin-top: 43px;
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }

    .form-footer-wrapper .bottom-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin: 25px 0px 43px 0px;
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }
`;

export const HeadBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #fff;
    padding: 0px 20px;

    h3 {
        font-size: 14px;
        font-weight: 400;
        color: #1590D8;
        text-transform: uppercase;
    }
`;

export const FormWrapper = styled.div`
    display: block;
    padding: 20px;
`;

export const Form = styled.form`
    display: block;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.07);

    .input-col {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        .input-wrapper {
            width: 100%;
            max-width: 480px;
            margin-bottom: 0px;
            :last-of-type {
                margin: 0px 0px;
            }
        }
        label {
            width: 100%;
        }

        .sec {
            margin-bottom: 25px;
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #7CBAFD;
            height: 50px;
            border-radius: 10px;
            width: 100%;
        }
        input {
            padding: 0px 20px;
            margin-bottom: 0px;
            width: 100%;
        }
        select {
            display: block;
            border: none;
            width: 100%;
            height: 48px;
            padding: 0px 20px;
            border-radius: 10px;
            background: #fff;
        }
        input:first-child {
            border: none;
            height: unset;
        }

        svg {
            fill: var(--primary-color);
        }

        .input-wrapper {
            margin-bottom: 25px;
        }

        @media screen and (min-width: 720px) {
            flex-direction: row;
            align-items: flex-end;

            .input-wrapper {
                width: 100%;
                max-width: 480px;
                margin-bottom: 0px;
                :nth-child(2) {
                    margin: 0px 20px;
                }
            }

            input {
                margin-bottom: 0px;
                width: 100%;
                max-width: 480px;
            }
            .sec {
                margin: 0px 20px 0px 0px;
            }
        }
    }

    label {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
    }
    input {
        display: block;
        border: 1px solid #7CBAFD;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        margin-bottom: 25px;
        border-radius: 10px;

        ::placeholder {
            color: #989898;
            font-weight: 400;
        }
    }
    .input-col {
        display: flex;
        flex-direction: column;
        label {
            width: 100%;
        }

        .sec {
            margin-bottom: 25px;
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #7CBAFD;
            height: 50px;
            padding: 0px 20px;
            border-radius: 10px;
            width: 100%;
        }
        input {
            margin-bottom: 0px;
            width: 100%;
        }
        input:first-child {
            border: none;
            padding: 0px;
            height: unset;
        }

        svg {
            fill: var(--primary-color);
        }

        @media screen and (min-width: 480px) {
            flex-direction: row;
            align-items: flex-end;

            input {
                margin-bottom: 0px;
                width: unset;
                max-width: 275px;
            }
            .input-wrapper {
                max-width: 275px;
            }
            .sec {
                margin: 0px 20px 0px 0px;
            }
        }
    }

    .form-editor-sec {
        margin-top: 25px;
        h4 {
            display: block;
            font-size: 14px;
            color: #000;
            margin-bottom: 6px;
            font-weight: 400;
        }

        .ck.ck-toolbar {
            border: 1px solid #7CBAFD !important;
            border-bottom: none !important;
            border-top-right-radius: 10px !important;
            border-top-left-radius: 10px !important;
            height: 83px;
            background: #fff;
        }
        .ck.ck-toolbar > .ck-toolbar__items {
            justify-content: space-around;
        }
        .ck.ck-editor__editable_inline {
            border: 1px solid #7CBAFD !important;
            height: 600px;
            border-bottom-right-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
        }
    }
`;

export const UploadWrapper = styled.div`
    display: block;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 42px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.07);

    h4 {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
        font-weight: 400;
    }
`

export const UploadContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border: 1px solid #7CBAFD;
    padding: 20px;
    border-radius: 10px;
    min-height: 300px;

    .upload-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        background-color: #CCDFF3;
        border-radius: 50%;
        margin-bottom: 20px;

        svg {
            fill: var(--primary-color);
            font-size: 40px;
        }
    }

    span {
        color: #989898;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    form label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 35px;
        border-radius: 5px;
        background-color: rgba(10, 109, 216, 0.75);
        color: #fff;
        font-size: 13px;
        font-weight: 400;
        margin-top: 20px;
        cursor: pointer;
    }

    @media screen and (min-width: 960px) {
        height: 600px;
        .upload-icon-wrapper {
            width: 150px;
            height: 150px;

            svg {
                font-size: 60px;
            }
        }
        span {
            font-size: 24px;
        }

        form label {
            width: 135px;
            height: 45px;
            cursor: pointer;
        }
    }
`
