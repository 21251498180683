import React, { useState } from "react";

import { Link } from "react-router-dom";

// import Message from "../../components/Shared/Message/Index";
import { FormModal, FormWrapper, Form } from "./styles";
import { TableBody, TableItem } from "./styles";
import Loader from "../../components/Shared/Loader/Loader";
import AxiosCall from "../../../Utils/axios";

import Message from "../../components/Shared/Message/Index";

const TableList = ({ studentList, courseItem, activeStudent, getCourses }) => {
	const [state, setState] = useState({});
	const getGrade = (student) => {
		if (courseItem.weeks) {
			const course = student?.expert?.find(
				(course, index) => course.courseId === courseItem._id
			);
			return course?.average?.toFixed(1);
		} else if (courseItem.modules) {
			const course = student?.crash?.find(
				(course, index) => course.courseId === courseItem._id
			);
			return course?.average?.toFixed(1);
		} else {
			const course = student?.crash?.find(
				(course, index) => course.courseId === courseItem._id
			);
			return course?.average?.toFixed(1);
		}
	};

	const makeGraduate = async (studentId) => {
		try {
			const payLoad = {
				courseId: courseItem?._id,
				studentId,
			};
			console.log("payload", payLoad);
			const res = await AxiosCall({
				method: "PATCH",
				path: "classroom/course/graduates",
				data: payLoad,
			});

			console.log("response: ", res);

			if (res.status) {
				getCourses();
				Message.success("Student updated successfully");
			} else {
				Message.error(res?.data?.error);
			}
		} catch (err) {
			// setError("Invalid credentials");
			Message.error(err.response?.data?.message ?? err.response?.data?.error);
		}
	};

	const makeSpillover = async (studentId) => {
		try {
			const payLoad = {
				courseId: courseItem?._id,
				studentId,
			};
			console.log("payload", payLoad);
			const res = await AxiosCall({
				method: "PATCH",
				path: "classroom/course/spillovers",
				data: payLoad,
			});

			console.log("response: ", res);

			if (res.status) {
				getCourses();
				Message.success("Student updated successfully");
			} else {
				Message.error(res?.data?.error);
			}
		} catch (err) {
			// setError("Invalid credentials");
			Message.error(err.response?.data?.message ?? err.response?.data?.error);
		}
	};

	return (
		<TableBody>
			{console.log(courseItem)}
			{studentList?.map((studentItem, index) => (
				<TableItem key={index}>
					<li>{studentItem.firstName + " " + studentItem.lastName}</li>

					<li>{studentItem.email}</li>
					<li>{studentItem.phone ?? ""}</li>

					<li>{getGrade(studentItem)}</li>

					{/* {activeStudent !== "students" && (
						<li>
							<button
								className="status__btn btn"
								onClick={() => {
									// makeSpillover(studentItem?._id);
								}}>
								Make Student
							</button>
						</li>
					)} */}
					{activeStudent !== "graduates" && (
						<li>
							<button
								className="status__btn btn"
								onClick={() => {
									makeGraduate(studentItem?._id);
								}}>
								Make Graduate
							</button>
						</li>
					)}

					{activeStudent !== "spillovers" && (
						<li>
							<button
								className="status__btn btn"
								onClick={() => {
									makeSpillover(studentItem?._id);
								}}>
								Make Spillover
							</button>
						</li>
					)}
					<li>
						<Link
							to={{
								pathname: "/classroom/progress/" + studentItem._id,
								state: {
									weeks: courseItem.weeks,
									courseId: courseItem._id,
									student: studentItem,
								},
							}}>
							Progress
						</Link>
					</li>
					<li>
						<Link
							to={{
								pathname: "/classroom/grade/" + studentItem._id,
								state: {
									weeks: courseItem.weeks,
									courseId: courseItem._id,
									student: studentItem,
								},
							}}>
							Grade
						</Link>
					</li>
				</TableItem>
			))}
			{/* {state && (
				<FormModal onClick={() => {}}>
					<FormWrapper>
						<Form>
							<div className="modal-form">
								<div className="input-col">
									<div className="input-wrapper">
										<select
											name="category"
											onChange={(e) => {
												/* 	formInput.current = {
													...formInput.current,
													category: e.target.value,
												}; 
											}}>
											<option selected value="Web 3">
												Web 3
											</option>
										</select>
									</div>
								</div>
							</div>

							<button
								onClick={(e) => {
									e.preventDefault();
								}}>
								{"sendingAccouncement" ? <Loader /> : "Send Message"}
							</button>
						</Form>
					</FormWrapper>
				</FormModal>
			)} */}
		</TableBody>
	);
};

export default TableList;
