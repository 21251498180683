import { Link } from "react-router-dom";
import { Wrapper, FormWrapper, HeadBar, Form, UploadWrapper, UploadContent } from "./styles";
// import {MdDateRange} from 'react-icons/md'
import { AiOutlineCloudUpload} from 'react-icons/ai'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AxiosCall, { hostUrl } from "../../../../Utils/axios";
import AxiosUpload from "../../../../Utils/axios/upload";
import { useState, useEffect } from "react";
import Loader from "../../../components/Shared/Loader/Loader";
import { useHistory, useParams } from "react-router-dom";
import Message from "../../../components/Shared/Message/Index";
import { useRef } from "react";
import { config as configuration } from "../../../../Utils/axios/upload";
import { useCallback } from "react";
import Loading from "../../../components/Shared/Loader/Loading";
import axios from "axios";
const FreeLessonUpload = () => {
	let history = useHistory();
	const { courseId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	// const [error, setError] = useState(null);
	const [loadingVideo, setLoadingVideo] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	
	const [formInput, setFormInput] = useState({
		title: "",
		description: "",
		duration: "",
		documentation: "",
		videoURL: "",
		featuredImage: "",
		category: "",
	});
	const documentation = useRef("");
	const config = configuration();

	const [selectedAttachment, setSelectedAttachment] = useState(null);

	const videoLinkRef = useRef(null);
	const imageLinkRef = useRef(null);
	/* 
	const viewAttachment = (file) => {
		let reader = new FileReader();

		//if reading completed
		reader.onload = (e) => {
			//set values of selected attachment
			let newSelectedAttachment = {};
			newSelectedAttachment.file = file;
			newSelectedAttachment.blobData = e.target.result;

			//if file type is image then show the attachment or download the same
			if (file.type.includes("video")) {
				setSelectedAttachment(newSelectedAttachment);
			}
		};

		//read the file
		reader.readAsDataURL(file);
	};
 */

	const getCategory = useCallback(async () => {
		const token = localStorage.getItem("authToken");
		// console.log("fewf token: ", token);

		const headers = {
			cholatrek_access_token: token,
			"Content-Type": "application/json",
		};
		try {
			const res = await axios(
				`${hostUrl}/admin/category/all`,
				headers
			);

			/* 	const res = await axios(
		
				"https://api.cholatrek.io/admin/category/all",
				headers
			); */
			if (res) {
				setCategories(res.data.data);
			}

			console.log("categories: ", res.data);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, []);
	const getCourse = useCallback(async () => {
		if (courseId !== "new") {
			try {
				const res = await AxiosCall({
					method: "GET",
					path: "classroom/course/" + courseId,
				});
				imageLinkRef.current = res.data.featuredImage;
				videoLinkRef.current = res.data.videoURL;

				if (res.status) {
					setFormInput({
						courseId: res.data._id ?? courseId,
						title: res.data.title,
						duration: res.data.duration,
						description: res.data.description,
						body: res.data.body,
						documentation: res.data.documentation,
						category: res.data.category,
					});
					documentation.current = res.data.documentation;
				}

				// timeline = res.data.timeline
				setPageLoading(false);
				console.log("response: ", res);
			} catch (err) {
				Message.error(err?.response?.data?.error);
				setPageLoading(false);
			}
		} else {
			setPageLoading(false);
		}
	}, [courseId]);
	useEffect(() => {
		getCourse();
	}, [ getCourse ] );
	useEffect(() => {
		getCategory();
	}, [getCategory]);

	const uploadCourse = async () => {
		setIsLoading(true);

		try {
			if (courseId === "new") {
				const payLoad = {
					...formInput,

					videoURL: videoLinkRef.current,
					featuredImage: imageLinkRef.current,
					category: formInput.category,
					documentation: documentation.current,
				};

				console.log("payload upload", payLoad);
				const res = await AxiosCall({
					method: "POST",
					path: "course/free",
					data: payLoad,
				});

				console.log("response: ", res);

				if (res.status) {
					Message.success("Course created successfully");
					history.push("/upload/course/free/" + res.data._id);
					setFormInput({
						...formInput,
						courseId: res.data._id,
					});
					await getCourse();
				} else {
					Message.error(res.data?.error);
				}
			} else {
				const payLoad = {
					...formInput,
					courseId: formInput.courseId,
					videoURL: videoLinkRef.current,
					featuredImage: imageLinkRef.current,
					category: formInput.category,
					documentation: documentation.current,
				};
				console.log("payload", payLoad);
				const res = await AxiosCall({
					method: "PATCH",
					path: "course/free",
					data: payLoad,
				});

				console.log("response: ", res);

				if (res.status) {
					Message.success("Course updated successfully");
					history.push("/upload/course/free/" + res.data._id);
				} else {
					Message.error(res?.data?.error);
				}
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			// setError("Invalid credentials");
			Message.error(err.response?.data?.message ?? err.response?.data?.error);
			setIsLoading(false);
		}
	};

	const uploadfile = async (file) => {
		setLoadingVideo(true);
		const formData = new FormData();

		// Update the formData object
		formData.append("file", file);

		// Details of the uploaded file
		console.log("file", file);
		console.log("name", file.name);

		// Request made to the backend api
		// Send formData object

		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});
		if (res) {
			videoLinkRef.current = res.data[0].url;
			setFormInput({ ...formInput, videoURL: res.data[0].url });
		}

		console.log("response", res);
		setLoadingVideo(false);
	};

	const uploadImage = async (file) => {
		setLoadingImage(true);
		const formData = new FormData();

		// Update the formData object
		formData.append("file", file);

		// Details of the uploaded file
		console.log("file", file);
		console.log("name", file.name);

		// Request made to the backend api
		// Send formData object

		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		imageLinkRef.current = res.data[0].url;

		console.log("response", res);
		setLoadingImage(false);
	};

	return (
		<Wrapper>
			<HeadBar>
				<h3>upload courses</h3>
				<Link to="/course/upload">Exit</Link>
			</HeadBar>
			{pageLoading ? (
				<Loading />
			) : (
				<>
					<div className="top-action">
						<button onClick={uploadCourse}>
							{isLoading ? <Loader /> : "Publish"}
						</button>
					</div>

					<FormWrapper>
						<Form>
							<label htmlFor="">Course Title</label>
							<input
								onChange={(e) =>
									setFormInput({ ...formInput, title: e.target.value })
								}
								type="text"
								placeholder="Insert Course Title"
								defaultValue={formInput?.title}
							/>

							<label htmlFor="">Short Description</label>
							<input
								onChange={(e) =>
									setFormInput({ ...formInput, description: e.target.value })
								}
								type="text"
								placeholder="Insert Course Description"
								defaultValue={formInput?.description}
							/>

							<label htmlFor="">Basic info</label>
							<div className="input-col">
								<div className="sec">
									<div className="input-wrapper">
										<select
											defaultValue={formInput?.category}
											name="category"
											onChange={(e) => {
												setFormInput({
													...formInput,
													category: e.target.value,
												});
											}}>
											<option value="design">-- select category --</option>
											{categories?.map((category) => (
												<option
													selected={
														formInput.current?.category === category.name
													}
													value={category?.name}>
													{category?.name}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="input-wrapper ">
									<input
										defaultValue={formInput?.duration}
										onChange={(e) =>
											setFormInput({ ...formInput, duration: e.target.value })
										}
										type="number"
										placeholder="Duration in minutes"
									/>
								</div>
							</div>

							<div className="form-editor-sec">
								<h4>Contents</h4>
								<CKEditor
									editor={ClassicEditor}
									data={formInput?.documentation}
									config={{
										ckfinder: {
											uploadUrl: `${config.baseUrl}/v1/file/upload/${config.token}?ckeditor=true`,
										},
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										// console.log( { event, editor, data } )
										if (data) {
											documentation.current = data;
										}
									}}
									onBlur={(event, editor) => {
										// console.log( 'Blur.', editor );
									}}
									onFocus={(event, editor) => {
										// console.log( 'Focus.', editor );
									}}
								/>
							</div>
						</Form>

						<UploadWrapper>
							<h4>Upload Video</h4>
							<UploadContent>
								{loadingVideo && selectedAttachment ? (
									<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
								) : (
									<>
										{videoLinkRef.current && (
											<>
												<video
													style={{ background: "#222", borderRadius: 10 }}
													width="300"
													height="200"
													controls>
													<source src={videoLinkRef.current} />
													Your browser does not support HTML5 video.
												</video>{" "}
												<form action="" encType="multipart/form-data">
													<label htmlFor="upload-video">Change</label>
												</form>
											</>
										)}
										{
											<>
												{!videoLinkRef.current && (
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
												)}
												{!videoLinkRef.current && (
													<span>Drage & drop a video</span>
												)}
												<form action="" encType="multipart/form-data">
													{!videoLinkRef.current && (
														<label htmlFor="upload-video">Browse</label>
													)}

													<input
														accept="video/*"
														multiple={false}
														beforeupload={(e) => false}
														showuploadlist={false}
														onChange={(info) => {
															setSelectedAttachment(null);

															let reader = new FileReader();
															//if reading completed
															reader.onload = (e) => {
																//set values of selected attachment
																let newSelectedAttachment = {};
																newSelectedAttachment.file =
																	info.target.files[0];
																newSelectedAttachment.blobData =
																	e.target.result;

																//if file type is image then show the attachment or download the same
																if (
																	info.target.files[0].type.includes("video")
																) {
																	setSelectedAttachment(newSelectedAttachment);
																	uploadfile(info.target.files[0]);
																}
															};

															//read the file
															reader.readAsDataURL(info.target.files[0]);
														}}
														style={{ display: "none" }}
														type="file"
														id="upload-video"
													/>
												</form>
											</>
										}
									</>
								)}
							</UploadContent>
						</UploadWrapper>

						<UploadWrapper>
							<h4>Upload Featured Image</h4>
							<UploadContent>
								{loadingImage ? (
									<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
								) : (
									<>
										{imageLinkRef.current && (
											<>
												<img
													width="400"
													src={imageLinkRef.current}
													alt="file"
												/>
												<form action="" encType="multipart/form-data">
													<label htmlFor="upload-image">Change</label>
												</form>
											</>
										)}
										{!loadingImage && !imageLinkRef.current && (
											<div className="upload-icon-wrapper">
												<AiOutlineCloudUpload />
											</div>
										)}
										{!loadingImage && !imageLinkRef.current && (
											<span>Drage & drop an image</span>
										)}
										<form action="" encType="multipart/form-data">
											{!loadingImage && !imageLinkRef.current && (
												<label htmlFor="upload-image">Browse</label>
											)}

											{!loadingImage && (
												<input
													accept="image/png,jpg,jpeg"
													multiple={false}
													id={"upload-image"}
													beforeUpload={(e) => false}
													showUploadList={false}
													onChange={(info) => {
														uploadImage(info.target.files[0]);

														console.log("Selected image");

														let reader = new FileReader();
														//if reading completed
														reader.onload = (e) => {
															//set values of selected attachment
															let newSelectedAttachment = {};
															newSelectedAttachment.file = info.target.files[0];
															newSelectedAttachment.blobData = e.target.result;

															//if file type is image then show the attachment or download the same
															if (info.target.files[0].type.includes("video")) {
																setSelectedAttachment(newSelectedAttachment);
															}
														};

														//read the file
														reader.readAsDataURL(info.target.files[0]);
													}}
													style={{ display: "none" }}
													type="file"
												/>
											)}
										</form>
									</>
								)}
							</UploadContent>
						</UploadWrapper>
					</FormWrapper>
					<div className="form-footer-wrapper">
						<div className="bottom-action">
							<button onClick={uploadCourse}>
								{isLoading ? <Loader /> : "Publish"}
							</button>
						</div>
					</div>
				</>
			)}
		</Wrapper>
	);
};;

export default FreeLessonUpload;
