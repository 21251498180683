import styled from 'styled-components'

export const Wrapper = styled.div`
    display: block;
    background: #fafafa;
`;

export const Content = styled.div`
    display: block;
    margin-left: 0px;

    @media screen and (min-width: 720px) {
        margin-left: 245px;
    }
`;