import { useState, useRef } from "react";
import { FormWrapper, ImageWrapper, Wrapper } from "./styles";
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
// import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, saveToken, setUser } from "../../../actions";
import Loader from "../../components/Shared/Loader/Loader";
import AxiosCall from "../../../Utils/axios";
import Message from "../../components/Shared/Message/Index";

const SignIn = () => {
    const [rememberMe, setRememberMe] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [loginStatus, setLoginStatus] = useState(false);
    const [error, setError] = useState(null);
    const email = useRef(0)
    const password = useRef(0)
    const tokenState = useSelector(state => state.token);
    const loginState = useSelector(state => state.isLoggedIn);
    const dispatch = useDispatch()

    const login = async (e) =>  {
        e.preventDefault();
        setIsLoading(true)
        console.log("email", email.current.value);
        console.log("password", password.current.value);

        console.log('current token', tokenState);

        setError(null)

        try {
            const res = await AxiosCall({
                method: "POST",
                path: "signin",
                data: {
                    email: email.current.value,
                    password: password.current.value
                }
              });

            console.log(res);


            if (res.status) {
                setIsLoading(false)
                console.log('new token', tokenState);
                dispatch(saveToken(res.token))
                localStorage.setItem("authToken", res.token)
                const userData = {
                    userId: res.data._id,
                    email: res.data.email,
                    username: res.data.username,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    profileImg: res.data.profileImg,
                    stack: res.data.stack
                }
                dispatch(setUser(userData))
                dispatch(loginUser())
            } else {
                setIsLoading(false)
              
            }
        } catch (err) {
            setIsLoading(false)
            Message.error(err?.response?.data?.error);
        }
    }
    return (
        !loginState ? <Wrapper>
            <ImageWrapper>
                <img className="wrapper-img" src="/img/form-background.png" alt="cholatrek" />
                <div className="logo-wrapper">
                    <img src="/img/logo.png" alt="cholatrek" />
                    <span>INSTITUTE</span>
                </div>
            </ImageWrapper>
            <FormWrapper>
                <h2>Hi there,</h2>
                <h4>Sign in to access your account</h4>
                <form>
                    <label>Email</label>
                    <input ref={email} name="email" type="email" placeholder="example@gmail.com" />

                    <label>Password</label>
                    <input ref={password} name="email" type="password" placeholder="Min 8 character" />
                    <div className="row-1">
                        <div className="check-box-wrapper">
                            <div className="check-btn" style={{background: !rememberMe ? '#fff' : 'var(--primary-color)'}} onClick={() => setRememberMe(!rememberMe)}>
                                <i className="fas fa-check"></i>
                            </div>
                            <span onClick={() => setRememberMe(!rememberMe)}>Remember me</span>
                        </div>

                        <Link to="/forgot-password">Forgot password?</Link>
                    </div>

                    <button onClick={login} name="login" type="submit">{isLoading ? <Loader /> : "Continue"}</button>

                    <h6 style={{color: error ? "#900" : "#fff", fontSize: 12, fontWeight: 500, marginTop: 10, textAlign: 'center'}}>An error occurred</h6>
                </form>
            </FormWrapper>
        </Wrapper> : <Redirect to="/" />
    );
}
 
export default SignIn;
