const userReduder = (
	state = {
		userId: "",
		email: "",
		username: "",
		firstName: "",
		lastName: "",
		profileImg:
			"https://emmyh-coin.appspot.com.storage.googleapis.com/6d85b1e5-1307-4365-a4ab-4e44c319bdad.png",
		stack: "",
	},
	action
) => {
	switch (action.type) {
		case "GET_USER":
			return state;
		case "SET_USER":
			return action.payload;
		default:
			return state;
	}
};

export default userReduder;
