import styled from 'styled-components'

export const Wrapper = styled.div`
    display: block;
`;

export const Content = styled.div`
    display: block;
    padding: 0px 20px;
    h3 {
        text-align: center;
        margin: 60px 0px 30px 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        
        @media screen and (min-width: 960px) {
            font-size: 20px;
        }
    }
`;

export const CardWrapper = styled.div`
    display: block;
    padding: 0px 20px;
    @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
    }
`;

export const Card = styled.div`
	display: block;
	background: #fff;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 40px;
	box-shadow: 0px 0px 54px 4px rgba(0, 0, 0, 0.07);

	ul {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		background: #ccdff3;
		height: 286px;
		li:first-child {
			margin-bottom: 40px;
			img {
				width: 80px;
			}
		}

		li:last-child {
			margin-bottom: 25px;
			font-weight: 500;
			font-size: 16px;
		}
	}
	:nth-child(2) {
		li:first-child {
			img {
				width: 60px;
			}
		}
	}
	h4 {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 85px;
		font-size: 16px;
		font-weight: 400;
		color: var(--primary-color);
	}
`;
