import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom'
import Header from "../../components/Shared/Header/Header";
import { Content, Wrapper} from "./styles";
import AxiosCall from "../../../Utils/axios";
// import { BsChevronDown, BsCheck2} from 'react-icons/bs'
// import Loader from "../../components/Shared/Loader/Loader";
import Message from "../../components/Shared/Message/Index";
import { useParams } from 'react-router';
import Gradewrapper from "./GradeWrapper";
import { useCallback } from "react";

const GradeStudent = ({ location }) => {
	// let history = useHistory();
	const { studentId } = useParams();

	const [weeks, setWeeks] = useState([]);
	const averageScore = useRef("");
	// const [error, setError] = useState(null);
	// const [listView, setListView] = useState(true);

	// const projectRef = useRef(null);
	// const quizRef = useRef(null);
	/*
	const [selectedWeek, setSelectedWeek] = useState(null);
	const [gradingWeek, setGradingWeek] = useState(); */

	const getStudents = useCallback(async () => {
		console.log("student data", location.state.student);

		try {
			const res = await AxiosCall({
				method: "GET",
				path: `classroom/student/grade?studentId=${studentId}&courseId=${location.state.courseId}`,
			});
			averageScore.current = res.data.average;
			setWeeks([...res.data.grade.reverse()]);

			console.log("response: ", res.data.grade);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, [studentId, location]);
	useEffect(() => {
		getStudents();
	}, [getStudents]);
	return (
		<Wrapper>
			<Header leftCol={<Link to="/classroom">Back</Link>} />
			<Content>
				<div className="page-title-wrapper">
					<h3 className="page-title progress-title">
						View Progress ({" "}
						<span>
							{location.state.student.firstName}{" "}
							{location.state.student.lastName}
						</span>{" "}
						)
					</h3>
					<h3 className="page-title progress-title">
						Average Score:{" "}
						<span>
							{averageScore?.current
								? parseInt(averageScore?.current)?.toFixed(1)
								: ""}
						</span>
					</h3>
				</div>

				{weeks.map((item, weekIndex) => {
					return (
						<Gradewrapper
							item={item}
							weekIndex={weekIndex}
							location={location}
							studentId={studentId}
							getStudents={getStudents}
						/>
					);
				})}
			</Content>
		</Wrapper>
	);
};;

export default GradeStudent;
