import React from "react";
import { Link } from "react-router-dom";
// import MainLayout from '../../components/Layouts/MainLayout/MainLayout';
import Header from "../../components/Shared/Header/Header";
// import StatsCard from './StatsCard';
import {
	Content,
	Wrapper,
	ModulesWrapper,
	ModulesList,
	PageTitle,
} from "./styles";
import Switch from "../../components/Shared/Switch/Switch";
// import { BsGrid, BsListUl, BsChevronRight } from 'react-icons/bs';
import { TiPencil } from "react-icons/ti";
import { IoTrashOutline } from "react-icons/io5";

const ExpertLedModules = () => {
	// const [listView, setListView] = useState(false);

	return (
		<Wrapper>
			<Header leftCol={<PageTitle>Expert Module</PageTitle>} />
			<Content>
				<ModulesWrapper>
					<div className="page-head">
						<ul>
							<li>
								<Link to="/courses/expert-led/">Product Design {">"}</Link>
							</li>
							<li>Modules</li>
						</ul>
					</div>
					{[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
						<ModulesList>
							<ul>
								<li>Week {index + 1} :</li>
								<li>Introduction to Design Basics</li>
							</ul>
							<ul>
								<li>
									<TiPencil />
								</li>
								<li>
									<IoTrashOutline />
								</li>
								<li>
									<Switch />
								</li>
							</ul>
						</ModulesList>
					))}
				</ModulesWrapper>
			</Content>
		</Wrapper>
	);
};

export default ExpertLedModules;
