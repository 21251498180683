import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ::after {
        content: "";
        top: 2px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 4px solid ${props => props.sideColor ? props.sideColor  : '#0004'};
        border-top-color: ${props => props.topColor ? props.topColor  : '#fff'};
        animation: Loading 0.5s linear infinite;
    }

    @keyframes Loading {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const LoaderBox = styled.div`
    display: block;
`;

export const LoadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	min-height: 75vh;

	svg {
		width: 30%;
		max-width: 200px;
		height: fit-content;
		margin-bottom: 10px;
	}
`;
