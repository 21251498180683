import { useState } from "react";
import { Head, Wrapper, MenuItem, Content } from "./styles";
import {Link} from 'react-router-dom';
import { ReactComponent as DashBoardIcon } from "../../../../assets/svg/sidebar/dashboard-icon.svg";
import { ReactComponent as AllCourcesIcon } from "../../../../assets/svg/sidebar/all-cources-icon.svg";
import { ReactComponent as ClassroomIcon } from "../../../../assets/svg/sidebar/classroom-icon.svg";
import { ReactComponent as UploadCourseIcon } from "../../../../assets/svg/sidebar/upload-course-icon.svg";
import { ReactComponent as FreeModulesIcon } from "../../../../assets/svg/sidebar/free-modules-icon.svg";
import { ReactComponent as CrashCourseModulesIcon } from "../../../../assets/svg/sidebar/crash-course-icon.svg";
import { ReactComponent as ExpertLedIcon } from "../../../../assets/svg/sidebar/expert-led-icon.svg";
import { ReactComponent as ProfileIcon } from "../../../../assets/svg/sidebar/profile-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu } from "../../../../actions";

const Sidebar = () => {
	const [currentTab, setCurrentTab] = useState("home");
	const toggleState = useSelector((state) => state.sidebar);
	const dispatch = useDispatch();

	return (
		<Wrapper showSidebar={toggleState}>
			<Content>
				<Head>
					<img
						src="/svg/blue-logo.svg"
						style={{ fill: "#fff00ff" }}
						alt="icon"
					/>
				</Head>

				<ul onClick={() => dispatch(toggleMenu())}>
					<MenuItem
						onClick={() => setCurrentTab("home")}
						currentTab={currentTab === "home" ? true : false}>
						<Link to="/">
							<div>
								<DashBoardIcon />
							</div>
							<span>Dashboard</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("all-courses")}
						currentTab={currentTab === "all-courses" ? true : false}>
						<Link to="/courses/all">
							<div>
								<AllCourcesIcon />
							</div>
							<span>All Courses</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("classroom")}
						currentTab={currentTab === "classroom" ? true : false}>
						<Link to="/classroom">
							<div>
								<ClassroomIcon />
							</div>
							<span>Classroom</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("upload-course")}
						currentTab={currentTab === "upload-course" ? true : false}>
						<Link to="/course/upload">
							<div>
								<UploadCourseIcon />
							</div>
							<span>Upload Course</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("free-modules")}
						currentTab={currentTab === "free-modules" ? true : false}>
						<Link to="/courses/free">
							<div>
								<FreeModulesIcon />
							</div>
							<span>Free Modules</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("crash-course")}
						currentTab={currentTab === "crash-course" ? true : false}>
						<Link to="/courses/crash">
							<div>
								<CrashCourseModulesIcon />
							</div>
							<span>Crash Modules</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("expert-led-modules")}
						currentTab={currentTab === "expert-led-modules" ? true : false}>
						<Link to="/courses/expert">
							<div>
								<ExpertLedIcon />
							</div>
							<span>Expert Led Modules</span>
						</Link>
					</MenuItem>
					<MenuItem
						onClick={() => setCurrentTab("profile")}
						currentTab={currentTab === "profile" ? true : false}>
						<Link to="/profile">
							<div>
								<ProfileIcon />
							</div>
							<span>Profile</span>
						</Link>
					</MenuItem>
				</ul>
			</Content>
		</Wrapper>
	);
};

export default Sidebar;
