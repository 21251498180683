import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 22px 20px;
    width: 100%;
    background: #fff;
    justify-content: space-between;

    .toggle-menu {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 36px;
        height: 30px;
        margin-right: 20px;
        background: rgba(125, 164, 214, 0.2);
        /* border: 1px solid #eee; */
        border-radius: 4px;
        box-shadow: 0px 0px 20px -5px #ccc;
        color: var(--primary-color);
        cursor: pointer;
        /* span, ::after, ::before {
            content: "";
            display: block;
            width: 24px;
            height: 1px;
            background: var(--primary-color);
        }
        ::before {
            margin-bottom: 4px;
        }
        ::after {
            margin-top: 4px;
        }
        span {
            
        } */
    }
    .col-1 {
        margin-bottom: 0px;
        flex: 1;
    }
    .col-1 h1 {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-bottom: 4px;
    }
    .col-1 h6 {
        color: rgba(0, 0, 0, 0.22);
        font-size: 11px;
        font-weight: 500;
    }
    .col-2 {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        .logout-box {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50px;
            right: 0px;
            padding: 20px;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 5px 20px -5px #ccc;
            z-index: 999999;
            cursor: pointer;


            svg {
                margin-right: 10px;
                color: #222;
                font-size: 14px;
            }
            span {
                color: #222;
                font-size: 14px;
            }
        }
    }
    .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 7px;
        overflow: hidden;
        img {
            min-width: 35px;
            min-height: 35px;
            filter: grayscale(100%);
        }
    }
    
    .col-2 ul {
        display: none;
        margin: 0px 8px 0px 0px;
    }
    .col-2 li:first-child {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .col-2 li:last-child {
        font-size: 13px;
        color: #0A6DD8;
        font-weight: 400;
    }
    .col-2 i {
        color: #989898;
        font-size: 18px;
        margin-bottom: 6px;
    }

    @media screen and (min-width: 720px) {
        background: transparent;
        .col-1 h1 {
            font-size: 22px;
        }
        .col-1 h6 {
            font-size: 13px;
        }
        .col-2 ul {
            display: block;
        }
        .col-2 img {
            border-radius: 5px;
            width: 45px;
            height: 45px;
        }
        .toggle-menu {
            display: none;
        }
    }
`;