import Sidebar from "../../Shared/Sidebar/Sidebar";
import { Content, Wrapper } from "./styles";
import { useSelector, useDispatch } from 'react-redux';
import {Redirect} from 'react-router-dom';
import { useEffect } from "react";
import { loginUser, logoutUser } from "../../../../actions";

const MainLayout = ({children}) => {
    const loginState = useSelector(state => state.isLoggedIn);
    const dispatch = useDispatch()

    useEffect(() => {
			if (localStorage.getItem("authToken")) {
				dispatch(loginUser());
			} else {
				dispatch(logoutUser());
			}
		}, [dispatch]);
    

    return (
        loginState ? <Wrapper>
            <Sidebar />
            <Content>
                {children}
            </Content>
        </Wrapper> : <Redirect to="/signin" />
    );
}
 
export default MainLayout;
