import { useState, useEffect, useRef } from "react";
import Header from "../../components/Shared/Header/Header";
// import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import {
	Content,
	Wrapper,
	Development,
	CurrentLession,
	CourseCard,
	FormModal,
	FormWrapper,
	Form,
	TableTabs,
	Divider,
	PageTitle,
	CourseCardWrapper,
	Table,
	TableHead,
	TableContainer,
} from "./styles";
import AxiosCall from "../../../Utils/axios";
import Loader from "../../components/Shared/Loader/Loader";
import Message from "../../components/Shared/Message/Index";
import TableList from "./TableList";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config as configuration } from "../../../Utils/axios/upload";
import { useCallback } from "react";
import Loading from "../../components/Shared/Loader/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants } from "../Home/Home";
import { NoCourse } from "../AllCourses/Illustrations";
import { NoCourseWrapper } from "../AllCourses/styles";

const ClassRoom = () => {
	const [viewStudents, setViewStudents] = useState(null);
	const [mailFormModal, setMailFormModal] = useState(false);
	const [annousencementFormModal, setAnnouncementModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [sendingMail, setSendingMail] = useState(false);
	const [sendingAccouncement, setSendingAccouncement] = useState(false);
	const [courses, setCourses] = useState([]);
	// const [error, setError] = useState(null);
	// const [listView, setListView] = useState(true);
	const tokenState = useSelector((state) => state.token);
	// const loginState = useSelector((state) => state.isLoggedIn);
	// const dispatch = useDispatch();
	const [activeStudent, setActiveStudents] = useState("current");

	const selectedCourse = useRef(null);

	const emailReq = useRef({
		courseId: "",
		recipients: "students",
		subject: "",
		message: "",
	});

	const announceReq = useRef({
		courseId: "",
		subject: "",
		message: "",
	});

	const config = configuration();
	const mailModalRef = useRef(null);
	const announcementModalRef = useRef(null);

	const closeModal = (e, modalRef) => {
		if (e.target === modalRef.current) {
			setAnnouncementModal(false);
			setMailFormModal(false);
		}
	};

	const getCourses = useCallback(async () => {
		// setIsLoading( true );

		console.log("current token", tokenState);

		// setError( null );

		try {
			const res = await AxiosCall({
				method: "GET",
				path: "classroom/course",
			});

			if (res.status) {
				setIsLoading(false);
				setCourses(res.data);
			} else {
				setIsLoading(false);
				// setError( "Invalid credentials" );
			}
		} catch (err) {
			setIsLoading(false);
			// setError( "Invalid credentials" );
			console.log(err);
		}
	}, [tokenState]);

	useEffect(() => {
		getCourses();
	}, [getCourses]);

	const sendEmail = () => {
		setSendingMail(true);

		emailReq.current = {
			...emailReq.current,
			courseId: selectedCourse.current,
		};
		console.log(emailReq.current, "inputs");

		if (
			emailReq.current.subject.length >= 3 &&
			emailReq.current.message.length >= 3
		) {
			setTimeout(async () => {
				try {
					const payLoad = emailReq.current;
					console.log("payload: ", payLoad);
					const res = await AxiosCall({
						method: "POST",
						path: "classroom/message/mail",
						data: payLoad,
					});

					console.log("response: ", res);

					if (res.status) {
						Message.success("Email sent successfully");
						emailReq.current = {
							courseId: "",
							recipient: "students",
							subject: "",
							message: "",
						};
						setMailFormModal(false);
					}
					setSendingMail(false);
				} catch (err) {
					setSendingMail(false);
					Message.error(err?.response?.data?.error);
				}
			}, 1000);
		} else {
			setSendingMail(false);
			Message.error("SHORT_INPUT");
		}
	};

	const parseDate = (timeStamp) => {
		let date = new Date(timeStamp);
		return date.toDateString();
	};
	const sendAnnouncement = () => {
		setSendingAccouncement(true);
		announceReq.current = {
			...announceReq.current,
			courseId: selectedCourse.current,
		};

		if (
			announceReq.current.subject.length >= 3 &&
			announceReq.current.message.length >= 3
		) {
			setTimeout(async () => {
				try {
					const payLoad = announceReq.current;
					console.log("payload: ", payLoad);
					const res = await AxiosCall({
						method: "POST",
						path: "classroom/message/announcement",
						data: payLoad,
					});

					console.log("response: ", res);

					if (res.status) {
						Message.success("Announcement sent successfully");
						announceReq.current = {
							courseId: "",
							recipient: "students",
							subject: "",
							message: "",
						};
						setAnnouncementModal(false);
					}
					setSendingAccouncement(false);
				} catch (err) {
					setSendingAccouncement(false);
					Message.error(err?.response?.data?.error);
				}
			}, 1000);
		} else {
			setSendingAccouncement(false);
			Message.error("SHORT_INPUT");
		}
	};

	const changeTab = (e) => {
		const tabs = [...document.getElementsByClassName("course__tab")];
		tabs.forEach((tab) => {
			tab.classList.remove("active");
		});

		setActiveStudents(e.target.id);
		e.target.classList.add("active");
	};
	return (
		<Wrapper>
			<Header leftCol={<PageTitle>Classroom</PageTitle>} />
			<AnimatePresence>
				{isLoading ? (
					<Loading />
				) : (
					<motion.div
						variants={containerVariants}
						initial="start"
						animate="end"
						exit={"exit"}
						className="fullheight">
						<Content>
							<h3 className="page-title">My Courses</h3>

							{courses.length < 1 ? (
								<NoCourseWrapper>
									<NoCourse />
									<h4>No course to display</h4>
								</NoCourseWrapper>
							) : (
								courses.map((courseItem, index) => {
									return (
										<CourseCardWrapper key={index}>
											<CourseCard>
												<Development>
													<h2>Development</h2>
													<div className="content-wrapper">
														<div className="info">
															<h6>{courseItem.title}</h6>

															<ul>
																<li
																	onClick={(e) => {
																		e.preventDefault();
																		if (viewStudents === index) {
																			setViewStudents(null);
																		} else {
																			setViewStudents(index);
																		}
																	}}>
																	<button>View Students</button>
																</li>
																{viewStudents !== index && (
																	<li
																		onClick={(e) => {
																			e.preventDefault();
																			selectedCourse.current = courseItem._id;
																			setMailFormModal(true);
																		}}>
																		<button>Mail Students</button>
																	</li>
																)}
																{viewStudents !== index && (
																	<li
																		onClick={(e) => {
																			e.preventDefault();
																			selectedCourse.current = courseItem._id;
																			setAnnouncementModal(true);
																		}}>
																		<button>Make An Announcement</button>
																	</li>
																)}
															</ul>
														</div>
														{/* <div className="stats">
														<span>25%</span>
													</div> */}
													</div>
												</Development>
												<Divider />
												<CurrentLession>
													<h6>Current lesson</h6>
													<h4>
														{
															courseItem.timeline[
																courseItem.timeline.length - 1
															].project.title
														}{" "}
													</h4>
													<button>Resume</button>
													<ul>
														<li>
															Start Date:{" "}
															<span>{parseDate(courseItem.createdAt)}</span>
														</li>
														{/* <li>End Date : 29th of Nov</li> */}
													</ul>
												</CurrentLession>
											</CourseCard>

											{viewStudents === index && (
												<Table>
													<TableTabs>
														<li
															id="current"
															className="course__tab active"
															onClick={changeTab}>
															Current
														</li>
														<li
															id="graduates"
															className="course__tab "
															onClick={changeTab}>
															Graduates
														</li>
														<li
															id="spillover"
															className="course__tab "
															onClick={changeTab}>
															Spillover
														</li>
													</TableTabs>
													<TableContainer>
														<TableHead>
															<li>Name</li>
															<li>Email</li>
															<li>Mobile Number</li>

															<li>Score</li>

															<li></li>
															<li></li>
															<li></li>
														</TableHead>
														{activeStudent === "current" ? (
															<TableList
																studentList={courseItem?.students}
																courseItem={courseItem}
																activeStudent={activeStudent}
																getCourses={getCourses}
															/>
														) : activeStudent === "spillover" ? (
															<TableList
																studentList={courseItem?.spillovers}
																courseItem={courseItem}
																activeStudent={activeStudent}
																getCourses={getCourses}
															/>
														) : (
															<TableList
																studentList={courseItem?.graduates}
																courseItem={courseItem}
																activeStudent={activeStudent}
																getCourses={getCourses}
															/>
														)}
													</TableContainer>
												</Table>
											)}
										</CourseCardWrapper>
									);
								})
							)}
							{mailFormModal && (
								<FormModal
									ref={mailModalRef}
									onClick={(e) => closeModal(e, mailModalRef)}>
									<FormWrapper>
										<Form>
											{/* <input placeholder="Name" onChange={e => {
                                    emailReq.current = {...emailReq.current, subject: e.target.value};
                            }}/> */}
											<input
												placeholder="Subject"
												onChange={(e) => {
													emailReq.current = {
														...emailReq.current,
														subject: e.target.value,
													};
												}}
											/>

											{/* <label htmlFor=""></label> */}

											<div className="input-row">
												<div className="input-col">
													<div className="sec">
														<div className="input-wrapper">
															<select
																name="recipients"
																onChange={(e) => {
																	emailReq.current = {
																		...emailReq.current,
																		recipients: e.target.value,
																	};
																}}>
																<option value="design" selected>
																	-- select recipients --
																</option>
																<option value={"students"}>Students</option>

																<option value={"graduates"}>Graduates</option>

																<option value={"spillovers"}>Spillovers</option>
															</select>
														</div>
													</div>
												</div>
											</div>

											<div className="modal-form">
												<CKEditor
													editor={ClassicEditor}
													// data="Message"
													placeholder="Message"
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														console.log("Editor is ready to use!", editor);
													}}
													config={{
														ckfinder: {
															uploadUrl: `${config.baseUrl}/v1/file/upload/${config.token}?ckeditor=true`,
														},
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														// console.log( { event, editor, data } );
														emailReq.current = {
															...emailReq.current,
															message: data,
														};
													}}
													onBlur={(event, editor) => {
														// console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														// console.log( 'Focus.', editor );
													}}
												/>
											</div>
											<button
												onClick={(e) => {
													e.preventDefault();
													sendEmail();
												}}>
												{sendingMail ? <Loader /> : "Send Message"}
											</button>
										</Form>
									</FormWrapper>
								</FormModal>
							)}
							{annousencementFormModal && (
								<FormModal
									ref={announcementModalRef}
									onClick={(e) => closeModal(e, announcementModalRef)}>
									<FormWrapper>
										<Form>
											<input
												placeholder="Subject"
												onChange={(e) => {
													announceReq.current = {
														...announceReq.current,
														subject: e.target.value,
													};
												}}
											/>
											{/* <input placeholder="select category of student" onChange={e => {
                                announceReq.current = {...announceReq.current, message: e.target.value};
                            }} /> */}
											<div className="modal-form">
												<CKEditor
													editor={ClassicEditor}
													// data="Message"
													placeholder="Message"
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														console.log("Editor is ready to use!", editor);
													}}
													config={{
														ckfinder: {
															uploadUrl: `${config.baseUrl}/v1/file/upload/${config.token}?ckeditor=true`,
														},
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														// console.log( { event, editor, data } );
														announceReq.current = {
															...announceReq.current,
															message: data,
														};
													}}
													onBlur={(event, editor) => {
														// console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														// console.log( 'Focus.', editor );
													}}
												/>
											</div>

											<button
												onClick={(e) => {
													e.preventDefault();
													sendAnnouncement();
												}}>
												{sendingAccouncement ? <Loader /> : "Send Message"}
											</button>
										</Form>
									</FormWrapper>
								</FormModal>
							)}
						</Content>
					</motion.div>
				)}
			</AnimatePresence>
		</Wrapper>
	);
};

export default ClassRoom;
