import styled from 'styled-components';

export const Wrapper = styled.div`
    display: block;
    .top-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin: 43px 0px 22px 0px;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }

    .form-footer-wrapper .bottom-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin: 25px 0px 43px 0px;
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }
`;

export const HeadBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #fff;
    padding: 0px 20px;

    h3 {
        font-size: 14px;
        font-weight: 400;
        color: #1590D8;
        text-transform: uppercase;
    }
`;

export const FormWrapper = styled.div`
    display: block;
    padding: 0px 20px 20px 20px;
`;

export const Form = styled.form`
    display: block;
    background: #fff;

    label {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
    }
    input {
        display: block;
        border: 1px solid #7CBAFD;
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        margin-bottom: 25px;
        border-radius: 10px;
        background: #fff;

        ::placeholder {
            color: #989898;
            font-weight: 400;
        }
    }
    .input-col {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        .input-wrapper {
            width: 100%;
            max-width: 480px;
            margin-bottom: 0px;
            :last-of-type {
                margin: 0px 0px;
            }
        }
        label {
            width: 100%;
        }

        .sec {
            margin-bottom: 25px;
        }
        .input-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #7CBAFD;
            height: 50px;
            border-radius: 10px;
            width: 100%;
        }
        input {
            padding: 0px 20px;
            margin-bottom: 0px;
            width: 100%;
        }
        select {
            display: block;
            border: none;
            width: 100%;
            height: 48px;
            padding: 0px 20px;
            border-radius: 10px;
            background: #fff;
        }
        input:first-child {
            border: none;
            height: unset;
        }

        svg {
            fill: var(--primary-color);
        }

        .input-wrapper {
            margin-bottom: 25px;
        }

        @media screen and (min-width: 720px) {
            flex-direction: row;
            align-items: flex-end;

            .input-wrapper {
                width: 100%;
                max-width: 480px;
                margin-bottom: 0px;
                :nth-child(2) {
                    margin: 0px 20px;
                }
            }

            input {
                margin-bottom: 0px;
                width: 100%;
                max-width: 480px;
            }
            .sec {
                margin: 0px 20px 0px 0px;
            }
        }
    }

    .input-description, .input-Knowledge {
        display: block;
        border: 1px solid #7CBAFD;
        width: 100%;
        height: 226px;
        padding: 20px;
        margin-bottom: 25px;
        border-radius: 10px;
        ::placeholder {
            color: #989898;
            font-weight: 400;
        }
        resize: none;
    }
    .input-Knowledge {
        height: 120px;
    }

    .form-sec {
        display: block;
        border: 1px solid #7CBAFD;
        width: 100%;
        padding: 20px;
        border-radius: 10px;

        label {
            margin-bottom: 18px;
        }


        .course-description {
            height: 167px;
        }
        .project-description {
            height: 117px;
        }
    }

    .timeline-sec {
        margin-bottom: 20px;
    }
    .timeline-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        label {
            margin-bottom: 0px;
        }

        span {
            font-size: 12px;
            font-weight: 300;
            color: #f66;
            padding: 10px 20px;
            margin-left: 20px;
            border-radius: 2px;
            text-transform: uppercase;
            border: 0.5px solid #f66;
            cursor: pointer;
        }
    }

    .form-footer-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .form-footer-wrapper span:first-of-type {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 200px;
        border: 1px solid #0A6DD8;
        font-size: 11px;
        color: #0A6DD8;
        border-radius: 5px;
        font-weight: 400;
        cursor: pointer;
    }
    .form-footer-wrapper span:last-of-type {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 80px;
        background-color: rgba(10, 109, 216, 0.75);
        color: #fff;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 400;
        cursor: pointer;
    }

    @media screen and (min-width: 960px) {
        .form-footer-wrapper span:first-of-type {
            height: 45px;
            width: 331px;
            font-size: 14px;
        }
        .form-footer-wrapper span:last-of-type {
            height: 45px;
            width: 113px;
            font-size: 14px;
        }
    }
`;
export const UploadWrapper = styled.div`
    padding: 0px 20px 20px 20px;
    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 102px;
        height: 45px;
        margin: 0px auto;
        border-radius: 5px;
        color: #fff;
        background-color: rgba(10, 109, 216, 0.75);
        font-size: 13px;
        font-weight: 400;
        margin-top: 20px;
    }
`;

export const UploadContent = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;

    h4 {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
        font-weight: 400;
    }

    @media screen and (min-width: 960px) {
        flex-direction: row;
        justify-content: space-between;
    }
`
export const UploadItemWrapper = styled.div`
	width: 100%;
	max-width: 480px;
	margin-bottom: 25px;
	position: relative;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* :last-child {
		margin-bottom: 0px;
	} */
	b {
		display: block;
		text-align: center;
		color: #000;
		font-weight: 500;
		font-size: 14px;
		margin-top: 20px;
		line-height: 22px;
	}
	@media screen and (min-width: 960px) {
		width: 30%;
	}
`;

export const UploadItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #fff;
	border: 1px dashed #5c5c5c;
	padding: 20px;
	border-radius: 10px;
	height: 300px;
	width: 100%;
	position: relative;

	.upload-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 90px;
		height: 90px;
		background-color: #ccdff3;
		border-radius: 50%;
		margin-bottom: 20px;

		svg {
			fill: var(--primary-color);
			font-size: 40px;
		}
	}

	span {
		color: #989898;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		position: relative;
		display: block;
		padding: 0 20px;
		width: 100%;
		word-wrap: break-word;
		text-align: center;
	}

	form label {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 125px;
		height: 35px;
		border-radius: 5px;
		color: #0a6dd8;
		font-size: 13px;
		font-weight: 400;
		margin-top: 20px;
		cursor: pointer;
	}
`;


export const ListTiles = styled.ul`
    padding: 0px 20px;
`;

export const Tiles = styled.li`
    background: #FFFFFF;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin-bottom: 20px;

    .tile-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        height: 100px;
        cursor: pointer;

        span {
            color: #000000;
            font-size: 14px;
            font-weight: 500;
        }

        svg {
            fill: #979797;
            font-size: 17px;
            transform: ${props => props.showForm ? "rotate(-180deg)" : "rotate(0deg)"};
            transition: all 0.2s ease-in-out;
        }
    }

    .instructor-tile-body {
        padding: 0px 20px 20px 20px;
        .input-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #7CBAFD;
            height: 50px;
            border-radius: 10px;
            width: 100%;
        }
        input {
            display: block;
            border: 1px solid #7CBAFD;
            width: 100%;
            height: 50px;
            padding: 0px 20px;
            margin-bottom: 25px;
            border-radius: 10px;
            background: #fff;

            ::placeholder {
                color: #989898;
                font-weight: 400;
            }
        }
        select {
            display: block;
            border: none;
            width: 100%;
            height: 48px;
            padding: 0px 20px;
            border-radius: 10px;
            background: #fff;
        }
        .input-col {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;

            .input-wrapper {
                width: 100%;
                max-width: 480px;
                margin-bottom: 0px;
                :last-of-type {
                    margin: 0px 0px;
                }
            }
        }
    }
`;

export const InstructorsFormWrapper = styled.div`
    display: block;
    padding: 0px 20px 20px 20px;
    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 102px;
        height: 45px;
        margin: 0px auto;
        border-radius: 5px;
        color: #fff;
        background-color: rgba(10, 109, 216, 0.75);
        font-size: 13px;
        font-weight: 400;
    }
`;


export const InstructorsFormContent = styled.div`
    display: flex;
    flex-direction: column;

    .upload-sec {
        width: 100%;
        max-width: 480px;
    }
    .form-sec {
        flex: 1;
        margin-left: 20px;
    }
    b {
        display: block;
        text-align: center;
        color: #000;
        font-weight: 500;
        font-size: 14px;
        margin-top: 20px;
        line-height: 22px;
    }


    @media screen and (min-width: 960px) {
        flex-direction: row;
        b {
            margin-top: 40px;
        }
    }
`;

export const CourseThumbnailWrapper = styled.div`
    display: block;
    padding: 0px 20px 20px 20px;
    
    .upload-sec {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 102px;
        height: 45px;
        margin: 0px auto;
        border-radius: 5px;
        color: #fff;
        background-color: rgba(10, 109, 216, 0.75);
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
    }
`;

export const CourseUploadWrapper = styled.div`
    width: 100%;
    max-width: 480px;
`;
