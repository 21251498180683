import { useState, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
// import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import Header from "../../components/Shared/Header/Header";
// import StatsCard from "./StatsCard";
import {
	Content,
	Wrapper,
	TableWrapper,
	PageTitle,
	TableHead,
	TableBody,
	TableItem,
	TableGridItem,
} from "./styles";
import Switch from "../../components/Shared/Switch/Switch";
// import { BsGrid, BsListUl, BsChevronRight } from "react-icons/bs";
import { BiUser, BiTimeFive } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import AxiosCall from "../../../Utils/axios";
import { enableCourse, disableCourse } from "../AllCourses/AllCourses";
import { useCallback } from "react";
import Loading from "../../components/Shared/Loader/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants } from "../Home/Home";
import { NoCourse } from '../AllCourses/Illustrations';
import { NoCourseWrapper } from '../AllCourses/styles';


const ExpertLedModules = () => {
	const [listView, setListView] = useState(false);

	const [isLoading, setIsLoading] = useState(true);
	const [courses, setCourses] = useState([]);
	// const [error, setError] = useState(null);
	const history = useHistory();
	const getCourses = useCallback(async () => {
		// setIsLoading(true);

		try {
			const res = await AxiosCall({
				method: "GET",
				path: "course/expert/all",
			});

			if (res.status) {
				setIsLoading(false);
				setCourses(res.data);
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			setIsLoading(false);

			console.log(err);
		}
	}, []);
	useEffect(() => {
		getCourses();
	}, [getCourses]);

	const editCourse = (item) => {
		history.push(`/upload/course/expert/${item["_id"]}`);
	};
	const toggleActive = (course) => {
		if (course?.isActive) {
			disableCourse(course).then(() => {
				getCourses();
			});
		} else {
			enableCourse(course).then(() => {
				getCourses();
			});
		}
	};

	return (
		<Wrapper>
			<Header leftCol={<PageTitle>Expert Module</PageTitle>} />
			<AnimatePresence>
				{isLoading ? (
					<Loading />
				) : (
					<motion.div
						variants={containerVariants}
						initial="start"
						animate="end"
						exit={"exit"}
						className="fullheight">
						<Content>
							<div className="table-col">
								<h4>All Courses</h4>
								<ul className="options">
									{/* <li className={!listView ? "grid-view-active grid-view" : "grid-view"} onClick={() => setListView(false)}>
                            <BsGrid />
                        </li>
                        <li className={listView ? "list-view-active list-view" : "list-view"} onClick={() => setListView(true)}>
                            <BsListUl />
                        </li> */}
								</ul>
							</div>
							{courses.length < 1 ? (
								<NoCourseWrapper>
									<NoCourse />
									<h4>No course to display</h4>
								</NoCourseWrapper>
							) : (
								<TableWrapper>
									<TableHead gridview={!listView}>
										<li>Course Title</li>
										<li>Duration</li>
										<li>Students Enrolled</li>
										<li>Course Status</li>
									</TableHead>
									<TableBody gridview={!listView}>
										{courses.map((item, index) => {
											return listView ? (
												<TableItem key={index}>
													<li className="title-wrapper">
														<img src={item.featuredImage} alt={item.title} />
														<span>{item.title}</span>
													</li>
													<li>{item.duration + "weeks"}</li>
													<li>{item.students.length}</li>
													<li>
														<Switch active={item?.isActive} />
													</li>
													<li>
														<Link to="/courses/expert-led/modules">View</Link>
													</li>
												</TableItem>
											) : (
												<TableGridItem key={index}>
													<ul>
														<li className="title-wrapper">
															<img src={item.featuredImage} alt={item.title} />
															<span>{item.title}</span>
														</li>
														<li>
															<Switch
																active={item?.isActive}
																onClick={() => toggleActive(item)}
															/>
														</li>
													</ul>
													<ul>
														<li>
															<span>
																<BiUser />
															</span>
															<span>{item.students.length} Students</span>
														</li>
														<li>
															<span>
																<BiTimeFive />
															</span>
															<span>{parseInt(item.duration)} weeks</span>
														</li>
														<li onClick={() => editCourse(item)}>
															<IoIosArrowForward />
														</li>
													</ul>

													<Link to={"/upload/course/expert/" + item._id} />
												</TableGridItem>
											);
										})}
									</TableBody>
								</TableWrapper>
							)}
						</Content>
					</motion.div>
				)}
			</AnimatePresence>
		</Wrapper>
	);
};

export default ExpertLedModules;
