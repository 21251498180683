import styled from 'styled-components';

export const Wrapper = styled.div`
    display: block;
    .top-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin: 43px 0px 22px 0px;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }

    .form-footer-wrapper .bottom-action {
        display: flex;
        justify-content: flex-end;
        padding: 0px 20px;
        margin: 25px 0px 43px 0px;
        button {
            width: 135px;
            height: 45px;
            background: var(--primary-color);
            color: #fff;
            border: none;
            border-radius: 5px;
        }
    }
`;

export const HeadBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #fff;
    padding: 0px 20px;

    h3 {
        font-size: 14px;
        font-weight: 400;
        color: #1590D8;
        text-transform: uppercase;
    }
`;

export const ContentWrapper = styled.div`
    padding: 0px 20px;
`;

export const Content = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin: 40px 0px 20px 0px;
    min-height: 70vh;

    .tile-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        height: 80px;
        border-bottom: 1px solid #7CBAFD;
        margin-bottom: 20px;

        span {
            color: #000000;
            font-size: 18px;
            font-weight: 400;
        }

        svg {
            fill: #979797;
            font-size: 17px;
            transform: ${props => props.showForm ? "rotate(-180deg)" : "rotate(0deg)"};
            transition: all 0.2s ease-in-out;
        }
    }

    .upload-info {
        display: block;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        line-height: 25px;
        color: #5C5C5C;
        padding: 0px 20px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 720px;
        margin: 0px auto;

        @media screen and (min-width: 960px) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .add-module-btn {
        display: flex;
        margin: 0px auto;
        padding: 13px 30px;
        background-color: var(--primary-color);
        border: none;
        color: #fff;
        border-radius: 10px;
        margin-top: 30px;
        cursor: pointer;

        span {
            margin-left: 20px;
        }
        svg {
            font-size: 15px;
        }
    }
`;

export const ModuleListWrapper = styled.div`
    display: block;
    padding: 20px;
`;
export const ModuleCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    :last-child {
        margin-bottom: 0px;
    }
    border: 1px solid #7CBAFD;

    .col-1 {
        display: flex;

        .sec-1 {
            display: flex;
            justify-content: flex-start;

            svg {  
                cursor: pointer;
                margin-right: 20px;
            }
        }
        h4 {
            margin-right: 10px;
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
        }
        span {
            margin-right: 30px;
            color: #5C5C5C;
            font-size: 16px;
        }
        .toggle-wrapper {
            display: flex;
            flex: 1;
            margin-left: 5px;
            justify-content: flex-end;
            cursor: pointer;

            svg {
                cursor: pointer;
            }
        }
    }

    .add-lesson-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 40px;
        background-color: var(--primary-color);
        border: none;
        color: #fff;
        border-radius: 10px;
        margin-top: 30px;

        span {
            margin-left: 10px;
        }
        svg {
            font-size: 15px;
            cursor: pointer;
        }
    }

    .lession-action-column {
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;
        margin-top: 20px;
        border: 1px dashed var(--primary-color);

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            max-width: 120px;
            padding: 0px 8px;
            background-color: transparent;
            border: none;
            flex: 1;
            color: var(--primary-color);
            span {
                margin-left: 8px;
                font-weight: 300;
            }
            svg {
                font-size: 17px;
            }
        }

        div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            padding: 0px 20px 0px 20px;
            font-size: 20px;
            color: var(--primary-color);
            cursor: pointer;
        }
    }
`;

export const LessonCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    :last-child {
        margin-bottom: 0px;
    }
    border: 1px solid #7CBAFD;

    .col-1 {
        display: flex;
        justify-content: space-between;

        .sec-1 {
            display: flex;
            justify-content: flex-start;
        }
        h4 {
            margin-right: 10px;
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
        }
        span {
            margin-right: 30px;
            color: #5C5C5C;
            font-size: 16px;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 60px;
        height: 30px;
        background-color: var(--primary-color);
        border: none;
        color: #fff;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
    }
`;
export const ModuleFormWrapper = styled.div`
    display: block;
    padding: 20px;
`;
export const ModuleForm = styled.div`
    display: block;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #7CBAFD;

    .col-1 {
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            font-weight: 400;
        }
        input {
            flex: 1;
            border: 1px solid #7CBAFD;
            margin-left: 10px;
            height: 40px;
            padding: 0px 20px;
            border-radius: 4px;
        }
        margin-bottom: 20px;
    }

    .col-2 {
        display: flex;
        justify-content: flex-end;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 40px;
            background-color: var(--primary-color);
            border: none;
            color: #fff;
            border-radius: 8px;
            cursor: pointer;

            :first-child {
                margin-right: 20px;
                background-color: #fff;
                border: 1px solid var(--primary-color);
                color: var(--primary-color);
            }
        }
    }
`;


export const FormModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #0006;
    padding: 20px;
    z-index: 99999999999999999;
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 950px;
    min-height: ${props => props.minHeight ? props.minHeight : "70vh"};
    background-color: #EBF3FC;
    border-radius: 10px;
    padding: 20px;
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    width: 100%;

    input, textarea {
        display: block;
        width: 100%;
        padding: 0px 20px;
        border-radius: 4px;
        background-color: #EBF3FC;
        margin-bottom: 25px;
        height: 50px;
        border: 1px solid #0A6DD8;

        ::placeholder {
            color: #989898;
        }
    }

    textarea {
        padding: 20px;
        height: 247px;
        resize: none;
    }

    label {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
        text-align: left;
        width: 100%;
        cursor: pointer;
    }

    button {
        width: 100%;
        border-radius: 5px;
        border: none;
        max-width: 120px;
        height: 50px;
        color: #fff;
        background-color: var(--primary-color);
    }
`;


export const UploadWrapper = styled.div`
    padding: 0px 20px 20px 20px;
    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        width: 102px;
        height: 45px;
        margin: 0px auto;
        border-radius: 5px;
        color: #fff;
        background-color: rgba(10, 109, 216, 0.75);
        font-size: 13px;
        font-weight: 400;
        margin-top: 20px;
    }
`;

export const UploadContent = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;

    h4 {
        display: block;
        font-size: 14px;
        color: #000;
        margin-bottom: 6px;
        font-weight: 400;
    }

    @media screen and (min-width: 960px) {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const UploadItemWrapper = styled.div`
    width: 100%;
    max-width: 480px;
    margin-bottom: 25px;
    :last-child {
        margin-bottom: 0px;
    }
    b {
        display: block;
        text-align: center;
        color: #000;
        font-weight: 500;
        font-size: 14px;
        margin-top: 20px;
        line-height: 22px;
    }
    @media screen and (min-width: 960px) {
        /* width: 30%; */
    }
`;

export const UploadItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: #fff; */
    border: 1px dashed var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    height: 300px;

    .upload-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        background-color: #CCDFF3;
        border-radius: 50%;
        margin-bottom: 20px;

        svg {
            fill: var(--primary-color);
            font-size: 40px;
        }
    }

    span {
        color: #989898;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 35px;
        border-radius: 5px;
        color: #0A6DD8;
        font-size: 13px;
        font-weight: 400;
        margin-top: 20px;
        cursor: pointer;
    }

    .image-preview {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
        max-width: 480px;
        height: 300px;
        margin-bottom: 20px;

        label {
            position: absolute;
            top: 0px;
            left: 0px; 
            width: 100%;
            max-width: 480px;
            height: 300px;
            cursor: pointer;
        }

        img {
            min-width: 100%;
            min-height: 100%;
        }

        .edit-box {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 20px;
            right: 20px;
            width: 30px;
            height: 30px;
            background-color: var(--primary-color);
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
        }
    }
`;