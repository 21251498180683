import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 0px 0px;
	position: relative;
	height: auto;
	min-height: 100%;
`;

export const PageTitle = styled.h3`
	color: #000000;
	font-size: 20px;
	font-weight: 500;
`;

export const Content = styled.div`
	margin-top: 40px;
	padding: 0px 20px;

	.page-title {
		font-size: 18px;
		font-weight: 400;
		color: #5c5c5c;
		margin-bottom: 15px;
	}

	.progress-title {
		font-size: 16px;

		span {
			color: var(--primary-color);
		}
	}

	.page-title-wrapper {
		display: flex;
		justify-content: space-between;

		.progress-title {
			font-size: 13px;
		}

		@media screen and (min-width: 960px) {
			flex-direction: row;
			.progress-title {
				font-size: 16px;
			}
		}
	}
`;
export const CourseCardWrapper = styled.div`
	display: block;
	position: relative;
	background: #fff;
	border-radius: 10px;
	margin-bottom: 25px;
	box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.07);
`;
export const CourseCard = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;

	@media screen and (min-width: 960px) {
		flex-direction: row;
	}
`;
export const Development = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	h2 {
		font-size: 14px;
		font-weight: 400;
		color: #979797;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		li button {
			outline: hidden;
			border: none;
			font-size: 12px;
			white-space: nowrap;
			color: #1590d8;
			background: transparent;
		}
		li:nth-child(2) {
			border: 1px solid #ccc;
			border-top: none;
			border-bottom: none;
			margin: 0px 6px;
			padding: 0px 6px;
		}

		@media screen and (min-width: 960px) {
			li a {
				font-size: 14px;
			}
		}
	}
	.content-wrapper {
		display: flex;
		flex: 1;
		justify-content: space-between;
		.info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
			h6 {
				margin: 15px 0px 10px 0px;
				font-size: 14px;
				font-weight: 500;
			}
		}
		.stats {
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			width: 70px;
			height: 70px;
			border-radius: 50%;
			background: #fff;
			border: 4px solid #ccc;
			border-top-color: var(--primary-color);

			span {
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
	@media screen and (min-width: 960px) {
		padding: 10px 0px;
		.content-wrapper {
			.stats {
				width: 80px;
				height: 80px;
			}
		}
	}
`;
export const CurrentLession = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 20px;
	h6 {
		font-size: 14px;
		font-weight: 400;
		color: #979797;
		text-transform: uppercase;
		order: 1;
	}

	h4 {
		order: 2;
		font-size: 14px;
		font-weight: 500;
		color: #000;
		margin: 15px 0px 6px 0px;
	}
	button {
		order: 4;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(10, 109, 216, 0.75);
		width: 100px;
		height: 37px;
		border-radius: 18.5px;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		border: none;
		outline: hidden;
		margin-bottom: 10px;
	}

	ul {
		order: 3;
		display: flex;
		align-items: center;
		margin-bottom: 19px;
		li {
			:first-child {
				margin-right: 20px;
			}
			font-size: 12px;
			color: #989898;
			font-weight: 400;
		}

		@media screen and (min-width: 960px) {
			justify-content: flex-end;
		}
	}

	@media screen and (min-width: 960px) {
		margin-top: 0px;
		h4 {
			margin: 15px 0px 20px 0px;
		}
		a {
			order: 3;
			margin-bottom: 6px;
		}
		ul {
			order: 4;
			margin-bottom: 0px;
		}
	}
`;

export const Divider = styled.div`
	display: none;
	width: 1px;
	height: 92px;
	background: #c4c4c4;
	margin: 0px 60px 0px 40px;
	align-self: center;

	@media screen and (min-width: 960px) {
		display: block;
	}
`;

export const Table = styled.ul`
	display: block;
	width: 100%;
	position: relative;

	padding: 0px 20px 20px 20px;
`;

export const TableTabs = styled.ul`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	li {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8%;
		border-radius: 5px;
		font-size: 14px;
		color: #000;
		width: 100px;
		height: 30px;

		cursor: pointer;
		transition: 0.15s ease-in-out;
	}

	li:hover {
		background: lightgray;
	}
	li.active:hover {
		background-color: rgba(8, 96, 208, 0.24);
	}
	.active {
		background-color: rgba(8, 96, 208, 0.24);
		color: #0a6dd8;
	}
`;

export const TableHead = styled.ul`
	display: flex;
	padding: 15px 0px;
	position: relative;
	min-width: 100%;
	width: fit-content;

	::after {
		content: "";
		display: block;
		width: 20%;

		@media screen and (min-width: 720px) {
			width: 25%;
		}
	}

	li {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		width: fit-content;

		color: rgba(0, 0, 0, 0.2);
		text-transform: uppercase;
		:first-child {
			min-width: 200px;
		}

		:nth-child(2) {
			min-width: 250px;
		}
		:nth-child(3) {
			min-width: 150px;
		}
		:nth-child(4) {
			min-width: 50px;
		}

		@media screen and (min-width: 720px) {
			:first-child {
				flex: unset;
				width: 20%;
			}
			:nth-child(2) {
				display: block;
				width: 30%;
			}
			:nth-child(3) {
				display: block;
				width: 25%;
			}
			:last-child {
				width: 25%;
			}
			:nth-child(4) {
				width: 25%;
			}
		}
	}
`;

export const TableBody = styled.div`
	display: block;
	width: fit-content;
	min-width: 100%;
	position: relative;
`;

export const TableContainer = styled.div`
	display: block;
	width: 100%;
	overflow-x: auto;

	position: relative;
`;

export const TableItem = styled.ul`
	display: flex;
	border-top: 1px solid #c4c4c4;

	li {
		display: block;
		justify-content: flex-start;
		align-items: center;
		white-space: nowrap;
		padding: 20px 0px;
		color: #707070;
		font-size: 14px;
		position: relative;
		width: fit-content;
		padding-right: 10px;

		:first-child {
			min-width: 200px;
		}

		:nth-child(2) {
			min-width: 250px;
			display: none;
		}
		:nth-child(3) {
			min-width: 150px;
			display: none;
		}
		:nth-child(4) {
			min-width: 50px;
			width: 40%;
		}

		a {
			white-space: nowrap;
		}
		:last-child {
			width: 20%;
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 76px;
				border-radius: 12.5px;
				height: 25px;
				color: #fff;
				font-size: 13px;
				background-color: rgba(10, 109, 216, 0.75);
			}
		}

		@media screen and (min-width: 720px) {
			:first-child {
				width: 20%;
			}
			:nth-child(2) {
				display: block;
				width: 30%;
			}
			:nth-child(3) {
				display: block;
				width: 25%;
			}
			:nth-child(4) {
				width: 25%;
			}
			:last-child {
				width: 25%;
			}
		}
	}
`;

export const FormModal = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	background-color: #0006;
	padding: 20px;
	z-index: 99999999999999999;
`;

export const FormWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 950px;
	min-height: 70vh;
	background-color: #ebf3fc;
	border-radius: 10px;
	padding: 20px;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 750px;
	width: 100%;

	input,
	textarea {
		display: block;
		width: 100%;
		padding: 0px 20px;
		border-radius: 10px;
		background-color: #ebf3fc;
		margin-bottom: 25px;
		height: 50px;
		border: 1px solid #1590d8;

		::placeholder {
			color: #989898;
		}
	}

	textarea {
		padding: 20px;
		height: 247px;
		resize: none;
	}

	button {
		width: 100%;
		border-radius: 5px;
		border: none;
		max-width: 333px;
		height: 50px;
		color: #fff;
		background-color: var(--primary-color);
	}
`;

export const WeekCard = styled.div`
	background: #fff;
	border-radius: 7px;
	padding: 20px 20px;
	margin-bottom: 20px;
	cursor: pointer;

	.week-content {
		display: flex;
		flex-direction: column;
	}

	.col-1 {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		flex: 1;
	}
	.col-2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
	}

	h4 {
		font-weight: 500;
		font-size: 17px;
		line-height: 22px;
		color: #000;
		white-space: nowrap;
		margin-right: 20px;
	}

	p {
		margin-right: 10px;
		color: #5c5c5c;
		font-weight: 400;
		font-size: 17px;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 30px;
		border-radius: 20px;
		background: rgba(125, 214, 171, 0.2);
		color: #2a960f;
		font-size: 12px;
		font-weight: 400;
	}
	span.pending {
		background: #ffd7e5;
		color: #fa759e;
	}

	.toggle {
		svg {
			fill: #979797;
			font-size: 17px;
			transform: ${(props) =>
				props.showModules ? "rotate(-180deg)" : "rotate(0deg)"};
			transition: all 0.2s ease-in-out;
		}
	}

	@media screen and (min-width: 960px) {
		.week-content {
			flex-direction: row;
		}
		.col-2 {
			max-width: 300px;
		}

		span {
			width: 147px;
			height: 40px;
			font-size: 14px;
		}
	}
`;

export const ModuleWrapper = styled.div`
	display: block;
	padding-left: 10px;

	h5 {
		margin-bottom: 20px;
		font-weight: 400;
		font-size: 13px;
		color: #979797;
	}

	.input-row {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		@media screen and (min-width: 960px) {
			padding-left: 40px;
		}

		label {
			text-align: center;
			align-items: center;
			margin-right: 10px;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: #000000;
			width: 120px;
		}

		input {
			display: block;
			width: 100%;
			max-width: 240px;
			height: 40px;
			padding: 20px;
			color: #222;
			border-radius: 5px;
			border: 1px solid #7cbafd;

			@media screen and (min-width: 960px) {
				max-width: 275px;
				height: 50px;
				border-radius: 10px;
			}
		}
	}

	.submit-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			width: 100px;
			height: 50px;
			border: none;
			width: 80px;
			height: 40px;
			border-radius: 4px;
			color: #fff;
			cursor: pointer;
			background: rgba(10, 109, 216, 0.75);

			@media screen and (min-width: 960px) {
				width: 102px;
				height: 45px;
				border-radius: 5px;
			}
		}
	}
`;
export const ModuleCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	border-radius: 4px;
	padding: 10px 20px;
	margin-top: 10px;
	border: 1px solid #7cbafd;
	/* cursor: pointer; */

	:first-child {
		margin-top: 20px;
	}

	h4 {
		font-weight: 500;
		font-size: 17px;
		line-height: 22px;
		color: #000;
		white-space: nowrap;
		margin-right: 20px;
	}

	p {
		margin-right: 10px;
		color: #5c5c5c;
		font-weight: 400;
		font-size: 17px;
		text-overflow: ellipsis;
		flex: 1;
	}

	.check-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #4ecb71;
	}
	svg {
		fill: #fff;
		font-size: 14px;
		transition: all 0.2s ease-in-out;
	}

	@media screen and (min-width: 960px) {
		flex-direction: row;
		.col-1 {
			margin-bottom: 0px;
		}
		.col-2 {
			max-width: 300px;
		}

		span {
			width: 147px;
			height: 40px;
			font-size: 14px;
		}
	}
`;
