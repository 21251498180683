import Axios from "axios";

const REACT_APP_SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
console.log("HOST", REACT_APP_SERVER_HOST);

let baseURL;

const live = window.location.hostname === "instructor.cholatrek.io";
if (REACT_APP_SERVER_HOST) {
	baseURL = `${REACT_APP_SERVER_HOST}/`;
} else {
	const host = live
		? process.env.REACT_APP_LIVE_HOST_URL
		: process.env.REACT_APP_TEST_HOST_URL;
	baseURL = `${host}/`;
}

const host = live
	? process.env.REACT_APP_LIVE_HOST_URL
	: process.env.REACT_APP_TEST_HOST_URL;
// const host = "https://cholatrek.herokuapp.com";
baseURL = `${host}/`;

const AxiosUpload = async (requestObj) => {
	const { path, method, data, version = "v1" } = requestObj;

	// const token = localStorage.getItem("authToken");
	// console.log('lofty token: ', token)

	const headers = {
		"Content-Type": "multipart/form-data",
	};

	const url = version
		? `${baseURL}${version}/${path}/65e00162-92a2-403c-885d-e5f5742ebdb5`
		: `${baseURL}${path}`;

	console.log("url", url);
	try {
		const response = await Axios({
			method,
			url,
			data,
			headers,
			json: true,
		});
		const result = response && response.data;

		return result;
	} catch (error) {
		console.log(error?.response);
		if (error?.response?.status === 401) {
			localStorage.setItem("authToken", "");
			window.location.href = "/signin";
		}
		throw error;
	}
};

export default AxiosUpload;

export const config = () => {
	return {
		baseUrl: live
			? process.env.REACT_APP_LIVE_HOST
			: process.env.REACT_APP_TEST_HOST,
		token: "65e00162-92a2-403c-885d-e5f5742ebdb5",
	};
};
