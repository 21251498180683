import SignIn from './views/screens/SignIn/SignIn';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './views/screens/Home/Home';
import AllCourses from './views/screens/AllCourses/AllCourses';
import MainLayout from './views/components/Layouts/MainLayout/MainLayout';
import ClassRoom from './views/screens/ClassRoom/ClassRoom';
import Progress from './views/screens/ClassRoom/Progress';
import GradeStudent from './views/screens/ClassRoom/GradeStudent';
import UploadCourse from './views/screens/UploadCourse/UploadCourse';
import FreeModules from './views/screens/FreeModules/FreeModules';
import Profile from './views/screens/Profile/Profile';
import CrashModules from './views/screens/CrashModules/CrashModules';
import ExpertLedCourses from './views/screens/ExpertLedCourses/Index';
import ExpertLedModules from './views/screens/ExpertLedCourses/Modules';
import FreeLessonUpload from './views/screens/UploadCourse/FreeLessonUpload/FreeLessonUpload';
import CrashCourseUpload from './views/screens/UploadCourse/CrashLessonUpload/CrashCourseUpload';
import CrashCourseSyllabusUpload from './views/screens/UploadCourse/CrashLessonUpload/CrashCourseSyllabusUpload';
import ExpertLedCourseUpload from './views/screens/UploadCourse/ExpertLedCourseUpload/ExpertLedCourseUpload';
import ExpertLedSyllabusUpload from './views/screens/UploadCourse/ExpertLedCourseUpload/ExpertLedCourseSyllabusUpload';
import { useEffect } from 'react';
import AxiosCall from './Utils/axios';
import { useDispatch } from 'react-redux';
import { setUser } from './actions';
import "./App.css";
import { useCallback } from "react";

function App() {
	const dispatch = useDispatch();
	const getData = useCallback(async () => {
		if (localStorage.getItem("authToken")) {
			try {
				const res = await AxiosCall({
					method: "GET",
					path: "profile",
				});

				if (res.status) {
					const userData = {
						userId: res.data._id,
						email: res.data.email,
						username: res.data.username,
						firstName: res.data.firstName,
						lastName: res.data.lastName,
						profileImg: res.data.profileImg,
						stack: res.data.stack,
					};
					dispatch(setUser(userData));
				} else {
					console.log("An error occurred");
				}
			} catch (err) {
				console.log(err);
			}
		}
	}, [dispatch]);
	useEffect(() => {
		getData();
	});

	function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
		return (
			<Route
				{...rest}
				render={(props) => (
					<Layout>
						<Component {...props} />
					</Layout>
				)}
			/>
		);
	}
	return (
		<Router>
			<Switch>
				<RouteWrapper exact path="/" component={Home} layout={MainLayout} />
				<RouteWrapper
					exact
					path="/courses/all"
					component={AllCourses}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/classroom"
					component={ClassRoom}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/classroom/progress/:studentId"
					component={Progress}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/classroom/grade/:studentId"
					component={GradeStudent}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/course/upload"
					component={UploadCourse}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/courses/free"
					component={FreeModules}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/courses/crash"
					component={CrashModules}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/courses/expert"
					component={ExpertLedCourses}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/courses/expert-led/modules"
					component={ExpertLedModules}
					layout={MainLayout}
				/>
				<RouteWrapper
					exact
					path="/profile"
					component={Profile}
					layout={MainLayout}
				/>
				<Route exact path="/upload/course/free" component={FreeLessonUpload} />
				<Route
					exact
					path="/upload/course/free/:courseId"
					component={FreeLessonUpload}
				/>
				<Route
					exact
					path="/upload/course/crash/:courseId"
					component={CrashCourseUpload}
				/>
				<Route
					exact
					path="/upload/syllabus/course/crash/:courseId"
					component={CrashCourseSyllabusUpload}
				/>
				<Route
					exact
					path="/upload/course/expert/:courseId"
					component={ExpertLedCourseUpload}
				/>
				<Route
					exact
					path="/upload/syllabus/course/expert/:courseId"
					component={ExpertLedSyllabusUpload}
				/>
				<Route exact path="/signin" component={SignIn} />
			</Switch>
		</Router>
	);
}

export default App;
