import { Wrapper } from "./styles";

const Switch = (props) => {
	return (
		<Wrapper
			active={props.active}
			onClick={props.onClick}
			size={props.size ? props.size : 1}
		/>
	);
};

export default Switch;
