import { Link } from "react-router-dom";
import {
	Wrapper,
	HeadBar,
	ContentWrapper,
	Content,
	WeekListWrapper,
	WeekCard,
	ModuleCard,
	WeekForm,
	WeekFormWrapper,
	ModuleFormWrapper,
	ModuleForm,
	FormWrapper,
	Form,
	FormModal,
	UploadItemWrapper,
	UploadItem,
	LessonCard,
} from "./syllabusStyles";
import { VscMenu, VscSymbolColor } from "react-icons/vsc";
import { BsFillPencilFill } from "react-icons/bs";
import {
	AiOutlinePlus,
	AiOutlineCloudUpload,
	AiOutlineExpand,
} from "react-icons/ai";
import { BiVideo } from "react-icons/bi";
import { TiPencil } from "react-icons/ti";
import { IoTrashOutline } from "react-icons/io5";
import { MdOutlineQuiz, MdOutlineDragHandle } from "react-icons/md";
import { RiFileList3Line } from "react-icons/ri";
// import { RiFileList3Line, RiBarChartBoxLine } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import { useRef, useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import AxiosCall from "../../../../Utils/axios";
import AxiosUpload from "../../../../Utils/axios/upload";
import Loader from "../../../components/Shared/Loader/Loader";
import Message from "../../../components/Shared/Message/Index";
import Switch from "../../../components/Shared/Switch/Switch";
import { useCallback } from "react";

const ExpertLedCourseSyllabusUpload = () => {
	// let history = useHistory();
	const { courseId } = useParams();
	// const [error, setError] = useState(null);
	const [counter, setCounter] = useState(0);
	const [contntentForm, setContentForm] = useState(false);
	const [addWeekForm, setAddWeekForm] = useState(false);
	const [addModuleForm, setAddModuleForm] = useState(false);
	const [addLesson, setaddLesson] = useState(false);
	const [addVideoModal, setAddVideoModal] = useState(false);
	const [addNoteModal, setAddNoteModal] = useState(false);
	const [addInfoGraphicsModal, setAddInfoGraphicsModal] = useState(false);
	const [addQuizModal, setAddQuizModal] = useState(false);
	const [addVideoCallModal, setAddVideoCallModal] = useState(false);
	// const [addAssessmentModal, setAddAssessmentModal] = useState(false);
	const [addActivitiesModal, setAddActivitiesModal] = useState(false);
	const [moduleTitleModal, setModuleTitleModal] = useState(null);
	const [isDeletingWeek, setIsDeletingWeek] = useState(null);
	const [isDeletingModule, setIsDeletingModule] = useState(null);
	const [isDeletingLesson, setIsDeletingLesson] = useState(null);
	const [isTogglingWeek, setIsTogglingWeek] = useState(null);
	const [patch, setPatch] = useState(false);
	const [savingLesson, setSavingLesson] = useState(null);
	const [currentCohort, setCurrentCohort] = useState("")
	const [updateCurrentCohort, setupdateCurrentCohort] = useState(null)
	const [updateWeekModal, setUpdateWeekModal] = useState(false)
	const [selectedAttachment, setSelectedAttachment] = useState(null);

	const [loadingVideo, setLoadingVideo] = useState(false);
	const [loadingNote, setLoadingNote] = useState(false);
	// const [loadAssessment, setLoadingAssessment] = useState(false);
	const [loadingActivities, setLoadingActivities] = useState(false);
	const [loadingInfoGraphics, setLoadingInfoGraphics] = useState(false);

	const [lessonTitle, setLessonTitle] = useState(null);

	const addInfoGraphicsModalRef = useRef(null);
	const videoTitleRef = useRef(null);
	const videoLinkRef = useRef(null);

	const noteTitleRef = useRef(null);
	const noteLinkRef = useRef(null);

	const infoGraphicsTitleRef = useRef(null);
	const infoGraphicsLinkRef = useRef(null);

	const quizTitleRef = useRef(null);
	const quizLinkRef = useRef(null);

	const videoCallTitleRef = useRef(null);
	const videoCallLinkRef = useRef(null);
	const videoCallDescriptionRef = useRef(null);

	// const assessmentTitleRef = useRef(null);
	// const assessmentDescriptionRef = useRef(null);

	const activitiesTitleRef = useRef(null);
	const activitiesLinkRef = useRef(null);

	const addVideoModalRef = useRef(null);
	const addNoteModalRef = useRef(null);
	const addQuizModalRef = useRef(null);
	const addVideoCallModalRef = useRef(null);
	// const addAssessmentModalRef = useRef(null);
	const addActivitiesModalRef = useRef(null);
	const moduleTitleModalRef = useRef(null);
	const [activeLesson, setActiveLesson] = useState({});

	const formInput = useRef({
		courseId: courseId,
	});
	const closeModal = (e, modalRef) => {
		if (e.target === modalRef.current) {
			setAddVideoModal(false);
			setAddVideoCallModal(false);
			setAddNoteModal(false);
			setAddQuizModal(false);
			// setAddAssessmentModal(false);
			setAddActivitiesModal(false);
			setModuleTitleModal(null);
			setLessonTitle(null);
			setaddLesson(false);
			setAddInfoGraphicsModal(false);
		}
		console.log(addLesson, savingLesson)
	};
	// const [lessions, setLessons] = useState([]);
	const currentWeek = useRef(null);
	const selectedWeek = useRef(null);
	const currentModule = useRef(null);
	const selectedModule = useRef(null);
	const selectedLesson = useRef(null);

	// const modules = useRef([]);
	const lessonTitleRef = useRef(null);
	const weeks = useRef([]);

	console.log("course id: ", courseId);

	const clear = () => {
		videoTitleRef.current = null;
		videoLinkRef.current = null;

		noteTitleRef.current = null;
		noteLinkRef.current = null;

		quizTitleRef.current = null;
		quizLinkRef.current = null;

		videoCallTitleRef.current = null;
		videoCallLinkRef.current = null;
		videoCallDescriptionRef.current = null;

		// assessmentTitleRef.current = null;
		// assessmentDescriptionRef.current = null;

		activitiesTitleRef.current = null;
		activitiesLinkRef.current = null;

		setSelectedAttachment(null);

		formInput.current = {
			courseId: courseId,
			weekId: selectedWeek.current,
			moduleId: selectedModule.current,
			lessonId: selectedLesson.current,
		};
	};

	const getSyllabus = useCallback(async () => {
		try {
			const res = await AxiosCall({
				method: "GET",
				path: "classroom/course/" + courseId,
			});

			weeks.current = res.data.weeks;
			setCounter(1);

			console.log("response: ", res.data.weeks);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, [courseId]);
	useEffect(() => {
		getSyllabus();
	}, [getSyllabus]);

	const saveLesson = async () => {
		console.log("saving");
		/* 	const length =
			weeks.current[selectedWeek.current - 1].modules[
				selectedModule.current - 1
			].lessons.length;
		const id =
			weeks.current[selectedWeek.current - 1].modules[
				selectedModule.current - 1
			]?.lessons[length - 1]?.lessonId + 1;
	 */ formInput.current = {
			...formInput.current,
			weekId: selectedWeek.current,
			moduleId: selectedModule.current,

			lessonId: selectedLesson.current,
		};
		setSavingLesson(selectedLesson.current);

		console.log("req: ", formInput.current);
		try {
			const res = await AxiosCall({
				method: "PUT",
				path: "course/expert",
				data: formInput.current,
			});
			console.log("response: ", res.data.weeks);

			if (res.status) {
				Message.success(res.message);
				await getSyllabus().then(() => {
					clear();
				});
			}
			setSavingLesson(null);
		} catch (err) {
			setSavingLesson(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const updateLesson = async () => {
		console.log("updating");
		formInput.current = {
			...formInput.current,
			weekId: selectedWeek.current,
			moduleId: selectedModule.current,
			lessonId: selectedLesson.current,
		};
		setSavingLesson(selectedLesson.current);

		console.log("update video, req: ", formInput.current);
		try {
			const res = await AxiosCall({
				method: "PATCH",
				path: "course/expert/modules",
				data: formInput.current,
			});
			console.log("response: ", res.data.weeks);

			if (res.status) {
				Message.success(res.message);
				await getSyllabus().then(() => {
					clear();
				});
			}
			setSavingLesson(null);
		} catch (err) {
			setSavingLesson(null);
			Message.error(err?.response?.data?.error);
		}
	};
	const toggleWeek = async ({ weekId, isActive, cohort }) => {
		setIsTogglingWeek(weekId);
		const active = cohort == " " ? !isActive : true
		console.log(active)
		try {
			const res = await AxiosCall({
				method: "PATCH",
				path: `classroom/course/`,
				data: {
					courseId: courseId,
					weekId: weekId,
					resume: active,
					cohort: cohort
				},
			});
			// console.log("response: ", res);

			if (res.status) {
				setIsTogglingWeek(null);
				weeks.current[weekId - 1].isActive = active;
				setCounter(counter + 1);
				Message.success(res.message);
			}
		} catch (err) {
			setIsTogglingWeek(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const deleteWeek = async ({ weekId, weekIndex }) => {
		setIsDeletingWeek(weekId);

		console.log("req:", formInput.current);
		try {
			const res = await AxiosCall({
				method: "DELETE",
				path: "course/expert",
				data: {
					courseId: courseId,
					weekId: weekId,
				},
			});
			console.log("response: ", res);

			if (res?.status) {
				weeks.current.splice(weekIndex, 1);
				getSyllabus();
				Message.success(res.message);
			}
			setIsDeletingWeek(null);
		} catch (err) {
			setIsDeletingWeek(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const deleteModule = async ({ moduleId, moduleIndex, weekId, weekIndex }) => {
		setIsDeletingModule(moduleId);
		try {
			const res = await AxiosCall({
				method: "DELETE",
				path: "course/expert",
				data: {
					courseId: courseId,
					moduleId: moduleId,
					weekId: weekId,
				},
			});

			if (res?.status) {
				weeks.current[weekIndex].modules.splice(moduleIndex, 1);
				getSyllabus();
				Message.success(res.message);
			}
			setIsDeletingModule(null);
		} catch (err) {
			setIsDeletingModule(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const deleteLesson = async ({
		moduleId,
		moduleIndex,
		weekId,
		weekIndex,
		lessonId,
		lessonIndex,
	}) => {
		setIsDeletingLesson(lessonId);
		try {
			const res = await AxiosCall({
				method: "DELETE",
				path: "course/expert",
				data: {
					courseId: courseId,
					weekId: weekId,
					moduleId: moduleId,
					lessonId: lessonId,
				},
			});

			if (res.status) {
				weeks.current[weekIndex].modules[moduleIndex].lessons.splice(
					lessonIndex,
					1
				);
				getSyllabus();
				Message.success(res.message);
			}
			setIsDeletingLesson(null);
		} catch (err) {
			setIsDeletingLesson(null);
			Message.error(err?.response?.data?.error);
		}
	};

	const uploadVideo = async (file) => {
		setLoadingVideo(true);
		let reader = new FileReader();
		reader.onload = (e) => {
			//set values of selected attachment
			let newSelectedAttachment = {};
			newSelectedAttachment.file = file;
			newSelectedAttachment.blobData = e.target.result;

			//if file type is image then show the attachment or download the same
			if (file.type.includes("video")) {
				setSelectedAttachment(newSelectedAttachment);
			}
		};

		//read the file
		reader.readAsDataURL(file);

		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			videoLinkRef.current = res.data[0].url;
			formInput.current = {
				...formInput.current,
				video: res.data[0].url,
			};
		}
		Message.success(res.message);
		setLoadingVideo(false);
	};

	const uploadNote = async (file) => {
		setLoadingNote(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		console.log("response for note", res);

		if (res) {
			noteLinkRef.current = res.data[0].url;
			noteTitleRef.current = noteLinkRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				notes: { link: noteLinkRef.current, title: noteTitleRef.current },
			};
		}

		Message.success(res.message);
		setLoadingNote(false);
	};

	/* const uploadAssessment = async (file) => {
		setLoadingAssessment(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});
		assessmentDescriptionRef.current = res.data[0].url;
		assessmentTitleRef.current =
			assessmentTitleRef.current?.value ?? res.data[0].name;

		if (res) {
			formInput.current = {
				...formInput.current,
				assessment: {
					link: assessmentDescriptionRef.current,
					title: assessmentTitleRef.current,
				},
			};
		}
		Message.success(res.message);
		setLoadingAssessment(false);
	}; */

	const uploadInfoGraphics = async (file) => {
		setLoadingInfoGraphics(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		if (res) {
			infoGraphicsLinkRef.current = res.data[0].url;
			infoGraphicsTitleRef.current =
				infoGraphicsTitleRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				infographics: {
					link: infoGraphicsLinkRef.current,
					title: infoGraphicsTitleRef.current,
				},
			};
		}
		console.log("response", res);
		setLoadingInfoGraphics(false);
	};

	const uploadActivities = async (file) => {
		setLoadingActivities(true);
		const formData = new FormData();
		formData.append("file", file);
		console.log("file", file);
		console.log("name", file?.name);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});
		console.log("response", res);

		if (res) {
			activitiesLinkRef.current = res.data[0].url;
			activitiesTitleRef.current =
				activitiesTitleRef.current?.value ?? res.data[0].name;
			formInput.current = {
				...formInput.current,
				activities: {
					link: activitiesLinkRef.current,
					title: activitiesTitleRef.current,
				},
			};
		}

		Message.success(res.message);
		setLoadingActivities(false);
	};

	return (
		<Wrapper>
			<HeadBar>
				<h3>upload courses</h3>
				<Link to="/course/upload">Exit</Link>
			</HeadBar>

			<ContentWrapper>
				<Content showForm={contntentForm}>
					<div
						className="tile-head"
						onClick={() => setContentForm(!contntentForm)}>
						<span>Syllabus</span>
					</div>

					<p className="upload-info">
						Start putting together your course by creating sections, lectures
						and practice (quizzes, coding exercises and assignments).
						<br />
						<br />
						<span>Note: Modules can only be saved when they have lessons</span>
					</p>

					<WeekListWrapper>
						{weeks.current.map((item, weekIndex) => (
							<WeekCard
								key={weekIndex}
								onClick={() => {
									selectedWeek.current = item.weekId;
								}}>
								<div className="col-1 week-col-1">
									<div className="sec-1">
										<h4>Week {weekIndex + 1}</h4>
										{/* <span>{item.title}</span> */}
										<BsFillPencilFill />
										{isDeletingWeek === item.weekId ? (
											<Loader topColor="#0A6DD8" sideColor="#0A6DD866" />
										) : (
											<IoTrashOutline
												onClick={() => {
													deleteWeek({
														weekId: item.weekId,
														weekIndex: weekIndex,
													});
												}}
											/>
										)}
									</div>

									<div className="sec-2">
										{/* {isTogglingWeek === item.weekId ? (
											<Loader topColor="#0A6DD8" sideColor="#0A6DD866" />
										) : (
											<Switch
												active={item.isActive}
												onClick={() => {
													toggleWeek({
														weekId: item?.weekId,
														isActive: item?.isActive,
														cohort: " "
													})
												}}
											/>
										)}
										Toggle week */}

										<div className="toggle-wrapper">
											{isTogglingWeek === item.weekId ? (
												<Loader topColor="#0A6DD8" sideColor="#0A6DD866" />
											) : (
												<Switch
													active={item.isActive}
													onClick={() => {
														setupdateCurrentCohort(item)
														setUpdateWeekModal(true)
													}}
												/>
											)}
											{/* <VscMenu /> */}
											Toggle week
										</div>
									</div>
								</div>
								{item.modules.map((data, moduleIndex) => {
									return (
										<>
											<ModuleCard
												key={moduleIndex}
												onClick={() => {
													selectedWeek.current = item.weekId;
													selectedModule.current = data.moduleId;
												}}>
												<div className="col-1">
													<div className="sec-1">
														<h4>Module {moduleIndex + 1}:</h4>
														<span>{data.title}: </span>
														<BsFillPencilFill
															onClick={() => {
																selectedWeek.current = item.weekId;
																selectedModule.current = data.moduleId;
																setModuleTitleModal(item.weekId);

																console.log("week id: ", selectedWeek.current);
																console.log(
																	"Module id: ",
																	selectedModule.current
																);

																console.log(
																	weeks.current[selectedWeek.current - 1]
																		.modules[selectedModule.current - 1].title
																);
															}}
														/>

														{selectedWeek.current === item.weekId &&
															isDeletingModule === data.moduleId ? (
															<Loader
																topColor="#0A6DD8"
																sideColor="#0A6DD866"
															/>
														) : (
															<IoTrashOutline
																onClick={() => {
																	selectedWeek.current = item.weekId;
																	selectedModule.current = data.moduleId;
																	deleteModule({
																		moduleId: data.moduleId,
																		weekIndex: weekIndex,
																		weekId: item.weekId,
																		moduleIndex: moduleIndex,
																	});
																}}
															/>
														)}
													</div>

													<button
														onClick={() => {
															setaddLesson(null);

															selectedWeek.current = item.weekId;
															selectedModule.current = data.moduleId;
															const length =
																weeks.current[selectedWeek.current - 1].modules[
																	selectedModule.current - 1
																].lessons.length;
															/* 
																const lessonItem = weeks.current[
																	selectedWeek.current - 1
																].modules[
																	selectedModule.current - 1
																].lessons.find(
																	(lesson) => Number(lesson.lessonId) === length
																);
 */
															selectedLesson.current = length + 1;
															setaddLesson(data.moduleId);
														}}>
														Add Lessons
													</button>
												</div>

												{
													<div
														className="lession-action-column"
														onClick={() => {
															setPatch(false);
															selectedWeek.current = item.weekId;
															selectedModule.current = data.moduleId;
															setActiveLesson({});
															const length =
																weeks.current[selectedWeek.current - 1].modules[
																	selectedModule.current - 1
																].lessons.length;
															/* 	const lessonItem = weeks.current[
																		selectedWeek.current - 1
																	].modules[
																		selectedModule.current - 1
																	].lessons.find(
																		(lesson) => Number(lesson.lessonId)=== length
																	); */

															selectedLesson.current = length + 1;
															setaddLesson(data.moduleId);
														}}>
														<button onClick={() => setAddVideoModal(true)}>
															<BiVideo />
															<span>Pre-recorded Video</span>
														</button>
														<button onClick={() => setAddNoteModal(true)}>
															<CgNotes />
															<span>Notes</span>
														</button>
														<button
															onClick={() => setAddInfoGraphicsModal(true)}>
															<VscSymbolColor />
															<span>Infographics</span>
														</button>
														<button onClick={() => setAddQuizModal(true)}>
															<MdOutlineQuiz />
															<span>Quiz</span>
														</button>
														<button onClick={() => setAddVideoCallModal(true)}>
															<AiOutlineExpand />
															<span>Live Video Call</span>
														</button>
														{/* 	<button onClick={() => setAddAssessmentModal(true)}>
															<RiBarChartBoxLine />
															<span>Assessment</span>
														</button> */}
														<button onClick={() => setAddActivitiesModal(true)}>
															<RiFileList3Line />
															<span>Activities</span>
														</button>
														<div
															onClick={() => {
																setaddLesson(false);
																selectedModule.current = null;
															}}>
															&times;
														</div>
													</div>
												}
											</ModuleCard>
											{data.lessons.map((lessonItem, lessonIndex) => {
												return (
													<LessonCard
														key={lessonIndex}
														onClick={() => {
															setPatch(true);
															selectedLesson.current = lessonItem.lessonId;
															selectedWeek.current = item.weekId;
															selectedModule.current = data.moduleId;
														}}>
														<div className="lesson-toggle">
															<MdOutlineDragHandle />
														</div>
														<div className="lesson-content">
															<div className="lesson-header"
																onClick={() => setActiveLesson(lessonItem)}
															>
																<div className="sec-1">
																	<h4>Lesson {lessonIndex + 1}:</h4>
																	&nbsp;&nbsp;&nbsp;
																	<span>
																		{lessonItem.title ??
																			`Lesson ${lessonIndex + 1}`}
																		:
																	</span>
																	&nbsp;&nbsp;&nbsp;
																	<div
																		className="lesson-title"
																		onClick={() =>
																			setLessonTitle(data.moduleId)
																		}>
																		<BsFillPencilFill />
																	</div>
																	&nbsp; &nbsp;&nbsp;
																	{selectedWeek.current === item.weekId &&
																		selectedModule.current === data.moduleId &&
																		isDeletingLesson === lessonItem.lessonId ? (
																		<Loader
																			topColor="#0A6DD8"
																			sideColor="#0A6DD866"
																		/>
																	) : (
																		<IoTrashOutline
																			onClick={() => {
																				selectedWeek.current = item.weekId;
																				selectedModule.current = data.moduleId;
																				deleteLesson({
																					moduleId: data.moduleId,
																					weekId: item.weekId,
																					weekIndex: weekIndex,
																					moduleIndex: moduleIndex,
																					lessonId: lessonItem.lessonId,
																					lessonIndex: lessonIndex,
																				});
																			}}
																		/>
																	)}
																</div>
																<div className={"sec-2"}>
																	{/* 
																	<button
																		onClick={() => {
																			selectedWeek.current = item.weekId;
																			selectedModule.current = data.moduleId;

																			console.log("Lesson item", lessonItem);

																			// formInput.current = {
																			//     ...formInput.current,
																			//     ...lessonItem
																			// }
																			saveLesson();
																		}}>
																		{savingLesson===lessonItem.lessonId &&
																		selectedModule.current===data.moduleId &&
																		selectedWeek.current===item.weekId ? (
																			<Loader />
																		) : (
																			"save"
																		)}
																	</button> */}
																</div>
															</div>

															{selectedWeek.current === item.weekId &&
																selectedModule.current === data.moduleId &&
																activeLesson.lessonId ===
																lessonItem.lessonId && (
																	<div className="lesson-action-container">
																		{/* 	<button
																	onClick={() => {
																		selectedWeek.current = item.weekId;
																		selectedModule.current = data.moduleId;
																		selectedLesson.current =
																			lessonItem.lessonId;

																		console.log("Lesson item", lessonItem);

																		// formInput.current = {
																		//     ...formInput.current,
																		//     ...lessonItem
																		// }
																		saveLesson();
																	}}>
																	{savingLesson===lessonItem.lessonId &&
																	selectedModule.current===data.moduleId &&
																	selectedWeek.current===item.weekId ? (
																		<Loader />
																	) : (
																		"save"
																	)}
																</button> */}
																		<div className="lession-action-column">
																			<button
																				onClick={() => {
																					setAddVideoModal(true);
																				}}>
																				<BiVideo />
																				<span>Pre-recorded Video</span>
																			</button>
																			<button
																				onClick={() => setAddNoteModal(true)}>
																				<CgNotes />

																				<span>Notes</span>
																			</button>
																			<button
																				onClick={() =>
																					setAddInfoGraphicsModal(true)
																				}>
																				<VscSymbolColor />

																				<span>Infographics</span>
																			</button>
																			<button
																				onClick={() => setAddQuizModal(true)}>
																				<MdOutlineQuiz />
																				<span>Quiz</span>
																			</button>
																			<button
																				onClick={() =>
																					setAddVideoCallModal(true)
																				}>
																				<AiOutlineExpand />
																				<span>Live Video Call</span>
																			</button>{/* 
																			<button
																				onClick={() =>
																					setAddAssessmentModal(true)
																				}>
																				<RiBarChartBoxLine />
																				<span>Assessment</span>
																			</button> */}
																			<button
																				onClick={() =>
																					setAddActivitiesModal(true)
																				}>
																				<RiFileList3Line />
																				<span>Activities</span>
																			</button>
																			<div
																				onClick={() => {

																					setaddLesson(false);
																					setActiveLesson({});
																					selectedModule.current = null;
																				}}>
																				&times;
																			</div>
																		</div>

																		<div className="action-details-container">
																			{lessonItem?.video && (
																				<div className="action-details ">
																					<video
																						className="action-details-video"
																						controls>
																						<source src={lessonItem.video} />
																					</video>
																				</div>
																			)}
																			{lessonItem?.notes && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<CgNotes />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.notes?.title}
																					</h4>
																				</div>
																			)}
																			{lessonItem?.infographics && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<VscSymbolColor />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.infographics?.link}
																					</h4>
																				</div>
																			)}
																			{lessonItem?.test && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<MdOutlineQuiz />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.test?.link}
																					</h4>
																				</div>
																			)}

																			{lessonItem?.call && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<AiOutlineExpand />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.call?.link}
																					</h4>
																				</div>
																			)}

																			{/* 	{lessonItem?.assessment && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<RiBarChartBoxLine />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.assessment?.link}
																					</h4>
																				</div>
																			)} */}

																			{lessonItem?.activities && (
																				<div className="action-details--2">
																					<div className="action-details-icon">
																						<RiFileList3Line />
																					</div>
																					<h4 className="action-details-text">
																						{lessonItem?.activities?.link}
																					</h4>
																				</div>
																			)}
																		</div>
																	</div>
																)}
														</div>
													</LessonCard>
												);
											})}
										</>
									);
								})}
								{!addModuleForm && (
									<button
										className="add-lesson-btn"
										onClick={() => {
											setAddModuleForm(true);
											selectedWeek.current = item.weekId;
										}}>
										<AiOutlinePlus />
										<span>Add Module</span>
									</button>
								)}
								{addModuleForm && selectedWeek.current === item.weekId && (
									<ModuleFormWrapper>
										<ModuleForm>
											<div className="col-1">
												<input
													ref={currentModule}
													type="text"
													placeholder="Module title"
												/>
											</div>
											<div className="col-2">
												<button
													onClick={() => {
														setAddModuleForm(false);
														selectedWeek.current = null;
													}}>
													Cancel
												</button>
												<button
													onClick={() => {
														setAddModuleForm(false);

														console.log("Lofty", weeks.current);
														console.log("Selected week", selectedWeek.current);
														console.log(
															"Selected module",
															selectedModule.current
														);

														console.log("current week", weeks.current);

														const newWeeks = weeks.current;
														let newModuleId;

														if (selectedWeek.current <= weeks.current.length) {
															newModuleId =
																weeks.current[selectedWeek.current - 1].modules
																	.length + 1;

															newWeeks[selectedWeek.current - 1].modules = [
																...newWeeks[selectedWeek.current - 1].modules,
																{
																	moduleId: newModuleId,
																	title: currentModule.current?.value,
																	isNewLesson: true,
																	lessons: [],
																},
															];
														} else {
															newModuleId = 1;

															newWeeks[weeks.current.length - 1].modules = [
																...newWeeks[weeks.current.length - 1]?.modules,
																{
																	moduleId: newModuleId,
																	title: currentModule.current?.value,
																	isNewLesson: true,
																	lessons: [],
																},
															];
														}

														weeks.current = newWeeks;

														formInput.current = {
															...formInput.current,
															moduleId: newModuleId,
															moduleTitle: currentModule.current?.value,
														};
														console.log("new module id:", weeks.current.length);
														selectedWeek.current = null;

														setCounter(2);
													}}>
													Add Module
												</button>
											</div>
										</ModuleForm>
									</ModuleFormWrapper>
								)}
							</WeekCard>
						))}
					</WeekListWrapper>
					{
						updateWeekModal && (
							<div

							>
								<FormModal >
									<FormWrapper
										style={{
											display: "flex",
											alignItems: "center",
											// height:"20vh"
										}}>
										<Form >
											<h1>Please Enter The Cohorts</h1>
											<p>Each cohort should be separated by space</p>
											<br /><br />
											<input
												type="text"
												placeholder="ex: 1.0 1.1 1.2"
												value={currentCohort}
												onChange={(e) => setCurrentCohort(e.target.value)}
											/>
											<div
												style={{
													display: "flex",
												}}
											>

												<button
													style={{
														width: "100px",
														marginRight: "10px",
													}}
													onClick={() => {
														setUpdateWeekModal(false)
													}}
												>Cancel</button>
												<button
													style={{
														width: "100px",
													}}
													onClick={() => {
														toggleWeek({
															weekId: updateCurrentCohort?.weekId,
															isActive: updateCurrentCohort?.isActive,
															cohort: currentCohort === "" ? " " : currentCohort,
														})
														setUpdateWeekModal(false)
														setTimeout(() => {
															setupdateCurrentCohort(null)
														}, 1000)
													}}
												>Save</button>
											</div><br/>
											<p>NB : To turn off for all cohorts leave the input field empty</p>
										</Form>
									</FormWrapper>
								</FormModal>
							</div>
						)
					}

					{!addWeekForm ? (
						<button
							className="add-module-btn"
							onClick={() => {
								setAddWeekForm(true);
								selectedModule.current = null;
							}}>
							<AiOutlinePlus />
							<span>Add New Week</span>
						</button>
					) : (
						<WeekFormWrapper>
							<WeekForm>
								<div className="col-1">
									<span>New Week:</span>
									<input
										ref={currentWeek}
										type="text"
										placeholder="Enter a new week"
									/>
								</div>
								<div className="col-2">
									<button onClick={() => setAddWeekForm(false)}>Discard</button>
									<button
										onClick={() => {
											setAddWeekForm(false);
											const newWeekId = weeks.current.length + 1;
											weeks.current = [
												...weeks.current,
												{
													weekId: newWeekId,
													title: currentWeek.current?.value,
													isNewWeek: true,
													isActive: false,
													modules: [],
												},
											];
											formInput.current = {
												...formInput.current,
												weekId: newWeekId,
											};

											console.log("new module id:", weeks.current.length);
										}}>
										Save
									</button>
								</div>
							</WeekForm>
						</WeekFormWrapper>
					)}
					{addVideoModal && (
						<FormModal
							ref={addVideoModalRef}
							onClick={(e) => closeModal(e, addVideoModalRef)}>
							<FormWrapper>
								<Form>
									<input ref={videoTitleRef} placeholder="Title" />
									<UploadItemWrapper>
										<UploadItem>
											{loadingVideo ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : selectedAttachment ? (
												<video
													style={{ background: "#222", borderRadius: 10 }}
													width="300"
													height="200"
													controls>
													<source
														src={selectedAttachment.blobData}
														type={selectedAttachment.file.type}
													/>
													Your browser does not support HTML5 video.
												</video>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your Video file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-video">Browse</label>
														<input
															onChange={(e) => {
																uploadVideo(e.target.files[0]);
															}}
															accept="video/*"
															style={{ display: "none" }}
															type="file"
															id="upload-video"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddVideoModal(false);

											console.log("Weeks: ", weeks.current);
											console.log("Selected weeek: ", selectedWeek.current);
											console.log("Selected module: ", selectedModule.current);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);

											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current,
																moduleId: selectedModule.current,
																lessonId: selectedLesson.current,
																title: videoTitleRef.current?.value ?? "",
																url: videoLinkRef.current,
															},
														];
													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current,
												video: videoLinkRef.current,
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addNoteModal && (
						<FormModal
							ref={addNoteModalRef}
							onClick={(e) => closeModal(e, addNoteModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={noteTitleRef}
										placeholder="Title"
										defaultValue={activeLesson.notes?.title ?? ""}
									/>
									<UploadItemWrapper>
										<UploadItem>
											{loadingNote ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : noteLinkRef.current ? (
												<div className="image-preview">
													<img
														src={noteLinkRef.current}
														alt={noteLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<label htmlFor="upload-note">Browse</label>
												</>
											)}
											<input
												onChange={(e) => {
													uploadNote(e.target.files[0]);
												}}
												accept="application/pdf"
												style={{ display: "none" }}
												type="file"
												id="upload-note"
											/>
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddNoteModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);

											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current ?? "",
																moduleId: selectedModule.current ?? "",
																lessonId: selectedLesson.current ?? "",
																title: noteTitleRef.current?.value ?? "",
																url: noteLinkRef.current ?? "",
															},
														];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												notes: {
													title:
														noteTitleRef.current?.value ?? noteTitleRef.current,
													link: noteLinkRef.current ?? "",
												},
											};
											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addQuizModal && (
						<FormModal
							ref={addQuizModalRef}
							onClick={(e) => {
								closeModal(e, addQuizModalRef);
							}}>
							<FormWrapper>
								<Form>
									<input
										ref={quizTitleRef}
										placeholder="Title"
										defaultValue={activeLesson?.test?.title ?? ""}
									/>
									<label htmlFor="">
										Use External Hosted Content ( URL to your Quiz )
									</label>
									<input
										ref={quizLinkRef}
										placeholder="Link to Quiz"
										defaultValue={activeLesson?.test?.link ?? ""}
									/>
									<div style={{ opacity: 0 }}></div>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddQuizModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);

											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current,
																moduleId: selectedModule.current,
																lessonId: selectedLesson.current,
																title: quizTitleRef.current?.value,
																link: quizLinkRef.current?.value,
															},
														];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												test: {
													title:
														quizTitleRef.current?.value ?? quizLinkRef.current,
													link: quizLinkRef.current?.value,
												},
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}

					{addVideoCallModal && (
						<FormModal
							ref={addVideoCallModalRef}
							onClick={(e) => {
								closeModal(e, addVideoCallModalRef);
							}}>
							<FormWrapper>
								<Form>
									<input
										ref={videoCallTitleRef}
										placeholder="Title"
										type="string"
										defaultValue={activeLesson?.call?.title ?? ""}
									/>
									<label htmlFor="">
										Use External Hosted Content ( URL to your Live call )
									</label>
									<input
										ref={videoCallLinkRef}
										type="url"
										placeholder="ex: https://www.example.com"
										defaultValue={activeLesson?.call?.link ?? ""}
									/>
									<textarea
										ref={videoCallDescriptionRef}
										placeholder="Description"
										defaultValue={
											activeLesson?.call?.description ?? ""
										}></textarea>
									{/* 	<label htmlFor="">Replay link</label>
									<input
										type="url"
										placeholder="ex: https://www.example.com"
										defaultValue={activeLesson?.call?.replay ?? ""}
									/> */}
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddVideoCallModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current,
																moduleId: selectedModule.current,
																lessonId: selectedLesson.current,
																title: quizTitleRef.current?.value,
																link: quizLinkRef.current?.value,
																description:
																	videoCallDescriptionRef.current?.value,
															},
														];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												call: {
													title: videoCallTitleRef.current?.value,
													link: videoCallLinkRef.current?.value,
													description: videoCallDescriptionRef.current?.value,
												},
											};
											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{/* 					{addAssessmentModal && (
						<FormModal
							ref={addAssessmentModalRef}
							onClick={(e) => {
								closeModal(e, addAssessmentModalRef);
							}}>
							<FormWrapper>
								<Form>
									<input
										ref={assessmentTitleRef}
										placeholder="Title"
										defaultValue={activeLesson.assessment?.title ?? ""}
									/>
									{/* <input ref={assessmentDescriptionRef} placeholder="Submition Deadline" /> }
									<UploadItemWrapper>
										<UploadItem>
											{loadAssessment ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : assessmentDescriptionRef.current ? (
												<div className="image-preview">
													<img
														src={assessmentDescriptionRef.current}
														alt={assessmentDescriptionRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													{" "}
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-info-graphics">Browse</label>
														<input
															onChange={(e) => {
																uploadAssessment(e.target.files[0]);
															}}
															accept="application/pdf"
															style={{ display: "none" }}
															type="file"
															id="upload-info-graphics"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddAssessmentModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
														...newWeeks[selectedWeek.current - 1].modules[
															selectedModule.current - 1
														].lessons,
														{
															weekId: selectedWeek.current,
															moduleId: selectedModule.current,
															lessonId: selectedLesson.current,
															title: assessmentTitleRef.current?.value,
															link: assessmentDescriptionRef.current,
														},
													];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												assessment: {
													title:
														assessmentTitleRef.current?.value ??
														assessmentTitleRef.current,
													link: assessmentDescriptionRef.current,
												},
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)} */}

					{addInfoGraphicsModal && (
						<FormModal
							ref={addInfoGraphicsModalRef}
							onClick={(e) => closeModal(e, addInfoGraphicsModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={infoGraphicsTitleRef}
										placeholder="Subject"
										defaultValue={activeLesson.infographics?.title ?? ""}
									/>
									<UploadItemWrapper>
										<UploadItem>
											{loadingInfoGraphics ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : infoGraphicsLinkRef.current ? (
												<div className="image-preview">
													<img
														src={infoGraphicsLinkRef.current}
														alt={infoGraphicsLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													{" "}
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-info-graphics">Browse</label>
														<input
															onChange={(e) => {
																uploadInfoGraphics(e.target.files[0]);
															}}
															style={{ display: "none" }}
															type="file"
															accept="application/pdf"
															id="upload-info-graphics"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddInfoGraphicsModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);
											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current,
																moduleId: selectedModule.current,
																lessonId: selectedLesson.current,
																title: infoGraphicsTitleRef.current?.value,
																link: infoGraphicsLinkRef.current,
															},
														];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												weekId: selectedWeek.current,
												moduleId: selectedModule.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												infographics: {
													title:
														infoGraphicsTitleRef.current?.value ??
														infoGraphicsTitleRef.current,
													link: infoGraphicsLinkRef.current,
												},
											};

											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{addActivitiesModal && (
						<FormModal
							ref={addActivitiesModalRef}
							onClick={(e) => closeModal(e, addActivitiesModalRef)}>
							<FormWrapper>
								<Form>
									<input
										ref={activitiesTitleRef}
										placeholder="Title"
										defaultValue={activeLesson.activities?.title ?? ""}
									/>
									<UploadItemWrapper>
										<UploadItem>
											{loadingActivities ? (
												<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
											) : activitiesLinkRef.current ? (
												<div className="image-preview">
													<img
														src={activitiesLinkRef.current}
														alt={infoGraphicsLinkRef.current}
													/>
													<div className="edit-box">
														<TiPencil />
													</div>
													<label htmlFor="upload-note"></label>
												</div>
											) : (
												<>
													<div className="upload-icon-wrapper">
														<AiOutlineCloudUpload />
													</div>
													<span>
														Drop your PDF file here <br /> or
													</span>
													<form action="" encType="multipart/form-data">
														<label htmlFor="upload-activities">Browse</label>
														<input
															onChange={(e) => {
																uploadActivities(e.target.files[0]);
															}}
															accept="application/pdf"
															style={{ display: "none" }}
															type="file"
															id="upload-activities"
														/>
													</form>
												</>
											)}
										</UploadItem>
									</UploadItemWrapper>
									<button
										onClick={(e) => {
											e.preventDefault();
											setAddActivitiesModal(false);

											const availableLessons = weeks.current[
												selectedWeek.current - 1
											].modules[selectedModule.current - 1].lessons.filter(
												(item) =>
													item.moduleId === selectedModule.current &&
													item.lessonId === selectedLesson.current
											);

											if (!patch) {
												if (availableLessons.length === 0) {
													const newWeeks = weeks.current;
													newWeeks[selectedWeek.current - 1].modules[
														selectedModule.current - 1
													].lessons = [
															...newWeeks[selectedWeek.current - 1].modules[
																selectedModule.current - 1
															].lessons,
															{
																weekId: selectedWeek.current,
																moduleId: selectedModule.current,
																lessonId: selectedLesson.current,
																title: activitiesTitleRef.current?.value,
																link: activitiesLinkRef.current?.value,
															},
														];

													weeks.current = newWeeks;
												}
											}

											formInput.current = {
												...formInput.current,
												lessonId: selectedLesson.current, // newModules[selectedModule.current].lessons.length + 1,
												activities: {
													title:
														activitiesTitleRef.current?.value ??
														activitiesTitleRef.current,
													link: activitiesLinkRef.current,
												},
											};
											if (patch) {
												updateLesson();
											} else {
												saveLesson();
											}
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}

					{moduleTitleModal != null && (
						<FormModal
							ref={moduleTitleModalRef}
							onClick={(e) => closeModal(e, moduleTitleModalRef)}>
							<FormWrapper minHeight="unset">
								<Form>
									<input
										ref={activitiesTitleRef}
										placeholder="Enter Module Title"
										defaultValue={
											weeks.current[selectedWeek.current - 1].modules[
												selectedModule.current - 1
											].title
										}
										onChange={(e) => {
											weeks.current[selectedWeek.current - 1].modules[
												selectedModule.current - 1
											].title = e.target.value;

											formInput.current = {
												...formInput.current,
												moduleTitle: e.target.value,
											};
										}}
									/>
									<button
										onClick={(e) => {
											e.preventDefault();
											setModuleTitleModal(null);
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
					{lessonTitle != null && (
						<FormModal
							ref={lessonTitleRef}
							onClick={(e) => closeModal(e, lessonTitleRef)}>
							<FormWrapper minHeight="unset">
								<Form>
									<input
										placeholder="Enter Lesson Title"
										defaultValue={
											weeks.current[selectedWeek.current - 1].modules[
												selectedModule.current - 1
											]?.lessons[selectedLesson.current - 1]?.title
										}
										onChange={(e) => {
											formInput.current = {
												...formInput.current,
												lessonTitle: e.target.value,
												lessonId: selectedLesson.current,
											};
										}}
									/>
									<button
										onClick={(e) => {
											e.preventDefault();
											updateLesson();
											setLessonTitle(null);
										}}>
										Save
									</button>
								</Form>
							</FormWrapper>
						</FormModal>
					)}
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
};

export default ExpertLedCourseSyllabusUpload;
