import { Link } from "react-router-dom";
import Header from "../../components/Shared/Header/Header";
import { Content, Wrapper, CardWrapper, Card } from "./styles";

const UploadCourse = () => {
    return (
        <Wrapper>
            <Header />
            <Content>
                <h3>First, let's find out what type of course you're uploading.</h3>
                <CardWrapper>
                    <Card>
                        <Link to="/upload/course/free/new">
                            <ul>
                                <li><img src="/svg/free-lesson.svg" alt="icon" /></li>
                                <li>
                                    <span>Free lesson</span>
                                </li>
                            </ul>
                            <h4>Select</h4>
                        </Link>
                    </Card>
                    <Card>
                        <Link to="/upload/course/crash/new">
                            <ul>
                                <li><img src="/svg/crash-course.svg" alt="icon" /></li>
                                <li>
                                    <span>Crash Course</span>
                                </li>
                            </ul>
                            <h4>Select</h4>
                        </Link>
                    </Card>
                    <Card>
                        <Link to="/upload/course/expert/new">
                            <ul>
                                <li><img src="/svg/expert-led.svg" alt="icon" />
                                </li>
                                <li>
                                    <span>Expert Led Course</span>
                                </li>
                            </ul>
                            <h4>Select</h4>
                        </Link>
                    </Card>
                </CardWrapper>
            </Content>
        </Wrapper>
    );
}
 
export default UploadCourse;
