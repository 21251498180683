import styled from 'styled-components'

export const Wrapper = styled.div`
    background: ${props => props.active ? 'var(--primary-color)' : '#979797'};;
    width: 40px;
    height: 24px;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(${props => props.size ? props.size : 1});

    ::after {
        content: "";
        position: absolute;
        left: ${props => props.active ? '18px' : '2px'};
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        transition: all 0.2s ease-in-out;
    }
`;
