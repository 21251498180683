import React, { useRef, useState } from "react";
import { WeekCard, ModuleWrapper } from "./styles";

import AxiosCall from "../../../Utils/axios";
import Loader from "../../components/Shared/Loader/Loader";
import Message from "../../components/Shared/Message/Index";
const Gradewrapper = ({
	item,
	weekIndex,
	getStudents,
	location,
	studentId,
}) => {
	// const [gradingWeek, setGradingWeek] = useState();
	const [isLoading, setIsLoading] = useState(false);
	// const [error, setError] = useState(null);
	const formInput = useRef({
		courseId: location?.state?.courseId,
		studentId: studentId,
		weekId: Number(item?.week),
		project: String(item?.score?.project),
		quiz: String(item?.score?.quiz),
	});

	const gradeStudent = async ({ weekId }) => {
		console.log(item, "item");
		setIsLoading(true);
		// setGradingWeek(weekId);

		try {
			const payLoad = formInput.current;
			console.log("payload: ", payLoad);
			const res = await AxiosCall({
				method: "PATCH",
				path: "classroom/student/grade",
				data: payLoad,
			});

			console.log("response: ", res);

			if (res.status) {
				getStudents();
				Message.success("Student graded successfully");
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			Message.error(err?.response?.data?.error);
		}
	};

	return (
		<WeekCard
			key={weekIndex}
			// showModules={selectedWeek == weekIndex ? true : false}
			onClick={() => {
				/* if (selectedWeek == weekIndex) {
					setSelectedWeek(null);
				} else {
					setSelectedWeek(weekIndex);
				} */
			}}>
			<div className="week-content">
				<div className="col-1">
					<h4>Week {item.week}:</h4>
					<p>{item.title}</p>
				</div>
			</div>
			<ModuleWrapper>
				<h5>Input score respectively</h5>
				<div className="input-row">
					<label htmlFor="project-input">Project</label>
					<input
						id="project-input"
						defaultValue={item.score.project}
						type="number"
						onChange={(e) => {
							formInput.current = {
								...formInput.current,
								project: e.target.value,
							};
						}}
					/>
				</div>
				<div className="input-row">
					<label htmlFor="project-quiz">Quiz</label>
					<input
						id="project-quiz"
						defaultValue={item.score.quiz}
						type="number"
						onChange={(e) => {
							formInput.current = {
								...formInput.current,
								quiz: e.target.value,
							};
						}}
					/>
				</div>
				<div className="submit-wrapper">
					<button
						onClick={() => {
							gradeStudent({
								weekId: String(weekIndex + 1),
							});
						}}>
						{isLoading ? <Loader /> : "Submit"}
					</button>
				</div>
			</ModuleWrapper>
		</WeekCard>
	);
};

export default Gradewrapper;
