import { useState, useEffect, useRef, useCallback } from "react";
import Header from "../../components/Shared/Header/Header";
import { useSelector } from "react-redux";
import { TiPencil } from "react-icons/ti";
import { Content, Wrapper, PageTitle, Form } from "./styles";
import AxiosCall from "../../../Utils/axios";
import Loader from "../../components/Shared/Loader/Loader";
// import AxiosUpload from "../../../Utils/axios/upload";
import Loading from "../../components/Shared/Loader/Loading";
import { AnimatePresence, motion } from "framer-motion";
import { containerVariants } from "../Home/Home";

const Profile = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	// const [courses, setCourses] = useState([])
	// const [error, setError] = useState(null);
	const [profileImg, setProfileImg] = useState(null);
	const tokenState = useSelector((state) => state.token);
	// const loginState = useSelector(state => state.isLoggedIn);
	// const dispatch = useDispatch()

	const profileData = useRef({
		isActive: true,
		_id: "6171beff6a85e02fc9f7f633",
		isVerified: true,
		email: "",
		password: "",
		firstName: "",
		lastName: "",
		phone: "",
		profileImg:
			"https://emmyh-coin.appspot.com.storage.googleapis.com/6d85b1e5-1307-4365-a4ab-4e44c319bdad.png",
		isTutor: true,
		joinDate: "",
		bio: "",
		experience: 0,
		gender: "Male",
		nationality: "Nigerian",
		stack: "",
		courses: [],
		students: [],
		createdAt: "2021-10-21T19:26:55.158Z",
		updatedAt: "2021-10-21T19:26:55.158Z",
		__v: 0,
	});

	const getProfile = useCallback(async () => {
		// setIsLoading(true);

		console.log("current token", tokenState);

		// setError(null)

		try {
			const res = await AxiosCall({
				method: "GET",
				path: "profile",
			});

			if (res.status) {
				profileData.current = res.data;
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	}, [tokenState]);

	useEffect(() => {
		getProfile();
	}, [getProfile]);

	const updateProfile = async (e) => {
		e.preventDefault();

		try {
			setIsUpdating(true);
			const formData = new FormData();
			// const token = localStorage.getItem("authToken");
			if (profileImg) {
				formData.append("profileImg", profileImg);
			}
			formData.append("email", profileData.current.email);
			formData.append("gender", profileData.current.gender);
			formData.append("firstName", profileData.current.firstName);
			formData.append("lastName", profileData.current.lastName);
			formData.append("nationality", profileData.current.nationality);
			formData.append("stack", profileData.current.stack);
			formData.append("bio", profileData.current.bio);
			formData.append("phone", profileData.current.phone);
			formData.append("experience", profileData.current.experience);

			console.log("formData: ", formData.get("stack"));

			const res = await AxiosCall({
				method: "PATCH",
				path: "update_profile",
				contentType: "multipart/form-data",
				data: formData,
			});
			console.log("response", res);
			setIsUpdating(false);
		} catch (err) {
			setIsUpdating(false);
			// console.log(err.response.data.message)
		}
	};

	return (
		<Wrapper>
			<Header
				leftCol={
					<>
						<PageTitle>Profile</PageTitle>
						<h6>Update Your Photos and personal Details here</h6>
					</>
				}
			/>
			<AnimatePresence>
				{isLoading ? (
					<Loading />
				) : (
					<motion.div
						variants={containerVariants}
						initial="start"
						animate="end"
						exit={"exit"}
						className="fullheight">
						<Content>
							<Form>
								<div className="col-1">
									<div className="input-sec">
										<label for="firstname">First name</label>
										<input
											type="text"
											name=""
											id="firstname"
											placeholder="Enter first name"
											defaultValue={profileData.current.firstName}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													firstName: e.target.value,
												};
											}}
										/>
									</div>
									<div className="input-sec">
										<label for="lastname">Last name</label>
										<input
											type="text"
											name=""
											id="lastname"
											placeholder="Enter last name"
											defaultValue={profileData.current.lastName}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													lastName: e.target.value,
												};
											}}
										/>
									</div>
								</div>

								<div className="col-2">
									<div className="user-avatar-label">
										<img
											src={profileData.current.profileImg}
											alt={profileData.current.profileImg}
										/>
										<div className="edit-box">
											<TiPencil />
										</div>
										<label for="user-avatar"></label>
									</div>
									<input
										className="user-avater-input"
										type="file"
										name=""
										id="user-avatar"
										onChange={(e) => {
											setProfileImg(e.target.files[0]);
											profileData.current = {
												...profileData.current,
												profileImg: URL.createObjectURL(e.target.files[0]),
											};
										}}
									/>

									<div className="input-sec">
										<label className="bio-label" for="user-bio">
											Your Bio
										</label>
										<textarea
											name=""
											id="user-bio"
											placeholder="Enter bio"
											defaultValue={profileData.current.bio}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													bio: e.target.value,
												};
											}}></textarea>
									</div>
								</div>

								<div className="col-3">
									<div className="input-sec">
										<label for="stack">Stack</label>
										<input
											type="text"
											name=""
											id="stack"
											placeholder="Enter stack"
											defaultValue={profileData.current.stack}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													stack: e.target.value,
												};
											}}
										/>
									</div>
									<div className="input-sec">
										<label for="Nationality">Nationality</label>
										<input
											type="text"
											name=""
											id="Nationality"
											placeholder="Enter nationality"
											defaultValue={profileData.current.nationality}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													nationality: e.target.value,
												};
											}}
										/>
									</div>
									<div className="input-sec">
										<label for="Experience">Years of experience</label>
										<input
											type="text"
											name=""
											id="Experience"
											placeholder="experience (e.g. 7 years)"
											defaultValue={profileData.current.experience}
											onChange={(e) => {
												profileData.current = {
													...profileData.current,
													experience: parseInt(e.target.value),
												};
											}}
										/>
									</div>
								</div>

								<button onClick={updateProfile}>
									{isUpdating ? <Loader /> : "Save"}
								</button>
							</Form>
						</Content>
					</motion.div>
				)}
			</AnimatePresence>
		</Wrapper>
	);
};

export default Profile;
