import { Link } from "react-router-dom";
import {
	Wrapper,
	FormWrapper,
	HeadBar,
	Form,
	UploadWrapper,
	UploadItem,
	ListTiles,
	Tiles,
	UploadItemWrapper,
	UploadContent,
	CourseThumbnailWrapper,
	CourseUploadWrapper,
} from "./styles";
import axios from "axios";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useState, useEffect, useRef } from "react";
import Loader from "../../../components/Shared/Loader/Loader";
import { useHistory, useParams } from "react-router-dom";
import AxiosCall, { hostUrl } from "../../../../Utils/axios";
import AxiosUpload from "../../../../Utils/axios/upload";
import Message from "../../../components/Shared/Message/Index";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../components/Shared/Loader/Loading";
const CrashCourseUpload = () => {
	const user = useSelector((state) => state.user);
	let history = useHistory();
	const { courseId } = useParams();

	// const [counter, setCounter] = useState(0);
	const [contentForm, setContentForm] = useState(false);
	const [resourcesForm, setResourcesForm] = useState(false);
	const [instructorsForm, setInstructorsForm] = useState(false);
	const [courseThumbnailForm, setCourseThumbnailForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const [error, setError] = useState(null);
	const [loadingVideo, setLoadingVideo] = useState(false);
	const [loadingImage, setLoadingImage] = useState(false);
	const [loadingThumbnail, setLoadingThumbnail] = useState(false);
	const [loadingSyllabus, setLoadingSyllabus] = useState(false);
	const [videoLink, setVideoLink] = useState("");
	const [imageLink, setImageLink] = useState("");
	const [thumbnailLink, setThumbNailLink] = useState("");
	const [syllabusLink, setSyllabusLink] = useState("");
	const [instructors, setInstructors] = useState([]);
	const [timeline, setTimeline] = useState([]);
	const [pageLoading, setPageLoading] = useState(true);

	const [categories, setCategories] = useState([]);
	/* 
	const timeline = useRef([
		{
			title: "",
			description: "",
			project: {
				title: "",
				description: "",
			},
		},
	]);
 */

	const formInput = useRef({
		title: "",
		snippet: "",
		description: "",
		duration: "",
		syllabus: syllabusLink,
		featuredVideo: videoLink,
		featuredImage: imageLink,
		thumbnail: thumbnailLink,
		category: "",
		prerequisite: "",
		effort: "",
		level: "",
		timeline: [
			{
				title: "",
				description: "",
				project: {
					title: "",
					description: "",
				},
			},
		],
		instructors: [],
	});

	useEffect(() => {
		formInput.current.timeline = [...timeline];
	}, [timeline]);

	const uploadCourse = async () => {
		setIsLoading(true);
		formInput.current = {
			...formInput.current,
			syllabus: syllabusLink,
			featuredVideo: videoLink,
			featuredImage: imageLink,
			thumbnail: thumbnailLink,
		};

		// console.log(formInput)
		try {
			// console.log(
			//     {syllabus: syllabusLink},
			//     {featuredVideo: videoLink},
			//     {featuredImage: imageLink},
			//     {thumbnail: thumbnailLink}
			// )

			setTimeout(async () => {
				try {
					const payLoad = formInput.current;

					if (courseId === "new") {
						const res = await AxiosCall({
							method: "POST",
							path: "course/crash",
							data: payLoad,
						});

						if (res.status) {
							Message.success("Course created successfully");
							history.push("/upload/syllabus/course/crash/" + res.data._id);
						}
					} else {
						const res = await AxiosCall({
							method: "PATCH",
							path: "course/crash/details",
							data: payLoad,
						});

						if (res.status) {
							history.push("/upload/syllabus/course/crash/" + res.data._id);
							Message.success("Course updated successfully");
						}
					}
				} catch (error) {
					setIsLoading(false);
					// setError("Invalid credentials");
				}
			}, 1000);
		} catch (err) {
			setIsLoading(false);
			// setError("Invalid credentials");
			// console.log(err?.response?.data?.error)
		}
	};

	const uploadVideo = async (file) => {
		setLoadingVideo(true);
		const formData = new FormData();
		formData.append("file", file);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		setVideoLink(res.data[0].url);
		formInput.current.featuredVideo = res.data[0].url;
		Message.success("Media uploaded successfully");
		setLoadingVideo(false);
	};
	const uploadImage = async (file) => {
		setLoadingImage(true);
		const formData = new FormData();
		formData.append("file", file);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		setImageLink(res.data[0].url);
		formInput.current.featuredImage = res.data[0].url;
		Message.success("Media uploaded successfully");
		setLoadingImage(false);
	};
	const uploadThumbnail = async (file) => {
		setLoadingThumbnail(true);
		const formData = new FormData();
		formData.append("file", file);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		setThumbNailLink(res.data[0].url);
		formInput.current.thumbnail = res.data[0].url;
		Message.success("Media uploaded successfully");
		setLoadingThumbnail(false);
	};
	const uploadSyllabus = async (file) => {
		setLoadingSyllabus(true);
		const formData = new FormData();
		formData.append("file", file);
		const res = await AxiosUpload({
			method: "POST",
			path: "file/upload",
			data: formData,
		});

		setSyllabusLink(res.data[0].url);
		formInput.current.syllabus = res.data[0].url;
		Message.success("Media uploaded successfully");
		setLoadingSyllabus(false);
	};

	const getCategory = useCallback(async () => {
		const token = localStorage.getItem("authToken");
		// console.log("fewf token: ", token);

		const headers = {
			cholatrek_access_token: token,
			"Content-Type": "application/json",
		};
		try {
			const res = await axios(`${hostUrl}/admin/category/all`, headers);

			/* 	const res = await axios(
		
				"https://api.cholatrek.io/admin/category/all",
				headers
			); */
			if (res) {
				setCategories(res.data.data);
			}
		} catch (err) {
			Message.error(err.response?.data?.message ?? err.response?.data?.error);
		}
	}, []);

	const getInstructor = useCallback(async () => {
		try {
			const res = await AxiosCall({
				method: "GET",
				custormBaseURL: "https://api.cholatrek.io/",
				path: "instructors",
			});
			setInstructors(res.data);

	
		} catch (err) {
			Message.error(err.response?.data?.message ?? err.response?.data?.error);
		}
	}, []);

	const getCourse = useCallback(async () => {
		if (courseId !== "new") {
			try {
				const res = await AxiosCall({
					method: "GET",
					path: "classroom/course/" + courseId,
				});

				formInput.current = {
					...formInput.current,
					courseId: res.data._id,
					title: res.data.title,
					snippet: res.data.snippet,
					level: res.data.level,
					category: res.data.category,
					effort: res.data.effort,
					duration: res.data.duration,
					prerequisite: res.data.prerequisite,
					description: res.data.description,
					syllabus: res.data.syllabus,
					featuredVideo: res.data.featuredVideo,
					featuredImage: res.data.featuredImage,
					thumbnail: res.data.thumbnail,
					timeline: [...res.data.timeline],
				};

				setSyllabusLink(res.data.syllabus);
				setVideoLink(res.data.featuredVideo);
				setImageLink(res.data.featuredImage);
				setThumbNailLink(res.data.thumbnail);

		
				setTimeline([...res.data.timeline]);
				// timeline.current = res.data.timeline

				setPageLoading(false);
			} catch (err) {
				setPageLoading(false);
			}
		} else {
			setPageLoading(false);
		}
	}, [courseId]);

	useEffect(() => {
		getInstructor();
	}, [getInstructor]);

	useEffect(() => {
		getCourse();
	}, [getCourse]);

	useEffect(() => {
		getCategory();
	}, [getCategory]);

	return (
		<>
			<Wrapper>
				<HeadBar>
					<h3>upload courses</h3>
					<Link to="/course/upload">Exit</Link>
				</HeadBar>
				{pageLoading ? (
					<Loading />
				) : (
					<>
						{" "}
						<div className="top-action">
							<button onClick={uploadCourse}>
								{isLoading ? <Loader /> : "Continue"}
							</button>
						</div>
						<ListTiles>
							<Tiles showForm={contentForm}>
								<div
									className="tile-head"
									onClick={() => setContentForm(!contentForm)}>
									<span>Course Content</span>
									<div className="toggle">
										<BsChevronDown />
									</div>
								</div>

								{contentForm && (
									<div className="tile-body">
										<FormWrapper>
											<Form>
												<label htmlFor="">Course Title</label>
												<input
													defaultValue={formInput.current.title}
													type="text"
													placeholder="Insert Course Title"
													onChange={(e) => {
														formInput.current = {
															...formInput.current,
															title: e.target.value,
														};
													}}
												/>

												<label htmlFor="">Short Description</label>
												<input
													defaultValue={formInput.current.snippet}
													type="text"
													placeholder="Insert Course Description"
													onChange={(e) => {
														formInput.current = {
															...formInput.current,
															snippet: e.target.value,
														};
													}}
												/>

												<div className="input-col">
													<div className="input-wrapper">
														<input
															defaultValue={formInput.current.effort}
															type="text"
															placeholder="Effort"
															onChange={(e) => {
																formInput.current = {
																	...formInput.current,
																	effort: e.target.value,
																};
															}}
														/>
													</div>
												</div>

												<div className="input-col">
													<div className="input-wrapper">
														<select
															name="category"
															onChange={(e) => {
																formInput.current = {
																	...formInput.current,
																	category: e.target.value,
																};
															}}>
															<option selected value="design">
																-- select category --
															</option>
															{categories?.map((category) => (
																<option
																	selected={
																		formInput.current.category === category.name
																	}
																	value={category?.name}>
																	{category?.name}
																</option>
															))}
														</select>
													</div>

													<div className="input-wrapper">
														<select
															name="category"
															onChange={(e) => {
																formInput.current = {
																	...formInput.current,
																	level: e.target.value,
																};
															}}
															defaultValue={formInput.current.level}>
															<option selected value="design">
																-- select level --
															</option>
															<option
																selected={
																	formInput.current.category === "Beginner"
																		? "true"
																		: "false"
																}
																value="beginner">
																Beginner
															</option>
															<option
																selected={
																	formInput.current.category === "Intermediate"
																		? "true"
																		: "false"
																}
																value="intermediate">
																Intermediate
															</option>
															<option
																selected={
																	formInput.current.category === "Advanced"
																		? "true"
																		: "false "
																}
																value="advanced">
																Advanced
															</option>
														</select>
													</div>

													<div className="input-wrapper">
														<input
															defaultValue={formInput.current.duration}
															type="text"
															placeholder="Duration in weeks"
															onChange={(e) => {
																formInput.current = {
																	...formInput.current,
																	duration: e.target.value,
																};
															}}
														/>
													</div>
												</div>

												<label htmlFor="">Description</label>
												<textarea
													defaultValue={formInput.current.description}
													className="input-description"
													type="text"
													onChange={(e) => {
														formInput.current = {
															...formInput.current,
															description: e.target.value,
														};
													}}></textarea>
												<label htmlFor="">Prerequsite Knowledge</label>
												<textarea
													defaultValue={formInput.current.prerequisite}
													className="input-Knowledge"
													type="text"
													onChange={(e) => {
														formInput.current = {
															...formInput.current,
															prerequisite: e.target.value,
														};
													}}></textarea>

												{timeline.map((item, index) => (
													<div key={index} className="form-sec timeline-sec">
														<div className="timeline-head">
															<label htmlFor="">Course Timeline</label>
															<span
																onClick={() => {
																	let newTimeline = [...timeline];
																	newTimeline.splice(index, 1);

																	setTimeline(newTimeline);

																	formInput.current = {
																		...formInput.current,
																		timeline: newTimeline,
																	};
																}}>
																remove
															</span>
														</div>
														<input
															type="text"
															placeholder="Headline"
															defaultValue={item.title}
															onChange={(e) => {
																const newTimeline = formInput.current.timeline;
																newTimeline[index].title = e.target.value;
																formInput.current = {
																	...formInput.current,
																	timeline: newTimeline,
																};
															}}
														/>
														<textarea
															className="input-Knowledge course-description"
															type="text"
															placeholder="Description"
															defaultValue={item.description}
															onChange={(e) => {
																const newTimeline = formInput.current.timeline;
																newTimeline[index].description = e.target.value;
																formInput.current = {
																	...formInput.current,
																	timeline: newTimeline,
																};
															}}></textarea>

														<label htmlFor="">Project</label>
														<input
															type="text"
															placeholder="headline"
															defaultValue={item.project.title}
															onChange={(e) => {
																const newTimeline = formInput.current.timeline;
																newTimeline[index].project.title =
																	e.target.value;
																formInput.current = {
																	...formInput.current,
																	timeline: newTimeline,
																};
															}}
														/>

														<textarea
															className="input-Knowledge project-description"
															type="text"
															placeholder="Description"
															defaultValue={item.project.description}
															onChange={(e) => {
																const newTimeline = formInput.current.timeline;
																newTimeline[index].project.description =
																	e.target.value;
																formInput.current = {
																	...formInput.current,
																	timeline: newTimeline,
																};
															}}></textarea>
													</div>
												))}

												<div className="form-footer-wrapper">
													<span
														onClick={() => {
															setTimeline([
																...timeline,
																{
																	title: "",
																	description: "",
																	project: {
																		title: "",
																		description: "",
																	},
																},
															]);
														}}>
														Add Course Timeline & Project
													</span>
													<span style={{ opacity: 0 }}>Add</span>
												</div>
											</Form>
										</FormWrapper>
									</div>
								)}
							</Tiles>
							<Tiles showForm={resourcesForm}>
								<div
									className="tile-head"
									onClick={() => setResourcesForm(!resourcesForm)}>
									<span>Resources</span>
									<div className="toggle">
										<BsChevronDown />
									</div>
								</div>

								{resourcesForm && (
									<div className="tile-body">
										<UploadWrapper>
											<UploadContent>
												<UploadItemWrapper>
													{loadingSyllabus ? (
														<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
													) : (
														<UploadItem>
															<div className="upload-icon-wrapper">
																<AiOutlineCloudUpload />
															</div>
															{formInput.current.syllabus ? (
																<span>{formInput.current.syllabus}</span>
															) : (
																<span>
																	Drop your PDF file here <br /> or
																</span>
															)}
															<form action="" encType="multipart/form-data">
																<label htmlFor="upload-syllabus">Browse</label>
																<input
																	onChange={(e) => {
																		uploadSyllabus(e.target.files[0]);
																	}}
																	style={{ display: "none" }}
																	type="file"
																	accept="application/pdf"
																	id="upload-syllabus"
																/>
															</form>
														</UploadItem>
													)}
													<b>Syllabus Upload</b>
												</UploadItemWrapper>
												<UploadItemWrapper>
													{loadingVideo ? (
														<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
													) : (
														<UploadItem>
															<div className="upload-icon-wrapper">
																<AiOutlineCloudUpload />
															</div>
															{formInput.current.featuredVideo ? (
																<span>{formInput.current.featuredVideo}</span>
															) : (
																<span>
																	Drop your video here <br /> or
																</span>
															)}
															<form action="" encType="multipart/form-data">
																<label htmlFor="upload-video">Browse</label>
																<input
																	onChange={(e) => {
																		uploadVideo(e.target.files[0]);
																	}}
																	style={{ display: "none" }}
																	type="file"
																	accept="video/*"
																	id="upload-video"
																/>
															</form>
														</UploadItem>
													)}
													<b>Video Upload</b>
												</UploadItemWrapper>
												<UploadItemWrapper>
													{loadingImage ? (
														<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
													) : (
														<UploadItem>
															<div className="upload-icon-wrapper">
																<AiOutlineCloudUpload />
															</div>
															{formInput.current.featuredImage ? (
																<span>{formInput.current.featuredImage}</span>
															) : (
																<span>
																	Drop your image here <br /> or
																</span>
															)}
															<form action="" encType="multipart/form-data">
																<label htmlFor="upload-image"> Browse </label>
																<input
																	onChange={(e) => {
																		uploadImage(e.target.files[0]);
																	}}
																	style={{ display: "none" }}
																	type="file"
																	accept="image/png,jpg,jpeg"
																	id="upload-image"
																/>
															</form>
														</UploadItem>
													)}
													<b>Featured Image</b>
												</UploadItemWrapper>
											</UploadContent>
											{/* <button className="upload-button">Add</button> */}
										</UploadWrapper>
									</div>
								)}
							</Tiles>
							<Tiles showForm={instructorsForm}>
								<div
									className="tile-head"
									onClick={() => setInstructorsForm(!instructorsForm)}>
									<span>Instructors</span>
									<div className="toggle">
										<BsChevronDown />
									</div>
								</div>

								{instructorsForm && (
									<div className="instructor-tile-body">
										<div className="input-wrapper">
											<select
												name="category"
												onChange={(e) => {
													formInput.current = {
														...formInput.current,
														instructors: [e.target.value],
													};
												}}>
												<option key={0} value="select-instructor">
													-- select instructor --
												</option>
												{instructors.map((item, instructorIndex) => {
													if (item._id !== user.userId) {
														return (
															<option
																key={instructorIndex + 1}
																value={item._id}>
																{item.firstName + " " + item.lastName}
															</option>
														);
													} else {
														return <></>;
													}
												})}
											</select>
										</div>
									</div>
								)}
							</Tiles>
							<Tiles showForm={courseThumbnailForm}>
								<div
									className="tile-head"
									onClick={() => setCourseThumbnailForm(!courseThumbnailForm)}>
									<span>Course Thumbnail</span>
									<div className="toggle">
										<BsChevronDown />
									</div>
								</div>

								{courseThumbnailForm && (
									<div className="tile-body">
										<CourseThumbnailWrapper>
											<div className="upload-sec">
												<CourseUploadWrapper>
													{loadingThumbnail ? (
														<Loader topColor="#0A6DD8" sideColor="#7CBAFD" />
													) : (
														<UploadItem>
															<div className="upload-icon-wrapper">
																<AiOutlineCloudUpload />
															</div>
															{formInput.current.thumbnail ? (
																<span>{formInput.current.thumbnail}</span>
															) : (
																<span>
																	Drop your image ( 393 x 246 ) here <br /> or
																</span>
															)}
															<form action="" encType="multipart/form-data">
																<label htmlFor="upload-thumbnail">Browse</label>
																<input
																	onChange={(e) => {
																		uploadThumbnail(e.target.files[0]);
																	}}
																	style={{ display: "none" }}
																	type="file"
																	id="upload-thumbnail"
																/>
															</form>
														</UploadItem>
													)}
												</CourseUploadWrapper>
											</div>
											{/* <button className="upload-button">Add</button> */}
										</CourseThumbnailWrapper>
									</div>
								)}
							</Tiles>
						</ListTiles>
					</>
				)}
			</Wrapper>
		</>
	);
};
 
export default CrashCourseUpload;
