import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Shared/Header/Header";
// import { useSelector, useDispatch } from "react-redux";
import {
	Content,
	Wrapper,
	WeekCard,
	ModuleWrapper,
	ModuleCard,
} from "./styles";
import AxiosCall from "../../../Utils/axios";
import { BsChevronDown, BsCheck2 } from "react-icons/bs";
import { useParams } from "react-router";
// import Loader from "../../components/Shared/Loader/Loader";
import Message from "../../components/Shared/Message/Index";
import { useCallback } from "react";

const Progress = ({ location }) => {
	const { studentId } = useParams();
	// const [isLoading, setIsLoading] = useState(false);
	// const [courses, setCourses] = useState([]);
	// const [error, setError] = useState(null);
	// const [listView, setListView] = useState(true);
	// const tokenState = useSelector((state) => state.token);
	// const loginState = useSelector((state) => state.isLoggedIn);
	// const dispatch = useDispatch();

	const [weeks, setWeeks] = useState([]);

	console.log("weeks: data ", location.state.weeks);

	const [selectedWeek, setSelectedWeek] = useState(null);

	const getData = useCallback(async () => {
		try {
			const res = await AxiosCall({
				method: "GET",
				path:
					"classroom/student/progress?studentId=" +
					studentId +
					"&courseId=" +
					location.state.courseId,
			});
			setWeeks(res.data);

			console.log("progress data: ", res);
		} catch (err) {
			Message.error(err?.response?.data?.error);
		}
	}, [location, studentId]);
	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<Wrapper>
			<Header leftCol={<Link to="/classroom">Back</Link>} />
			<Content>
				<h3 className="page-title progress-title">
					View Progress ({" "}
					<span>
						{location.state.student.firstName} {location.state.student.lastName}
					</span>{" "}
					)
				</h3>

				{weeks.map((weekItem, weekIndex) => {
					return (
						<WeekCard
							key={weekIndex}
							showModules={selectedWeek === weekIndex ? true : false}
							onClick={() => {
								if (selectedWeek === weekIndex) {
									setSelectedWeek(null);
								} else {
									setSelectedWeek(weekIndex);
								}
							}}>
							<div className="week-content">
								<div className="col-1">
									<h4>
										Week &nbsp;
										{weekItem.week.weekId}:
									</h4>
								</div>
								<div className="col-2">
									{weekItem.week.complete ? (
										<span>Completed</span>
									) : (
										<span className="pending">Pending</span>
									)}
									<div className="toggle">
										<BsChevronDown />
									</div>
								</div>
							</div>
							{selectedWeek === weekIndex && (
								<ModuleWrapper>
									{weekItem.module.map((moduleItem, index) => {
										return (
											<ModuleCard key={index}>
												<h4>Module {moduleItem.moduleId}:</h4>
												<p>{moduleItem.title}</p>
												{moduleItem.complete && (
													<div className="check-box">
														<BsCheck2 />
													</div>
												)}
											</ModuleCard>
										);
									})}
								</ModuleWrapper>
							)}
						</WeekCard>
					);
				})}
			</Content>
		</Wrapper>
	);
};

export default Progress;
