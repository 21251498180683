import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 100vh;
`;

export const ImageWrapper = styled.div`
    display: none;
    flex: 1;
    background-color: var(--primary-color);
    overflow: hidden;
    position: relative;

    img.wrapper-img {
        min-width: 100%;
        min-height: 100vh;
    }

    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    span {
        color: #EBF3FC;
        font-size: 35px;
        font-weight: 800;
        margin-left: 10px;
    }

    @media screen and (min-width: 720px) {
        display: flex;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #fff;
    padding: 20px;

    h2 {
        text-align: center;
        color: var(--primary-color);
        font-weight: 500;
        font-size: 37px;
    }
    h4 {
        text-align: center;
        margin-bottom: 59px;
        color: #707070;
        font-weight: 400;
        font-size: 14px;
    }
    form {
        width: 100%;
        max-width: 385px;
        padding: 0px 20px;
    }
    form label {
        display: flex;
        font-size: 12px;
        color: #707070;
        font-weight: 500;
        margin-bottom: 6px;
        position: relative;
    }
    form label:last-of-type::after {
        content: "*";
        display: block;
        position: relative;
        top: 0px;
        right: -2px;
        color: var(--primary-color);
    }
    form input {
        display: block;
        border: none;
        width: 100%;
        background-color: #EBF3FC;
        height: 44px;
        font-size: 12px;
        color: #222;
        padding: 0px 15px;
        border-radius: 15px;
    }
    form input::placeholder {
        color: #707070;
        font-weight: 300;
    }
    form input:first-of-type {
        margin-bottom: 24px;
    }
    form button {
        background-color: var(--primary-color);
        border: none;
        height: 49px;
        width: 100%;
        border-radius: 15px;
        color: #fff;
        margin-top: 20px;
        font-weight: 600;
    }
    form .row-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
    }
    form .row-1 .check-box-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    form .row-1 .check-box-wrapper span {
        font-size: 12px;
        color: #707070;
        font-weight: 300;
    }
    form .row-1 .check-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 6px;
        border: 1px solid var(--primary-color);
    }
    form .row-1 .check-btn i {
        font-size: 8px;
        color: #fff;
    }
    form .row-1 a {
        font-size: 12px;
        font-weight: 300;
    }
`;