import styled from 'styled-components'

export const Wrapper = styled.div`
    display: ${props => props.showSidebar ? 'block' : 'none'};
    background-color: #ffffff;
    width: 100%;
    max-width: 245px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99999;

    
    ::after {
        content: "";
        position: fixed;
        top: 0px;
        left: 0px;
        background: #0006;
        width: 100%;
        height: 100vh;
        cursor: pointer;
    }

    @media screen and (min-width: 720px) {
        display: block;
        ::after {
            display: none;
        }
    }
`;

export const Content = styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    padding: 0px 20px;
    position: relative;
    z-index: 9999999;
    ul {
        padding: 16px 0px;
    }
    ul li {
        display: block;
        margin-bottom: 10px;
    }
    ul li a div {
        display: flex;
        align-items: center;
        width: 27px;
        margin: 0px 12px 0px 24px;
    }
`;

export const MenuItem = styled.li`
	a {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 50px;
		border-radius: 5px;
		background: ${(props) =>
			props.currentTab === true ? "rgba(125, 164, 214, 0.2)" : "#fff"};
	}
	a span {
		color: ${(props) =>
			props.currentTab === true ? "--primary-color" : "#979797"};
		font-size: 13px;
		font-weight: 400;
	}
	a svg path {
		fill: ${(props) =>
			props.currentTab === true ? "--primary-color" : "#979797"};
	}
`;

export const Head = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 44px 0px 25px 0px;
    border-bottom: 1px solid #C4C4C4;

    img {
        height: 41px;
    }
`;
