export const success = (message) => {
	if (document.querySelector(".lofty-message-wrapper")) {
		const wrapper = document.querySelector(".lofty-message-wrapper");
		const content =
			'<div class="lofty-message-box lofty-message-success"><span>' +
			message +
			"</span></div>";
		wrapper.innerHTML = content;
	} else {
		const appWrapper = document.getElementById("root");
		const wrapper = document.createElement("div");
		wrapper.classList.add("lofty-message-wrapper");
		appWrapper.appendChild(wrapper);

		const content =
			'<div class="lofty-message-box lofty-message-success"><span>' +
			message +
			"</span></div>";
		wrapper.innerHTML = content;
	}
};

export const error = (message) => {
	let displayMessage = "An error occurred";
	let errorObject = {
		IMAGE_UPLOAD_ERROR: "Please check your file(s) and try again",
		DATA_ERROR: "Some fields are empty",
		UPDATE_DATA_ERROR: "You need to send over the required data",
		URL_ERROR: "That URL is invalid",
		DUPLICATE_ERROR: "Data provided is already in use",
		EMAIL_DUPLICATE_ERROR: "Email provided is already in use",
		USERNAME_DUPLICATE_ERROR: "Username provided is already in use",
		PHONE_NUMBER_DUPLICATE_ERROR: "Phone number provided is already in use",
		USAGE_ERROR: "That slug is already in use",
		ACCOUNT_EXISTENCE_ERROR: "No account associated with that data",
		EXISTENCE_ERROR: "We couldn't find what you requested",
		AUTHORIZATION_ERROR: "You aren't authorized for that action",
		VALIDATION_ERROR: "Invalid credentials provided",
		UNKNOWN_ERROR: "Something completely went wrong",
		PASSWORD_MATCH_ERROR: "Your password doesn't match our records",
		DATA_VALIDATION_ERROR: "Invalid characters in data sent",
		BAD_DATA_ERROR: "You've got some errors in your sent data",
		INTEREST_DATA_ERROR: "You've got some errors in the interests data sent",
		LOGIN_SUCCESS: "Sign In successful",
		ACCOUNT_UPDATE_SUCCESS: "Account has been successfully updated",
		LOGOUT_SUCCESS: "Sign Out successful",
		REGISTER_SUCCESS: "Your account has been successfully created",
		INVALID_TOKEN_ERROR: "Invalid authentication token provided",
		TOKEN_ERROR: "Authentication token required",
		UPDATE_ERROR: "You are not allowed to make that update",
		DOB_ERROR: "The DOB data sent contains invalid data objects",
		PASSWORD_STRENGTH_ERROR: "Your password isn't strong enough",
		PASSWORD_RECOVERY_INITIATED:
			"A recovery OTP has been sent to your email address",
		PASSWORD_RECOVERY_SUCCESS: "Your password has been successfully recovered",
		OTP_ERROR: "Invalid or expired OTP provided",
		QUERY_SUCCESS: "Request data successfully queried",
		REQUEST_SUCCESS: "Your request was successfully executed",
		COURSE_CREATE_SUCCESS: "Your course has been published",
		OPERATION_DUPLICATE_ERROR: "You've already executed that action",
		ENROLLMENT_ERROR: "You are already enrolled in that course",
		NOT_ENROLLED_ERROR: "You are not enrolled in that course",
		FACEBOOK_LOGIN_ERROR: "You need to login using Facebook",
		GOOGLE_LOGIN_ERROR: "You need to login using Google",
		REGULAR_LOGIN_ERROR: "You need to login using your email and password",
		ACCOUNT_DISABLED: "Your account has been disabled.",
		DELETE_SUCCESS: "Delete operation successful",
		CREATE_SUCCESS: "Create operation successful",
		UPDATE_SUCCESS: "Update operation successful",
		COUPON_EXISTENCE_ERROR: "Invalid or expired coupon code",
		COUPON_INACTIVE: "That coupon is currently inactive",
		ACCOUNT_INACTIVE: "That account is currently inactive",
		INSTRUCTOR_NOT_FOUND_OR_DISABLED:
			"One of the instructors does not exist or has been disabled",
		INSTRUCTOR_DISABLED: "Your instructor account has been disabled",
		ADMIN_DISABLED: "Your admin account has been disabled",
		STUDENT_DISABLED: "Your student account has been disabled",
		STUDENT_NOT_FOUND: "That student does not exist",
		INSTRUCTORS_FIELD_ERROR:
			"The instructors field needs to be an array of instructor IDs",
		COURSE_NOT_FOUND: "We couldn't find that course",
		COURSE_INACTIVE:
			"Course currently disabled. Enable the course and try again",
		MAIL_SUCCESS: "Your mail has been sent and is currently being delivered",
		ANNOUNCEMENT_SUCCESS: "Announcement has been successfully made",
		STUDENT_NOT_IN_COURSE: "That student is not in this course",
		UPLOAD_TOKEN_MISSING: "Upload token is missing",
		UPLOAD_TOKEN_INVALID: "Upload token is invalid",
		UPLOAD_SUCCESS: "file(s) upload successful",
		NOT_EXPERT_ERROR: "That course isn't an expert course",
		NOT_CRASH_ERROR: "That course isn't an expert course",
		NOT_FREE_ERROR: "That course isn't an expert course",
		WEEK_INACTIVE: "The requested course week isn't active yet",
		WEEK_EXISTENCE_ERROR: "We couldn't find that week",
		MODULE_EXISTENCE_ERROR: "We couldn't find that module",
		LESSON_EXISTENCE_ERROR: "We couldn't find that lesson",
		FREE_PRICE_ERROR: "Free lessons can't have a price",
		SUBSCRIBED_SUCCESS: "You have successfully subscribed to the mailing  list",
		SUBSCRIBED_ERROR: "You are already subscribed to the mailing list",
		BOOKING_SUCCESS:
			"We've received your booking request and will get back to you soon",
		ENROLLMENT_SUCCESS:
			"You've been successfully enrolled for the course. It's learning time!",
		SUBMISSION_DEADLINE_PASSED: "The submission deadline has passed",
		TRANSACTION_COMPLETED_ERROR: "The transaction has already been completed",
		TRANSACTION_NOT_FOUND: "No transaction could be found for this course",
		PAYMENT_EXPIRED: "You need to complete your payment to continue learning",

		SHORT_INPUT: "Input too short",
	};

	displayMessage = errorObject[message];

	if (document.querySelector(".lofty-message-wrapper")) {
		const wrapper = document.querySelector(".lofty-message-wrapper");
		const content =
			'<div class="lofty-message-box lofty-message-error"><span>' +
			displayMessage +
			"</span></div>";
		wrapper.innerHTML = content;
	} else {
		const appWrapper = document.getElementById("root");
		const wrapper = document.createElement("div");
		wrapper.classList.add("lofty-message-wrapper");
		appWrapper.appendChild(wrapper);

		const content =
			'<div class="lofty-message-box lofty-message-error"><span>' +
			displayMessage +
			"</span></div>";
		wrapper.innerHTML = content;
	}
};

export default{ success, error };
