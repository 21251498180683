import { Content, Wrapper } from "./styles";
import {VscMenu} from 'react-icons/vsc'
import { FiLogOut } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu, logoutUser} from '../../../../actions'
import { useState } from "react";


const Header = ({leftCol}) => {
    const dispatch = useDispatch();
    const [showLogout, setShowLogout] = useState(false)
    const user = useSelector(state => state.user);

    return (
        <Wrapper>
            <Content>
                <div className="toggle-menu" onClick={() => dispatch(toggleMenu())}>
                    <VscMenu />
                </div>
                <div className="col-1">
                {!leftCol ?<>
                    <h1>Hello {user.firstName + " " + user.lastName}</h1>
                    <h6>Here is your task overview today</h6></>
                 : leftCol}
                 </div>

                <div className="col-2" onClick={() => setShowLogout(!showLogout)}>
                    <div className="img-wrapper">
                        <img src={user.profileImg} alt="avatar" />
                    </div>
                    <ul>
                        <li>{user.firstName + " " + user.lastName}</li>
                        <li>{user.stack}</li>
                    </ul>
                    <i className="fas fa-sort-down"></i>

                    {showLogout && <div className="logout-box" onClick={() => dispatch(logoutUser())}>
                        <FiLogOut />
                        <span>Logout</span>
                    </div>}
                </div>
            </Content>
        </Wrapper>
    );
}
 
export default Header;
