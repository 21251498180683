import { StatsContent, StatsWrapper } from "./styles";
import { BiUser } from 'react-icons/bi';
import { BsLayersFill, BsCalendar2Check } from 'react-icons/bs';

const StatsCard = ({data}) => {
    return (
        <StatsWrapper>
            <StatsContent>
                <ul>
                    <li>Total Enroll Students</li>
                    <li>{data.totalStudents}</li>
                </ul>
                <div className="img-wrapper">
                    <BiUser />
                </div>
            </StatsContent>
            <StatsContent>
                <ul>
                    <li>Active Courses</li>
                    <li>{data.activeCourses}</li>
                </ul>
                <div className="img-wrapper">
                    <BsLayersFill />
                </div>
            </StatsContent>
            <StatsContent>
                <ul>
                    <li>Completed Course</li>
                    <li>{data.completedCourses}</li>
                </ul>
                <div className="img-wrapper">
                    <BsCalendar2Check />
                </div>
            </StatsContent>
        </StatsWrapper>
    );
}
 
export default StatsCard;